import React, { Fragment, useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Header from "../../components/Header";
import BannerImage from "../../assets/images/hostelimg/Artboard 1.png"
import Sectionimg from "../../assets/images/hostelimg/sectionimg.jpg"
import Icon1 from "../../assets/images/hostelimg/iconh1.png"
import Icon2 from "../../assets/images/hostelimg/iconh2.png"
import Icon3 from "../../assets/images/hostelimg/iconh3.png"
import Icon4 from "../../assets/images/hostelimg/iconh4.png"
import Icon5 from "../../assets/images/hostelimg/iconh5.png"
import Icon6 from "../../assets/images/hostelimg/iconh6.png"
import Icon7 from "../../assets/images/hostelimg/iconh7.png"
import Icon8 from "../../assets/images/hostelimg/iconh9.png"
import Icon10 from "../../assets/images/hostelimg/iconh10.png"
import Bio from "../../assets/images/hostelimg/bio.png"
import Room1 from "../../assets/images/hostelimg/room1.png"
import Room2 from "../../assets/images/hostelimg/room2.png"
import Room3 from "../../assets/images/hostelimg/room3.png"
import Room4 from "../../assets/images/hostelimg/room4.png"
import Room5 from "../../assets/images/hostelimg/room5.png"
import Room6 from "../../assets/images/hostelimg/room6.png"
import Room7 from "../../assets/images/hostelimg/room7.png"
import Room8 from "../../assets/images/hostelimg/room8.png"
import Room9 from "../../assets/images/hostelimg/room9.png"
import Artboard4 from "../../assets/images/hostelimg/Artboard4.png"
import Artboard5 from "../../assets/images/hostelimg/Artboard5.png"
import Artboard7 from "../../assets/images/hostelimg/Artboard7.png"
import Artboard8 from "../../assets/images/hostelimg/Artboard8.png"
import Artboard9 from "../../assets/images/hostelimg/Artboard9.png"
import "./style.css"
const Hostel = () => {
const [about, setAbout] = useState(false)
useEffect(() => {
window.scrollTo(0, 0)
}, [])
return (
<Fragment>
   <Header></Header>
   <div className="col bannerCont">
      <img src={BannerImage} alt="" className="imgRes" />
   </div>
   <div className="col-md-12 paddingclassHostel bg2">
      <div className="container">
         <div className="row">
            <h1 className="fontbig">STUDENT HOUSING OF THE YEAR FOR 2 YEARS RUNNING! HOSPITALITY PROJECT OF
               T            HE YEAR - CNN NEWS 18
            </h1>
         </div>
      </div>
   </div>
   <div className="col-md-12 paddingclassHostel">
      <div className="container">
         <div className="row">
            <div className="col-md-5">
               <p className="paraHostel" style={{color:"#000 !important"}}>Tribe is an international award winning luxury student accommodation. tribe wakad is one of our best designed and available for all students from indira group of institutes on the basis of first come first.
               it is one minute drive from indira group of institutes</p>
            </div>
            <div className="col-md-7">
               <img src={Sectionimg } className="img_res" />
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-12  ">
      <div className="container">
         <div className="row">
            <h2 className="fontbig2hostel">AMENITIES</h2>
         </div>
      </div>
   </div>
   <div className="col-md-12 HOSTELBG paddingclassHostel">
      <div className="container">
         <div className="row ">
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon1} className="hightlightimg" />
               <p className="bbapar3 text-center">Fully air conditioned with
                  ensuite bathrooms
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon2} className="hightlightimg" />
               <p className="bbapar3 text-center">Single or twin rooms
                  only
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon3} className="hightlightimg" />
               <p className="bbapar3 text-center">24x7 multicuisine
                  International
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon4} className="hightlightimg" />
               <p className="bbapar3 text-center">Keyless and Cashless
                  Campuses
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon5} className="hightlightimg" />
               <p className="bbapar3 text-center">Community smart tvs
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon6} className="hightlightimg" />
               <p className="bbapar3 text-center">Gym And Fitness Centre
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon7} className="hightlightimg" />
               <p className="bbapar3 text-center">Chill Zones And Breakout
                  Areas
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Bio} className="hightlightimg" />
               <p className="bbapar3 text-center">Biometric, Rfid And
                  ProfessionalSecurity Services
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon8} className="hightlightimg" />
               <p className="bbapar3 text-center">Community And Growth 
                  Events
               </p>
            </div>
            <div className="col-md-2 col-xs-6 somi hostelbox ">
               <img src={Icon10} className="hightlightimg" />
               <p className="bbapar3 text-center">Cutting Edge Backend
                  Technology
               </p>
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-12  ">
      <div className="container">
         <div className="row">
            <h2 className="fontbig3">TYPES OF ROOMS</h2>
         </div>
      </div>
   </div>
   <div className="col-md-12  HOSTELBG2 mhide paddingclassroom ">
      <div className="container">
         <div className="row">
            <div className="col-md-12 carouselmargin">
               <Carousel>
                  <Carousel.Item>
                     <div class="row">
                        <div className="col-md-4 boxhostel">
                           <img src={Room1} className="img_res" />
                           <h2 className="roombox">SINGLE ROOM</h2>
                        </div>
                        <div className="col-md-4 boxhostel">
                           <img src={Room1} className="img_res" />
                           <h2 className="roombox2">LUXURY TWIN ROOM</h2>
                        </div>
                        <div className="col-md-4 boxhostel">
                           <img src={Room3} className="img_res" />
                           <h2 className="roombox">TWIN ROOM</h2>
                        </div>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div class="row">
                        <div className="col-md-4 boxhostel">
                           <img src={Room4} className="img_res" />
                           <h2 className="roombox2">ENSUITE BATHROOMS </h2>
                        </div>
                        <div className="col-md-4 boxhostel">
                           <img src={Room5} className="img_res" />
                           <h2 className="roombox2">PROPERTY FACADE</h2>
                        </div>
                        <div className="col-md-4 boxhostel">
                           <img src={Room6} className="img_res" />
                           <h2 className="roombox">KEYLESS CAMPUS</h2>
                        </div>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div class="row">
                        <div className="col-md-4 boxhostel">
                           <img src={Room7} className="img_res" />
                           <h2 className="roombox3">TOP QUALITY FURNITURE </h2>
                        </div>
                        <div className="col-md-4 boxhostel">
                           <img src={Room8} className="img_res" />
                           <h2 className="roombox">STUDY AREAS </h2>
                        </div>
                        <div className="col-md-4 boxhostel">
                           <img src={Room9} className="img_res" />
                           <h2 className="roombox3">CHILL &amp; WORK ZONES </h2>
                        </div>
                     </div>
                  </Carousel.Item>
               </Carousel>
            </div>
         </div>
      </div>
   </div>
   <div className="clearfix"></div>
   {/*--------------------mobile----------------*/}
   <div className="col-md-12  HOSTELBG2 mshow paddingclassroom ">
      <div className="container">
         <div className="row">
            <div className="col-md-12 carouselmargin">
               <Carousel>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room1} className="img_res" />
                        <h2 className="roombox">SINGLE ROOM</h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room2} className="img_res" />
                        <h2 className="roombox2">LUXURY TWIN ROOM</h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room3} className="img_res" />
                        <h2 className="roombox">TWIN ROOM</h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room4} className="img_res" />
                        <h2 className="roombox2">ENSUITE BATHROOMS </h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room5} className="img_res" />
                        <h2 className="roombox2">PROPERTY FACADE</h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room6} className="img_res" />
                        <h2 className="roombox">KEYLESS CAMPUS</h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room7} className="img_res" />
                        <h2 className="roombox3">TOP QUALITY FURNITURE </h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room8} className="img_res" />
                        <h2 className="roombox">STUDY AREAS </h2>
                     </div>
                  </Carousel.Item>
                  <Carousel.Item>
                     <div className="col-md-4 boxhostel">
                        <img src={Room9} className="img_res" />
                        <h2 className="roombox3">CHILL &amp; WORK ZONES </h2>
                     </div>
                  </Carousel.Item>
               </Carousel>
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-12 paddingclassHostel ">
      <div className="container">
         <div className="row">
            <div className="col-md-3 col-xs-12 boxgray">
               <img src={Artboard4} className="img_res1" />
               <h2 className="unlimitedfont">UNLIMITED!</h2>
               <p className="para2">VEG/NON-VEG MEALS.<br />
                  LAUNDRY.<br/>
                  WIFI. <br/>
                  ELECTRICITY.<br/>
                  TEA/COFFEE.
               </p>
            </div>
            <div className="col-md-9 col-xs-12 boxblue">
               <img src={Artboard5} className="img_res" />
               <h2 className="packagefont">PACKAGE INCLUDE</h2>
               <p className="para3">Fully A/c Bed Rental With Ensuite Bathroom | 3 Meals (Breakfast | Snacks | Dinner) - Unltd 
                  Electricity - Unlimited | Rfid Access | Unlimited Wifi | Laundry (Unlimited 
                  Housekeeping (Twice A Week) | Room Linen Change (Once A Week) Unlimited Tea/coffee
                  Personal Student Erp Login | Gym Access &amp; Chill Zones Access | Festival Events
                  Iron Room/pantry Access
               </p>
            </div>
         </div>
      </div>
   </div>
   <div className="clearfix"></div>
   <div className="col-md-12 paddingclassnew col-xs-12 contactbgHostel col-xs-12">
      <div className="row">
         <div className="col-md-6 col-xs-12">
            <h2 className="contactfont">BOOK YOUR TOUR TODAY!</h2>
         </div>
         <div className="col-md-6 paddingtop col-xs-12">
            <div className="col-md-12 margincon col-xs-12">
               <div className="row">
                  <div className="col-md-1 col-2">
                     <img src={Artboard7} className="img_rescon" />
                  </div>
                  <div className="col-md-11 col-10 spanfont">  
                     <a href="tel:8880868000" style={{color:"white"}}>+91 8880868000</a>
                  </div>
               </div>
            </div>
            <div className="col-md-12 margincon col-xs-12">
               <div className="row">
                  <div className="col-md-1 col-2">
                     <img src={Artboard8} className="img_rescon" />
                  </div>
                  <div className="col-md-11  col-10 spanfont"> <a href="https://tribestays.com/" target="_blank" style={{color:"white"}}>https://tribestays.com/</a>
                  </div>
               </div>
            </div>
            <div className="col-md-12 margincon col-xs-12">
               <div className="row">
                  <div className="col-md-1 col-2">
                     <img src={Artboard9} className="img_rescon" />
                  </div>
                  <div className="col-md-11 col-10 spanfont">Gmap - "Tribe Hostel Wakad"
                     Ace Almighty, Wakad, Pune.
                     Touching Indira College Lane 
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</Fragment>
)
}
export default Hostel
import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/contact/contactusbanner.jpg"

import AddressImage from "../../assets/images/contact/address.png"
import ContactImage from "../../assets/images/contact/contact-details.png"
import Table from 'react-bootstrap/Table';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#ffffff', textAlign: "left", paddingLeft: '6%' }}>CONTACT US</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">CONTACT US</h3>
                    </div>
                </div>
            </div>
            <div className="marginBottom3em">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={AddressImage} alt="" className="circleAboutIcon1" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox1">
                                    <img src={AddressImage} alt="" className="circleAboutIconMob1 mobShow" />
                                    <p>Shree Chanakya Education Society's,   &nbsp; INDIRA SCHOOL OF BUSINESS STUDIES ‘Abhinavan’
                                        ,89/2A, New PuneMumbai Highway, Tathawade, Pune – 411033, Maharashtra, India.</p>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={ContactImage} alt="" className="circleAboutIcon1" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={ContactImage} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="paddingTop">Important Contact details</h4>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Telephone Numbe</th>
                                                <th>E-mail Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Admission Enquiry</td>
                                                <td>+91-20-66759500 / 415 / 407</td>
                                                {/* <td>admissions@indiraisbsmba.edu.in</td> */}
                                                <td>admissions@indiraisbs.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>Campus Hiring</td>
                                                <td>+91-20-66759500 / 415 / 440</td>
                                                {/* <td>placements_isbs@indiraisbs.ac.in</td> */}
                                                <td>placement_mba@indiraisbs.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>Examination & Student
                                                    Verification</td>
                                                <td>+91-20-66759500 / 415 / 407</td>
                                                {/* <td>examination@indiraisbsmba.edu.in</td> */}
                                                <td>examination@indiraisbs.edu.in</td>
                                            </tr>

                                            <tr>
                                                <td>General Enquiry</td>
                                                <td>+91-20-66759500 / 415 / 492</td>
                                                {/* <td>info@indiraisbsmba.edu.in</td> */}
                                                <td>info@indiraisbs.edu.in</td>
                                            </tr>

                                            <tr>
                                                <td>Careers at ISBS</td>
                                                <td>+91-20-66759500 / 415 / 463</td>
                                                {/* <td>careers@indiraisbsmba.edu.in</td> */}
                                                <td>careers@indiraisbs.edu.in</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={AddressImage} alt="" className="circleAboutIcon1" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox1">
                                    <img src={AddressImage} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="paddingTop">Contact Information</h4>
                                    <div className="formContact contact">
                                        <form>
                                            <div className="form-group row marginTop1em">
                                                <label for="inputPassword" className="col-sm-2 col-form-label">Name*</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="Name" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="form-group row marginTop1em">
                                                <label for="inputPassword" className="col-sm-2 col-form-label">Course Interested *</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="Course" placeholder="Course Interested" />
                                                </div>
                                            </div>
                                            <div className="form-group row marginTop1em">
                                                <label for="inputPassword" className="col-sm-2 col-form-label">Email*</label>
                                                <div className="col-sm-10">
                                                    <input type="email" className="form-control" id="Course" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="form-group row marginTop1em">
                                                <label for="inputPassword" className="col-sm-2 col-form-label">Qualification *</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="Qualification" placeholder="Qualification" />
                                                </div>
                                            </div>
                                            <div className="form-group row marginTop1em">
                                                <div className="col-sm-12 text-center">
                                                <button className="submitBtnContact">Submit</button>
                                                </div>
                                            </div>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Contact
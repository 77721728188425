import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/Editorial_2.png"
import Pdf from "../../assets/pdf/INSPIRA-Magazine-updated.pdf"

const Editorial = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Publications &#62; <span> Editorial</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 paddingTop3em paddingBottom3em">
                        <embed src={Pdf} width="100%" height="750" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Editorial
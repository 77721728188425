import React, { Fragment } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/student-life/studentlifebanner.jpg"
import Icon1Image from "../../assets/images/student-life/depressionmanagement.png"
import Icon2Image from "../../assets/images/student-life/foundationday.png"
import Icon3Image from "../../assets/images/student-life/talenthunt.png"
import Icon4Image from "../../assets/images/student-life/internationalday.png"
import Icon5Image from "../../assets/images/student-life/bookeview.png"
import Icon6Image from "../../assets/images/student-life/traditionalday.png"
import Icon7Image from "../../assets/images/student-life/dewangmehta.png"
import Icon8Image from "../../assets/images/student-life/nationalconference.png"
import BlankImage from "../../assets/images/student-life/blank1.jpg"
import library1 from "../../assets/images/library1.jpg"
import Gym from "../../assets/images/Gym.jpg"
import BasketballCourt from "../../assets/images/BasketballCourt.jpg"
import Audi from "../../assets/images/Audi.jpg"
import StudentsResidence from "../../assets/images/StudentsResidence.jpg"

const LifeAtISBS = () => {
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#000', textAlign: "left", paddingLeft: '6%' }}>INFRASTRUCTURE</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle"><span>Infrastructure</span></h3>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon1Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon1Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">IT Facility:</h4>
                                    <p>ISBS attempts to ensure availability of state-of-the-art IT infrastructure which enables effective delivery of teaching learning processes. Following are the details of IT infrastructure and its utility on campus:

Well-equipped Computer Lab: ISBS has well equipped computer Lab with LAN connectivity. Lab is effectively used in teaching learning process by both Faculty and Staff.

Institute has provided dedicated workstations for every Faculty member. Faculty and staff are also provided laptops on need basis for ease of work and effective delivery of assigned task. Every Faculty room has been provided printers as shared resource and scanners are available in library and computer room to be used as and when required. Projection Television sets has been installed in the corridor which is used for internal information dissemination and live streaming business news channels to keep students and staff updated on latest developments.

Software Resources: Based on requirement, licensed software has been installed across campus. Every Staff, Faculty and Student is given a unique Microsoft Id to be used for all official purposes. IT team has created common and shared online storage facility available in institute’s intranet named File Transfer Protocol (FTP). Updated Anti-virus protection is available in all systems for data security. Latest Software tools like SPSS, SPSS Amos, and Prowess and the like have been installed in computer lab.

Network Resources: Leased Line and Wi-Fi facility is available on campus for seamless connectivity for users. OneDrive (previously SkyDrive), which is a Microsoft’s service for hosting files in the “cloud” Office Online services, is also available for all Outlook users. Through this facility, files can be synced to a PC and accessed from a web browser or a mobile device, as well as shared publicly or with specific individuals.

IPad to Faculty Members – Every Faculty member has been provided I-pads. Select classrooms have also been equipped with Apple TV. Faculty members use them for training students on skills like GD, PI and sharing real time data during lectures.</p>
                                    <div className="row">
                                    <div className="col-md-6">
                                            <img src={library1} className="imgRes" />
                                        </div>
                                    <div class="col-md-6">
                                        <p>The Library of the Institute has an excellent collection of reference/text books and periodicals related to the field of Management and these are constantly updated. The Institute also subscribes to a number of journals (both national and international), periodicals and catalogues covering the various fields of Management. Multimedia & Reading room facility is available for the students. The books from the library are also available for home issue. The Institute also keeps adding the latest textbooks, reference books and Management journals as per the requirement and suggestions of the faculty, industry and students.</p>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon2Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon2Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <div className="row">
                                    <div className="col-md-6">
                                    <h4 className="blockTitle">Gym & Fitness Centre</h4>
                                    <p>Indira has developed a modern and well-equipped Gymnasium and Fitness Center for the students, which is managed by a full-time Fitness Instructor. We strongly believe that in today’s fast-paced and stressful business environment, it is imperative to maintain 100% physical fitness. Gym, yoga and aerobics form the key components of our Fitness Program. Fully equipped gymnasium and health centers have trained Individuals to conduct sessions in yoga, art of living, aerobics and other forms of physical fitness regime for students and faculty members.</p>
                                    </div>
                                        <div className="col-md-6">
                                            <img src={Gym} className="imgRes" />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon3Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon3Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <div className="row">
                                    <div className="col-md-6">
                                            <img src={BasketballCourt} className="imgRes" />
                                        </div>
                                    <div class="col-md-6">
                                    <h4 className="blockTitle">Basketball Court</h4>
                                    <p>Indira School of Business studies boasts of an in-campus basketball court where students can warm and stretch their muscles post their regular lectures and during weekly sports lecture.</p>
                                    </div>
                                    
                                        
                                     
                                    </div>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon4Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon4Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <div className="row">
                                    <div className="col-md-6">
                                    <h4 className="blockTitle">Auditorium</h4>
                                    <p>Conference Rooms and Auditoriums accommodating over 1000 students are a highlight for the Industry and Academic interface of national and internationally acclaimed speakers. The auditoriums are well equipped with infrastructure to accommodate the recent technological innovations for live streaming of various events that are conducted by Indira School of Business Studies</p>
                                    </div>
                                   
                                        <div className="col-md-6">
                                            <img src={Audi} className="imgRes" />
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon5Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon5Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <div className="row">
                                    <div className="col-md-6">
                                            <img src={StudentsResidence} className="imgRes" />
                                        </div>
                                    <div className="col-md-6">
                                    <h4 className="blockTitle">Student’s Residence Facility</h4>
                                    <p>Indira boasts of one of the largest hostel facilities amongst post-graduate education Institutes in Maharashtra. A modern, 300 student capacity hostel with excellent amenities and mess facility has been provided for the boys & girls. Students opting to stay in the hostel have to book their room at the earliest, as allotment is on a first come basis.

The Indira Group of Institutes has spotlessly clean hostels – 2 boys and 1 girls hostel, provide hygienic, comfortable surroundings for students, and they accommodate 300 students. Accommodation is triple bedded room of approximately 300 square feet size having attached toilet / bathrooms, 24-hour hot water facility. Other important facilities like washing machine, TV in the campus that ensure student and staff comfort are a canteen, STD/ISD facilities in each hostel, a beautiful, well-maintained, environment-friendly green campus provides a congenial ambience for Study research.

Adequate stocks of emergency medicines and an ambulance on call.</p>
</div>
                                    
                                       
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon6Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon6Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Language Leadership Lab</h4>
                                    <p>Communication skills are essential for enhancement of student’s personality on one hand and another to augment their interpersonal skills for people management. ISBS provides English language training along with finer aspects of communication skills and utilizes its provision of Language Leadership Lab (LAB): This prepares students for effective skills such as Reading, Speaking, Listening and Writing in English Language. These sessions are extensive, result oriented and are conducted by experienced trainers.

To accomplish the objective, ISBS has well equipped audio- visual “English LAB” with latest technology to enhance interactive learning. The LAB has software that helps students learn and grade themselves on various parameters. The LAB is developed on the methodology of Listening, Speaking, Writing, Reading skills.</p>
                                   <p><b>It has following interactive modules:</b></p>
                                   <ul>
                                    <li>Audio recording for assessing one’s speech</li>
                                    <li>Vocabulary Building modules</li>
                                    <li>
                                    Self-Paced Reading Exercises
                                    </li>
                                    <li>Interactive Quizzes.</li>
                                    <li>Listening Exercises</li>
                                   </ul>
                                </div>
                            </div>
                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    
        </Fragment>
    )
}

export default LifeAtISBS
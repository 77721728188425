import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/about/isbsbanner.jpg"
import MissionImage from "../../assets/images/about/mission.png"
import VisionImage from "../../assets/images/about/vision.png"
import RenuImage from "../../assets/images/Dr.Renu-Bhargava.png"

const IndiraSchoolOfBusinessStudies = () => {
    const [about, setAbout] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#ffffff' }}>Indira School of Business Studies</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">About Us &#62; <span> Indira School of Business Studies</span></h3>
                    </div>
                    <div className="col-md-12 whiteBgWithShadow">
                        <div className="row padding15px">
                            <div className="col-md-2 positionAbsolute mobHide">
                                <img src={MissionImage} alt="" className="circleAboutIcon" />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-11 whiteBgWithShadow paddingCommonbox lightPinkBackground">
                                <img src={MissionImage} alt="" className="circleAboutIconMob1 mobShow" />
                                <h3 className="paddingTop">VISION</h3>
                                <p className="paddingTop">"To become a preferred Business School for Students, Faculty and Industry."</p>
                            </div>
                        </div>
                        <div className="row padding15px">
                            <div className="col-md-2 positionAbsolute mobHide">
                                <img src={VisionImage} alt="" className="circleAboutIcon" />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-11 whiteBgWithShadow paddingCommonbox lightBlueBackground">
                                <img src={VisionImage} alt="" className="circleAboutIconMob1 mobShow" />
                                <h3 className="paddingTop">MISSION</h3>
                                <ul className="liVM">
                                <li>To promote a learning environment for students from diverse cultures and engage them in globally relevant knowledge dimensions for future applications in the Industry, Business and Life.</li>
                                <li>To provide research-oriented learning environment for faculty.</li>
                                <li>To foster alliances with industry.</li>
                                <li>To promote ethical and social values as a basis of humane social order.</li>
                                </ul>
                            </div>
                        </div>
                        <br />
                        <p className="paddingLeft70px">Indira School of Business Studies, Pune, was established under the aegis of Shree Chanakya Education Society (SCES).</p>
                        <p className="paddingLeft70px">Shree Chanakya Education Society began as a trust in 1994, under the visionary leadership of Dr. Tarita Shankar, with an explicit
                            vision to provide sustainable impetus to the corporate and entrepreneurial abilities in the youth. Unrelenting initiatives by the
                            management team at Indira led to the creation of multi disciplines such as Management, Information Technology, Pharmacy,
                            Commerce, Science and Engineering.</p>
                        <div className="mobHide">
                        <p className="paddingLeft70px">All this growth is enabled and sustained by the expertise and exertions of Prof. Chetan
                            Wakalkar, Group Director, Indira Group of Institutes, whose commitment and concern for students’ development stands unmatched.
                            SCES has grown steadily and today boasts of several Institutes, having more than 12,000 students, from all over India pursuing multidisciplinary graduate and post-graduate programs. The Institutes managed by SCES have maintained high academic standards and
                            have successfully provided trained manpower to the diverse industry sectors of the country.</p>
                        <p className="paddingLeft70px">Indira School of Business Studies (ISBS) was established as a professional school to meet the growing needs of the business sector
                            with an idea to bridge the gap between the ‘Campus and Corporates’. In a short span of time, the programs of the Institute were
                            approved by AICTE and ISBS established its presence in Pune as a premier business educational institute. The curriculum design,
                            methodology utilized, coupled with technology for teaching keeps the students abreast of the trends in the field of business and
                            assures greater articulation of the same.</p>
                        <p className="paddingLeft70px">ISBS has featured in Top 100 Management Institutes in India in NIRF 2019 Rankings. The National Institutional Ranking Framework
                            (NIRF) is approved by the Ministry of Human Resource Development. ISBS believes that the emerging global environment requires
                            professional graduates to be equipped with sufficient knowledge and confidence to face tough and competitive scenarios. The current
                            global economy has created an environment where managers have to face greater situational challenges; predict and handle the
                            impulses of the market and provide better quality products and services.</p>
                        </div>
                        <div className="mobShow">
                        {about &&
                        <span>
                        <p className="paddingLeft70px">All this growth is enabled and sustained by the expertise and exertions of Prof. Chetan
                            Wakalkar, Group Director, Indira Group of Institutes, whose commitment and concern for students’ development stands unmatched.
                            SCES has grown steadily and today boasts of several Institutes, having more than 12,000 students, from all over India pursuing multidisciplinary graduate and post-graduate programs. The Institutes managed by SCES have maintained high academic standards and
                            have successfully provided trained manpower to the diverse industry sectors of the country.</p>
                        <p className="paddingLeft70px">Indira School of Business Studies (ISBS) was established as a professional school to meet the growing needs of the business sector
                            with an idea to bridge the gap between the ‘Campus and Corporates’. In a short span of time, the programs of the Institute were
                            approved by AICTE and ISBS established its presence in Pune as a premier business educational institute. The curriculum design,
                            methodology utilized, coupled with technology for teaching keeps the students abreast of the trends in the field of business and
                            assures greater articulation of the same.</p>
                        <p className="paddingLeft70px">ISBS has featured in Top 100 Management Institutes in India in NIRF 2019 Rankings. The National Institutional Ranking Framework
                            (NIRF) is approved by the Ministry of Human Resource Development. ISBS believes that the emerging global environment requires
                            professional graduates to be equipped with sufficient knowledge and confidence to face tough and competitive scenarios. The current
                            global economy has created an environment where managers have to face greater situational challenges; predict and handle the
                            impulses of the market and provide better quality products and services.</p>
                            </span>
                            }
                            <span className="readButton" onClick={() => setAbout(!about)}>{about ? "Read Less" : "Read More"}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Director's Message</h3>
                    </div>
                    <div className="col-md-12 padding5px">
                        <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                            <div className="row">
                                <div className="col-md-12 positionRelative">
                                    <div className="row padding15px">
                                        <div className="col-md-3 positionRelative">
                                            <div className="col-md-12 positionRelative">
                                                <img src={RenuImage} alt="" className="imgRes" />
                                                {/* <p className="font14"><b>Dr. Renu Bhargava</b></p> */}
                                                <p className="font14"><b>Dr. Komal Singh</b></p>
                                                <p className="font14">Director – Indira School of Business Studies, Pune.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <p>ISBS has complete faith in the potential of Indian youth and believes that unleashing such
                                                potential, demands that all the students learning to occur within a powerful learning
                                                environment which provides active, self-directed acquisition of knowledge, skills and attitudes.</p>
                                            <p>At ISBS our industry-academia interaction brings forth managers of business corporations to
                                                extend the knowledge of their challenges, with the future leaders of business. All such efforts
                                                are aimed to have an impact commensurate with every student's ambition. The curriculum is
                                                ever evolving and has a progressive outlook with an intellectual rigor, where a sound
                                                foundation of critical thinking and articulating is laid, in an environment that encourages
                                                curiosity and discovery. We enlist the support of a strong team of faculty, who put their best
                                                efforts to motivate our students and guide them towards professionalism. ISBS is where it is
                                                today, due to the synergy between our organizational vision and culture. We have adopted a
                                                holistic approach, integrating physical, emotional and social development as we aim to build
                                                an attitude amongst the young aspiring managers, on how they can continually expand their
                                                capacity to create the results they truly desire. These values form the basis of our efforts to
                                                maintain being a premier management institute and we look forward to sharing this
                                                experience with you.</p>
                                            <p>Academic institutions have the potential to generate a wave of positive change, thereby
                                                ensuring that individual aspirations flourish along with sustainable growth for business and
                                                environment. Such vast and incredible knowledge generation shapes the attitudes and
                                                behavior of business leaders through business education, research, training, and other
                                                pervasive, but less tangible, activities such as the spread and advocacy of new values and
                                                ideas. The roles and responsibilities of business as a global force are becoming more urgent
                                                and complex, and concepts related to foresight and agile decision making skills are gaining
                                                recognition as essential elements in business management. Indira School of Business Studies
                                                (ISBS) recognizes this precept of business leadership and augments its teaching and learning
                                                to drive business behavior through academics.</p> */}
                                                <p>Dear Students<br/>
                                                    The Indira School of Business Studies is pleased to have you. 
                                                    </p>
                                                <p>I feel fortunate and thrilled to be a part of this outstanding educational institution, and I am grateful for the assistance of my committed professors, staff, ever-helpful parents, and the entire Indira community. I sincerely believe that management education is essential for both an individual's and a nation's advancement. It is a centre of knowledge and a storehouse of superior intellectual achievement.</p>
                                                <p>We are all aware that the complex world in which we live today is undergoing rapid change and offers us both opportunities and challenges. In this context, it all comes down to how each individual approaches the problem with the proper attitude and make decisions daily. We work hard to make every day of learning, a special day for every student who enters the portals of Indira School of Business Studies, 
                                                    with a heart and mind set to grow more than yesterday.</p>
                                                <p>Your power to choose the direction of your life allows you to reinvent yourself, to change your future, and to powerfully influence the rest. Learning and leadership are mutually beneficial. Only those who are capable of learning and adapting are considered to be educated. You are the "Change" for your future and for yourself. 
                                                        So, learn, lead and Inspire others.</p>
                                                <p>I'd like to welcome you all to this outstanding institution of higher learning and reassure you that you will find here a nurturing and caring atmosphere that will help you all grow into strong and compassionate individuals.</p>
                                        
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 padding5px">
                        <div className="col-md-12 marginBottom2em">
                            <div className="row">
                                <div className="col-md-5">
                                    <h3 className="blockTitle">Program Educational Objectives(PEOs)</h3>
                                    <ul>
                                        <li>Provide opportunity for application
                                            oriented learning in the field of business
                                            management to enhance decision
                                            making and leadership skills for
                                            managing dynamic business
                                            environments.</li>
                                        <li>Engaging faculty in research and
                                            training to impart current business
                                            trends to students to enhance their
                                            analytical and critical thinking.</li>
                                        <li>Forge industry and academic interface
                                            for student's exposure to manage the
                                            dynamics of business management.</li>
                                        <li>Expose students to various community</li>
                                    </ul>
                                </div>
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-5">
                                    <h3 className="blockTitle">Program Outcomes (POs)</h3>
                                    <ul>
                                        <li>Apply knowledge of management
                                            theories and practices to solve
                                            business problems</li>
                                        <li>Foster analytical and critical thinking
                                            abilities for data - based decision
                                            making.</li>
                                        <li>Ability to develop Value based
                                            leadership.</li>
                                        <li>Ability to understand, analyze and
                                            communicate global, economic, legal,
                                            and ethical aspects of business.</li>
                                            <li> Ability to lead themselves and others
                                            in the achievement of organizational
                                            goals, contributing effectively to a
                                            team environment.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="backgroundGrey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="blockTitle marginBottom2em">Management Advisory Board-ISBS</h3>
                        </div>

                        <div className="col-md-12">
                            <p>Management Advisory Board steers the efforts in furthering the mission and goal of an Institute. Board members provide advice on strategic
                                planning and developing the Institute's reputation locally, nationally and globally as leaders in business education. As a visible and influential
                                body in the community, the board acts as a conduit to link individuals, resources and opportunities to the Institute. Through their leadership
                                positions in respective industries and communities, board members garner support for the research, teaching and service initiatives of the
                                Institute. The Management Advisory Board comprises of individuals who have distinguished themselves in their careers and who are
                                recognized leaders in their professions and communities. The members are known for their ability to synthesize information and place it within
                                the appropriate social, environmental and economic context. The strength and diversity of the membership is representative of the business
                                communities that Indira School of Business Studies serves</p>
                        </div>
                        <br/>
                        <div className="col-md-12 whiteBackground">
                        <h3 className="blockTitle marginBottom2em">Members of Advisory Board AY 2022-23</h3>
                            <div className="table table-responsive">
                            <table className="table table-responsive">
                            <thead>
                            <tr><th>Sr. No. </th><th> Name </th><th> Organization </th><th> Individual Profile </th><th> Member Designate</th></tr>
                            </thead>
                            <tbody>
                            <tr><td>1 </td><td> Mr. Sandeep Prabhakar Gaikwad </td><td> Indira Group of Institutes </td><td> Group Director </td><td> Vice - President</td></tr>
                            <tr><td>2 </td><td> Dr. Komal Singh </td><td> Indira School of Business Studies </td><td> Director of Institute </td><td> Convener/ Secretary</td></tr>
                            <tr><td>3 </td><td> Ms. Madhuri Sathe </td><td> Indira Group of Institutes </td><td> Executive Director Corporate Relations </td><td> Member IGI</td></tr>
                            <tr><td>4 </td><td> Mr. Atul Nevase </td><td> Straive – Connecting the Dots, Pune </td><td> Associate Vice President </td><td> Member Industry</td></tr>
                            <tr><td>5 </td><td> Ms. Meenu Bhatia </td><td> V Mentor. ai. Mumbai.</td><td>Co - Founder </td><td> Member Industry</td></tr>
                            <tr><td>6 </td><td> Mr. Kaivalya Khandalgaonkar </td><td> Vodafone Intelligent Solutions, India, Pune </td><td> Deputy General Manager </td><td> Member Industry</td></tr>
                            <tr><td>7 </td><td> Mr. Sughosh Tembre </td><td> Infosys, Pune </td><td> Senior Manager HR </td><td> Member Industry</td></tr>
                            <tr><td>8 </td><td> Mr. Sandeep Raut </td><td> Going Digital </td><td> Founder and CEO </td><td> Member Industry</td></tr>
                            <tr><td>9 </td><td> Mr. Frederick Sidney Correa </td><td> Dara shaw & Company Private Limited </td><td> Senior Associate VP </td><td> Member Industry</td></tr>
                            <tr><td>10 </td><td> Dr. Manmohan Vyas </td><td> Indira School of Business Studies </td><td> Deputy Director </td><td> Member Academics</td></tr>
                            <tr><td>11 </td><td> Dr. Arvind Burande </td><td> Indira School of Business Studies </td><td> Assistant Professor </td><td> Member Academics</td></tr>
                            <tr><td>12 </td><td> Mr. Yogesh Kumbhar </td><td> KPMG, Pune </td><td> Consultant </td><td> Member Alumni</td></tr>
                            <tr><td>13 </td><td> Mr. Akshay Deokar </td><td> Hero MotoCorp, Pune </td><td> Territory Manager Sales </td><td> Member Alumni</td></tr>
                            </tbody>
                                    {/* <thead>
                                        <tr>
                                            <td>Sr. No.</td>
                                            <td>Member Designate</td>
                                            <td>Individual Profile</td>
                                            <td>Organization</td>
                                            <td>Name</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>VP/Member Trustee</td>
                                            <td>Group Director</td>
                                            <td>Indira Group of Institutes</td>
                                            <td>Mr. Chetan Wakalkar</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Convener/ Secretary</td>
                                            <td>Director of Institute</td>
                                            <td>Indira School of Business Studies</td>
                                            <td>Dr. Renu Bhargava</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Member IGI</td>
                                            <td>Executive Director Corporate Relations</td>
                                            <td>Indira Group of Institutes</td>
                                            <td>Ms. Madhuri Sathe</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Member Industry</td>
                                            <td>Associate Vice President</td>
                                            <td>Straive – Connecting the Dots, Pune</td>
                                            <td>Mr. Atul Nevase</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Member Industry</td>
                                            <td>Co - Founder</td>
                                            <td>V Mentor. ai. Mumbai.</td>
                                            <td>Ms. Meenu Bhatia</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Member Industry</td>
                                            <td>Deputy General Manager</td>
                                            <td>Vodafone Intelligent Solutions, India, Pune</td>
                                            <td>Mr. Kaivalya Khandalgaonkar</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Member Industry</td>
                                            <td>Senior Manager HR</td>
                                            <td>Infosys, Pune</td>
                                            <td>Mr. Sughosh Tembre</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Member Industry</td>
                                            <td>Founder and CEO</td>
                                            <td>Going Digital</td>
                                            <td>Mr. Sandeep Raut</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Member Industry</td>
                                            <td>Senior Associate VP</td>
                                            <td>Dara shaw & Company Private Limited</td>
                                            <td>Mr. Frederick Sidney Correa</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Member Academics</td>
                                            <td>Deputy Director</td>
                                            <td>Indira School of Business Studies</td>
                                            <td>Dr. Komal Singh</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Member Academics</td>
                                            <td>Dean</td>
                                            <td>Indira School of Business Studies</td>
                                            <td>Dr. Manmohan Vyas</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Member Alumni</td>
                                            <td>Consultant</td>
                                            <td>KPMG, Pune</td>
                                            <td>Mr. Yogesh Kumbhar</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Member Alumni</td>
                                            <td>Territory Manager Sales</td>
                                            <td>Hero MotoCorp, Pune</td>
                                            <td>Mr. Akshay Deokar</td>
                                        </tr>
                                        
                                    </tbody> */}
                             </table>
                                {/*<table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <td>Sr. No.</td>
                                            <td>Member Designate</td>
                                            <td>Individual Profile</td>
                                            <td>Organization</td>
                                            <td>Name</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Member Industry</td>
                                            <td>Managing Director</td>
                                            <td>Yazaki India Private Limited</td>
                                            <td>Mr. Prashanth Nayak</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Member Industry</td>
                                            <td>Erstwhile V. P</td>
                                            <td>Tech Mahindra</td>
                                            <td>Mr. Shantanu Sharma</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Member Industry</td>
                                            <td>Erstwhile President - Marketing Communication</td>
                                            <td>Bhaskar Group,Mumbai</td>
                                            <td>Mr.Sanjeev Kotnala</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Member Industry</td>
                                            <td>Erstwhile AVP</td>
                                            <td>KPIT Cummins</td>
                                            <td>Ms Renuka Krishnan</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Member Industry</td>
                                            <td>Erstwhile CEO</td>
                                            <td>Tata Yazaki</td>
                                            <td>Mr. B.S. Guha</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Member Industry</td>
                                            <td>Founder and CEO</td>
                                            <td>Going Digital</td>
                                            <td>Mr. Sandeep Raut</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Member Industry</td>
                                            <td>Senior Associate VP</td>
                                            <td>Darashaw & Company Private Limited</td>
                                            <td>Mr. Frederick Sidney Correa</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Member Alumni</td>
                                            <td>Associate Director - India & APAC</td>
                                            <td>Emeritus</td>
                                            <td>Mr. Kevin Pereira</td>
                                        </tr>
                                    </tbody>
                             </table>*/}
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default IndiraSchoolOfBusinessStudies
import React, { Fragment } from "react";
import Header from "../../components/Header";
import Carousel from 'react-bootstrap/Carousel'
import BannerImage from "../../assets/images/student-life/studentlifebanner.jpg"
import Icon1Image from "../../assets/images/student-life/Induction-Program.png"
import Icon2Image from "../../assets/images/student-life/CSR-Activities.png"
import Icon3Image from "../../assets/images/student-life/Industry-Visit.png"
import Icon4Image from "../../assets/images/student-life/Research-Activities.png"
import Icon5Image from "../../assets/images/student-life/Author-Arena.png"
import Icon6Image from "../../assets/images/student-life/Indira-Brand-Slam-2023.png"
import Icon7Image from "../../assets/images/student-life/Garba-&-Dandiya-Night.png"
import Icon8Image from "../../assets/images/student-life/International-Connect.png"
import Icon9Image from "../../assets/images/student-life/Sports-Activities.png"
import BlankImage from "../../assets/images/student-life/blank1.jpg"
import Bonding from "../../assets/videos/Bonding-at-ISBS.mp4"
import Campuslife from "../../assets/videos/Campus-life-at-ISBS.mp4"
import CulturalActivities from "../../assets/videos/Cultural-activities-at-ISBS.mp4"
import FitnessFreakIndiraites from "../../assets/videos/Fitness-Freak-Indiraites.mp4"
import HolisticDevelopment from "../../assets/videos/Holistic-Development-at-ISBS-1.mp4"
import Placement from "../../assets/videos/Placement-readiness.mp4"
import WeatISBS from "../../assets/videos/We-at-ISBS.mp4"
import Sport from "../../assets/videos/Sport.mp4"

const LifeAtISBS = () => {
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#000', textAlign: "left", paddingLeft: '6%' }}>STUDENT LIFE AT ISBS</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Student Life &#62; <span> Student Life At ISBS</span></h3>
                    </div>
                </div>
            </div>
            <div className="">

            <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon1Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon1Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Induction Program</h4>
                                    <p>Induction Program, "Prarambh 10.0," held from November 25th to December 10th, 2022, aimed to help 
                                        new students adjust and feel comfortable in their new academic environment. Activities included 
                                        lectures by eminent personalities, personality development workshops, mentoring, academic orientation, 
                                        specialization familiarization, creative arts, literary activities, and extra-curricular activities like
                                         CSR, sports, and management games. The program also focused on placement process orientation, 
                                         case study methodology, talent hunt, and specialized forums in finance and marketing. With a holistic approach, "Prarambh 10.0" aimed to promote institution culture, 
                                        foster connections, and provide students with a sense of purpose and self-exploration.</p>
                                    
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon2Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon2Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">CSR Activities</h4>
                                    <p>The CSR initiative "PEHEL" by Indira School Business Studies offers a multitude of benefits to students.
                                         Engaging in activities like logo competitions, seed ball distribution, and awareness campaigns allows 
                                         students to actively participate in driving positive change and making a meaningful impact in society.
                                          By being a part of international conferences on sustainability and innovation, students gain exposure
                                           to the latest advancements and develop a global perspective, expanding their knowledge and broadening 
                                           their horizons. Participating in drawing competitions and Diwali Diya decoration activities enables students to unleash their creativity, boosting their self-confidence and providing a platform to express their passion for social and environmental causes. The "Bottle Blossoms: Creating a Garden from Waste" activity fosters teamwork, effective communication, and problem-solving skills, equipping students with essential skills for their personal and professional growth. Overall, the CSR initiative empowers students to become 
                                        responsible global citizens, making a positive difference in the world.</p>
                                  
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon3Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon3Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Industry Visit</h4>
                                    <p>The students at Indira School of Business Studies (ISBS) embarked on an enlightening journey of knowledge 
                                        and exposure as they participated in prestigious events and visited renowned venues. Their adventure began
                                         with a visit to the esteemed World HRD Congress at the iconic Taj Lands End, Mumbai, on February 15, 2023.
                                          There, they gained valuable insights and broadened their horizons in the field of human resource development.
                                           Continuing their quest for learning, the students then explored the cutting-edge world of finance and technology during their
                                            visit to NISM Patalganga on May 9, 2023. Finally, they immersed themselves in the CII Marketing Leadership Summit held at the 
                                            luxurious Trident Hotel, Mumbai. These experiences enriched their academic journey, 
                                        nurturing their passion and providing them with a deeper understanding of their chosen fields.</p>
                  
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon4Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon4Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Research Activities</h4>
                                    <p>The Research Cell at ISBS is dedicated to fostering an academic research-oriented culture, encouraging students 
                                        to actively participate in meaningful research endeavours and enhance their knowledge generation capabilities.
                                         Recently, students from ISBS made notable contributions by participating in conferences and publishing their research 
                                         in reputable journals, including those indexed by UGC, Web of Science, etc. Their research work covered diverse topics such as the
                                          impact of business analytics on supply chain performance in the manufacturing sector, the development of interactive dashboards for 
                                          real-time data visualization and analysis, case studies on the pre-owned car market in India, and customer behaviour towards start-up online advertising. 
                                          These achievements reflect the 
                                        commitment of ISBS to promoting research excellence and nurturing students' research skills.</p>
                 
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon5Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon5Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Author’s Arena </h4>
                                    <p>Indira School of Business Studies, in collaboration with BIJA training, organized a captivating Author's 
                                        Arena session for the 2022-24 batch. The session, titled "Resign or Redesign: 50 Magical Rules to Transform Your Life," 
                                        featured Ms. Vasudha Madaan as the speaker. In this two-hour session, Ms. Madaan shared her unique life experiences, 
                                        inspiring students to navigate through life's challenges with strength and resilience. With her exceptional oratory skills, 
                                        captivating storytelling, and infectious energy, she left a lasting impact on the young minds. This event emphasized the power  of words,
                                         highlighting how they can transform lives and ignite motivation and personal growth.</p>
             
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon6Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon6Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Indira Brand Slam 2023</h4>
                                    <p>The majestic halls of Indira School of Business Studies reverberated with magnificence as they played host 
                                        to the resplendent 10th edition of the Indira Brand Slam Summit and Awards in 2023. Like a captivating symphony, 
                                        the chosen theme, "Changing Paradigms for Building Brands," beckoned forth a congregation of distinguished corporate 
                                        luminaries and ambitious management students. Within this enchanted gathering, the summit wove a tapestry of real-world narratives,
                                         showcasing the ingenious metamorphosis of businesses in the ever-evolving digital realm. As industry stalwarts graced the stage, 
                                         their words resonated like celestial melodies, imparting profound wisdom and guiding the dreams of future leaders. The event exuded an ethereal aura, illuminating the path 
                                         to success through the pillars of innovation, resilience, and adaptability, inspiring all attendees to dance upon the winds 
                                        of change and sculpt their destinies in the dynamic and enchanting world of brand building.</p>
                                   
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon7Image} alt="" className="circleAboutIcon mobHIde" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon7Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Garba & Dandiya Night </h4>
                                    <p>Navratri, a time brimming with significance and auspiciousness, holds a cherished place in the hearts of students 
                                        at ISBS MBA. Understanding the profound value of this vibrant festival, the Cultural Committee 'UDAAN' crafted a 
                                        spellbinding Garba night on October 1st, 2022. This captivating event not only enthralled with its lively ambiance but 
                                        also brought an array of benefits to the students. Through the spirited celebration of Garba, students found themselves 
                                        immersed in a joyful atmosphere, experiencing a sense of belonging and camaraderie. The rhythmic beats and graceful movements of Garba
                                         fostered a sense of unity, enabling students to forge new connections and strengthen existing bonds. Moreover, the cultural splendour
                                          of the night provided an enriching platform for students to appreciate and respect diverse traditions, expanding their cultural horizons 
                                          and nurturing a spirit of inclusivity. Ultimately, the Garba night served as a conduit for students' personal growth, 
                                        offering them an opportunity to unwind, rejuvenate, and embrace the infectious energy of Navratri.</p>
                                    
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon8Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon8Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">International Connect </h4>
                                    <p>X-Culture, launched in 2010, provides students in International Business/Management courses a transformative 
                                        learning opportunity. By collaborating in international teams, students gain practical knowledge and best practices 
                                        in global collaboration. Over eight weeks, they develop business proposals and reports, covering competitive analysis,
                                         market entry, pricing, promotion, marketing, finances, and trade regulations. ISBS, an advanced signatory to PRME, upholds 
                                         its six principles of responsible management education. Inspired by the UN Global Compact, ISBS integrates socially responsible 
                                         practices and has engaged in activities such as Breast Cancer Awareness, case studies and the Indira Global Leadership Lecture Series.
                                          ISBS takes pride in having a student representative as a 
                                        PRME Global Students Regional Leader, showcasing their commitment to responsible management education.</p>
                                    
                                </div>
                            </div>

                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon9Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon9Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Sports Activities  </h4>
                                    <p>ISBS recognizes the significance of physical fitness in promoting the mental well-being of students. 
                                        To foster a holistic development approach, the Sports Committee organizes a range of sporting events
                                         encompassing both outdoor and indoor games. Students are provided with opportunities to pursue their 
                                         sporting passions, enhance their teamwork skills, and participate in intercollege competitions.
                                          Notably, the flagship event, SPORTACUS, brought back the thrill of sports and created cherished
                                           memories for students. Additionally, activities such as the Walkathon, Zumba sessions, and Fitness 
                                           Challenges were organized to ensure students remained mentally and physically fit, even while staying indoors. ISBS firmly believes that sports offer abundant benefits, including emotional well-being, 
                                        character building, self-confidence, time management, and leadership skills.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{marginTop:"2em"}}>
                   <div className="container">
                     <div className="row">
                     <Carousel interval={3000}>
                    <Carousel.Item>
                    <div className="row">
                    <div className="col-md-6">
                    <video width="100%" height="315" controls>
                                <source src={Bonding} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Bonding at ISBS</h4>
                           </div>  
                           <div className="col-md-6">
                           <video width="100%" height="315" controls>
                                <source src={Campuslife} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Campus life at ISBS </h4> 
                           </div>
                        </div> 
                    </Carousel.Item>
                    <Carousel.Item>
                    <div className="row">
                    <div className="col-md-6">
                    <video width="100%" height="315" controls>
                                <source src={CulturalActivities} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Cultural Activities at ISBS</h4>
                           </div>  
                           <div className="col-md-6">
                           <video width="100%" height="315" controls>
                                <source src={FitnessFreakIndiraites} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Fitness Freak Indiraites</h4>
                           </div> 
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                    <div className="row">
                    <div className="col-md-6">
                    <video width="100%" height="315" controls>
                                <source src={HolisticDevelopment} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Holistic Development at ISBS</h4>
                           </div>  
                           <div className="col-md-6">
                           <video width="100%" height="315" controls>
                                <source src={Placement} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Placement Readiness</h4>
                           </div>
                           </div>
                    </Carousel.Item>
                    <Carousel.Item>
                    <div className="row">
                    <div className="col-md-6">
                    <video width="100%" height="315" controls>
                                <source src={WeatISBS} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">We at ISBS </h4>
                           </div>
                           <div className="col-md-6">
                           <video width="100%" height="315" controls>
                                <source src={Sport} type="video/mp4" />
                                
                                </video> 
                                <h4 className="blockTitle">Sport</h4>
                           </div> 
                           </div>
                    </Carousel.Item>
               
                  

                </Carousel>
                        </div>
                   </div>
                </div>
                                {/* <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon1Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon1Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Guest Session on Depression Management</h4>
                                    <p>Believing in negative thoughts is the single greatest obstruction to success. During their pursuit of knowledge and
                                        achievement, many students often face negative thoughts. An interactive session with Mr. Gaurav Warman – a
                                        motivational speaker, social activist, and a cyclist was organized on September 11, 2019. He is cycling 4350 km from
                                        Kanyakumari to Kashmir for the cause of “Fight against Depression”. In his, one-hour talk Mr. Warman demonstrated
                                        techniques to combat depression such as visualization, nutrition, forgiveness, gratitude, and self-affirmation to name a
                                        few. His talk rightly conveyed the message “It’s one life you have, Live with Spark!”</p>
                                    
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon2Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon2Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Indira Foundation Day 2019</h4>
                                    <p>5th September 2019 Indira Group of Institutes celebrated its Foundation Day as another successful year passed by. As a
                                        new journey began into the 26th Year of IGI it was a proud moment for ISBS. ISBS faculty Prof. Neha Agarwal was
                                        recognized for winning a spot at the internationally acclaimed Peter Drucker Challenge. ISBS faculty who were doctorates
                                        were recognized with Citations of Honor as well. Deputy Director of ISBS, Dr. Komal Singh secured a prize in an IGI Quiz
                                        on how well you know IGI. The pre-lunch Foundation Day ceremony was closed with a special interview, a “Vartalaap”
                                        with IGI Chairperson Dr. Tarita Shankar and Group Director, Prof. Chetan Wakalkar. It was truly a wondrous and magical
                                        day for all ISBS faculty and staff which ended with a beautiful ghazal program by the music maestros- Ashok Khosla and
                                        Jazim Sharma.</p>
                                  
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon3Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon3Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Talent Hunt 2019 & Euphoria</h4>
                                    <p>27th August, 2019 was a happening day for ISBS students. Energy, enthusiasm, hard work, team work and lots and lots
                                        of talent is what the students put up on the stage! From poetry to painting, from dancing to singing and walking down the
                                        ramp with oomph and style the students packed in a day of total entertainment for their audiences. The event not only
                                        gave wings to the talent and creativity of students but also stimulated the process of holistic development</p>
                                    <p>The Talent Hunt culminated with a popular band from Pune, JoyDhaam live in performance at the ISBS Campus.
                                        JoyDhaam performed many foot tapping numbers – old and new. The students both seniors and juniors, faculty, staff,
                                        directors and all the fraternity of Indira danced and moved to the magic of music. The event proved to be true to its name
                                        “Euphoria!”</p>
                  
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon4Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon4Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">International Day of YOGA</h4>
                                    <p>Indira School of Business Studies has celebrated 5th INTERNATIONAL Day of YOGA, which was initiated, pioneered,
                                        emphasized and advocated by Honorable Prime Minister of India Sri Narendra Modi ji, He said, “Yoga is an invaluable gift
                                        of India’s ancient tradition. It embodies unity of mind and body; thought and action; restraint and fulfilment; harmony
                                        between man and nature; a holistic approach to health and well-being. It is not about exercise but to discover the sense of
                                        oneness with yourself, the world and the nature. By changing our lifestyle and creating consciousness, it can help in
                                        wellbeing.” International Day of Yoga Day was celebrated by the faculties and staff members at Indira School of Business
                                        Studies with great enthusiasm. ISBS has invited certified Yoga Trainer and he demonstrated various Asanas followed by
                                        Omkar Chanting, Head Stands, Surya Namaskar. Warm up exercises were taken and all the faculty and staff members
                                        performed sitting and standing Asanas, importance of these were explained simultaneously. The celebration concluded
                                        with synchronized recitation of shlokas. He encouraged faculty and staff members to practice regular yoga to remain fit
                                        and improve concentration. Regular practice of yoga will surely help to achieve a better life, physically, mentally and
                                        spiritually as well.</p>
                 
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon5Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon5Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Book Review Session by Rishi Kapal</h4>
                                    <p>If you can make your kite fly high in hurricanes, you are here to survive. ISBS today proudly hosted the author of “Kites in
                                        Hurricanes” a book that has stories of 15 companies with which the writer Rishi Kapal, a Stanford LEAD University Alumni
                                        and ex MD and VICE PRESIDENT of Sony Experia has worked with. He gave the inputs on how he has written this book
                                        and how students can inculcate these teachings for their career growth. ISBS conducted a guest session on ‘Merchant
                                        Banking and Primary Market Operations’ for PGDM Finance Students of Batch 2018-20. The session was conducted by
                                        Mr. Vinit Iyer (CFA, CFP & CWM) Founder Wealth Creators Financial Advisers. This was a very insightful and knowledge
                                        enriching session for the students.</p>
             
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon6Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon6Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Traditional Day Celebrations</h4>
                                    <p>On 21st January the cultural team – Udaan organized the event “Traditional Day
                                        ”
                                        which saw enthusiastic participation
                                        from the batch of 2018-20. As the name suggests the event was an effort to celebrate the diverse traditions & cultures of
                                        our beautiful country- India; with harmony! The students, faculty & staff of the institute came dressed beautifully
                                        portraying the dress of different regions or India. Some students presented dance performances exhibiting their rich
                                        cultural heritage through the medium of dance. Truly it made us all realize that for us Indians it is “Unity in Diversity
                                        ”.</p>
                                   
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon7Image} alt="" className="circleAboutIcon mobHIde" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon7Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">Dewang Mehta Educational Awards</h4>
                                    <p>Six bright and enterprising students went to Mumbai on November 28th, 2018 to represent ISBS in various prestigious
                                        competitions at the Dewang Mehta Educational Awards. Prasad Mane & Kamalshil Manohare of 2018-20 MBA batch
                                        bagged the 1st prize in the Paper Presentation Contest and raised the flag of Indira high. The topic of their paper was
                                        “Role of Sustainability in Innovative branding”. Enthuzia 2K18 The students of 18-20 batch organized Enthuzia 2018. The
                                        event flagged off with a band performance by the musical members of the batch. The competitions organized included
                                        Mime on Social causes, Stand Up Comedy Act and Skits, giving the students a platform to recognize their hidden potential.
                                        Students surprised us by carrying forward the theme of social causes to the skits as well. Each performance was
                                        meaningful. They not only gave us goose bumps because of the sheer emotions we were going through but also left us
                                        with a feeling of inspiration to change. Kudos to all the members of the batch for a splendid show! AIMA Presentation
                                        Competition Regionals Four students of the PGDM 18-20 batch went to represent ISBS at the AIMA Presentation
                                        Competition Regionals held in Pune on November 16th. A team comprising of Charles Prem & Priyanka Sagar presented a
                                        “Blueprint to grow Tourism Sector: Turning a potential into a growth driver”. The team won the 2nd runner’s up trophy and
                                        has qualified for the final round to held in New Delhi on November 22nd. We wish them all the very best! Article Writing
                                        Competition Who said a single person cannot make a difference? If you read Ruchika Jain’s article on “The Earth Needs
                                        Your Help”

                                        you will surely agree that change begins from home. Ruchika – an ISBS student from the PGDM 18-20 batch
                                        wrote an article which went on to be selected for the final round of presentation at D.Y. Patil Vidyapeeth’s Institute of
                                        Distance Learning. Ruchika made a 3-minute presentation to the judges on November 17th securing 3rd place at the
                                        Article Writing Competition. Alumni Meet 2018 A founder can carry an institution only so far, and then others have to step
                                        in, even the alumni. Any institutions’ alumni are key to its growth. INDIRA GROUP OF INSTITUTES celebrated” The Alumni
                                        Meet’ 2018 “. The panel discussion, the short film, the dance and what not made the Dhruv Auditorium full of joy. The
                                        evening concluded and made all alumni nostalgic. It was a proud moment for IGI to see its alumni doing so good it their
                                        lives!!</p>
                                    
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={Icon8Image} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                                    <img src={Icon8Image} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h4 className="blockTitle">National Conference on Digitization & Innovation</h4>
                                    <p>Indira School of Business Studies organized National Conference on Digitization & Innovation in association with Savitribai
                                        Phule Pune University on 18th -20th January, 2018. The conference brought together wide range of speakers from all
                                        arenas – entrepreneurship, business, corporates, alumina, higher education, and others to share their experiences, to learn
                                        from one another through panel discussions the future generation of digitization & innovations. The ceremony started with
                                        the arrival of the Chief Guest Mr. Amit Gangal, Principal partner, Education & Competency Development, KPIT. The
                                        conference also brought together participants from different states who greatly enjoyed the chance to meet like-minded
                                        people from so many arenas, the opportunity to network and to learn from one another, and the chance to explore ideas.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </Fragment>
    )
}

export default LifeAtISBS
import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/conference-banner.jpg"
import Img1Image from "../../assets/images/conf1.png"
import Img2Image from "../../assets/images/conf2.png"
import Img3Image from "../../assets/images/conf3.png"
import Img4Image from "../../assets/images/conf4.png"
import ConferenceImage from "../../assets/images/conference.png"
const Conference = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout rightAlignBannerTxt whiteText">Conference</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Research &#62; <span>National Research Conference</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row paddingTop3em paddingBottom3em">
                        <div className="paddingclassMobNew">
                        <div className="col-md-12 whiteBackground marginTop2em marginBottom15px paddingclass">
                            <div className="col-md-12 text-center textLeftM">
                            <img src={ConferenceImage} alt="" className="imgIconCenterTop" />
                            </div>
                            <p>Indira School of Business Studies (ISBS) since its inception has been constantly contributing to the corporate world with well-prepared scholars, researchers, academicians and industry leaders who come together in sharing their knowledge and solving the real world corporate problems.</p>
                            <p>In today's rapidly changing business environment, managing innovation is critical and a prerequisite for the success and competitiveness of companies and organizations, whether public or private. Business ethics is an integral part of today’s business world. It really becomes important for today’s business leaders to run their organization in ethical manner and at the same time there are expectations from various stakeholders like management, customers, shareholders and society at large that businesses should come up with innovation in their product or services and become Atmanirbhar.</p>
                            <p>Indira School of Business Studies has organized AICTE sponsored National Conference on “Atmanirbhar Bharat: A Gateway to future growth and development on 18th September 2021. The imminent speakers for the conference were Dr. Parag Kalkar, Dean, Commerce and Management, SPPU, Pune. Mr. Akshay Adhalrao, Managing Director, Dynalog (India) Ltd, Mumbai and Mr. Nilesh Trivedi, Assistant Director, MSME, Government of India. Dr Parag Kalkar spoke about the importance of National Education Policy and its importance in making India Atmanirbhar. Mr Akshay Adhalrao explained how his company has benefitted from Atmanirbhar Bharat schemes of the government. Mr. Nilesh Trivedi mentioned about various schemes of the Government of India, to make India self-reliant. In the afternoon session, research papers were presented by academicians, researchers, and corporate leaders. Our conference had received 24 research papers across all themes from all over India. Selected papers of the conference will be published in “Yojana” journal of Ministry of Information and Broadcasting, Government of India. In all the conference was well received by the participants and the students. Over 300 students of MBA and PGDM courses along with faculty members attended the conference.</p>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <img src={Img1Image} alt="" className="imgRes paddingBottom1em" />
                        </div>
                        <div className="col-md-6">
                        <img src={Img2Image} alt="" className="imgRes paddingBottom1em" />
                        </div>
                        <div className="col-md-6">
                        <img src={Img3Image} alt="" className="imgRes paddingBottom1em" />
                        </div>
                        <div className="col-md-6">
                        <img src={Img4Image} alt="" className="imgRes" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default Conference

import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/about/compliance-comettee.png"
import { useState } from "react";
import Table from 'react-bootstrap/Table';

const ComplianceCommittee = () => {
  
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout leftAlignBannerTxt whiteText">IQAC <br/>2022-23</h2>
            </div>
            <div className="container">
                                <div className="col-md-12 padding15px whiteBackground">
            <Table class="table table-responsive table-bordered" responsive>
            <thead>
                <tr><th>Sr. No </th><th>Name </th><th>Designation </th><th>Profile </th></tr>
                </thead>
                <tbody>
                <tr><td>1. </td><td>Dr. Komal Singh </td><td>Director </td><td>Chairperson </td></tr>
                <tr><td>2 </td><td>Dr. Arvind Burande </td><td>Asst. Professor </td><td>Secretary </td></tr>
                <tr><td>3</td><td>Mr. Bijoy Guha </td><td>Consultant & Ex. VP Tata Yazaki </td><td>Member Industry </td></tr>
                <tr><td>4 </td><td>Dr. Manmohan Vyas </td><td>Deputy Director </td><td>Member </td></tr>
                <tr><td>5 </td><td>Prof. Amrita Karnawat </td><td>Assistant Professor </td><td>Member </td></tr>
                <tr><td>6 </td><td>Mrs. Sharvari Ratnakar </td><td>Assistant Registrar </td><td>Member: Non-teaching</td></tr>
                </tbody>
            </Table>
            </div>
            </div>
         
        </Fragment>
    )
}

export default ComplianceCommittee
import React, { Fragment } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/alumni/alumnicommittee.jpg"
import Icon1Image from "../../assets/images/alumni/committeeicon1.png"
import Icon2Image from "../../assets/images/alumni/committeeicon2.png"
import Icon3Image from "../../assets/images/alumni/committeeicon3.png"
import Icon4Image from "../../assets/images/alumni/committeeicon4.png"
import Icon5Image from "../../assets/images/alumni/committeeicon5.png"
import Icon6Image from "../../assets/images/alumni/committeeicon6.png"
import AluminiImage1 from "../../assets/images/alumni/1.png"
import AluminiImage2 from "../../assets/images/alumni/2.png"
import AluminiImage3 from "../../assets/images/alumni/3.jpeg"
import AluminiImage4 from "../../assets/images/alumni/4.png"
import AluminiImage5 from "../../assets/images/alumni/5.png"

const AlumniCommittee = () => {
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#ffffff', textAlign: "right", right: '6%' }}>Alumni

                    Committee - DOR</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">ABOUT US &#62; <span> ALUMNI COMMITTEE - DOR</span></h3>
                    </div>
                </div>
            </div>
            <div className="paddingclassMobNew">
                <div className="container marginBottom2em">
                    <div className="row">
                        <div className="col-md-12 roundWithShadow padding15px">
                            <p>The Alumni committee works as a conduit between the alumni and the alma-mater, both at the corporate and personal level. The
                                alumni are our best emissaries in the corporate world and a vital link in the Industry-Institute relationship.</p>
                            <p>The Alumni Association plays an important role in helping to shape the future of our ISBS students by representing the views of its
                                members and contributing to building an engaged and supportive alumni community.</p>
                            <p>Our alumni hold prominent positions in various sectors and are always on the fore-front to mentor/counsel/guide our current students.
                                This is done in a structured pattern.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <div className="col-md-12 roundWithShadow padding15px text-center iconBoxMinHeight">
                            <img src={Icon1Image} className="imgRes maxWidthIcon" />
                            <p className="text-center">General Interaction
                                and Counselling</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="col-md-12 roundWithShadow padding15px text-center iconBoxMinHeight">
                            <img src={Icon2Image} className="imgRes maxWidthIcon" />
                            <p className="text-center">Dialect - Grooming
                                sessions by alumnus
                                and alumnae</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="col-md-12 roundWithShadow padding15px text-center iconBoxMinHeight">
                            <img src={Icon3Image} className="imgRes maxWidthIcon" />
                            <p className="text-center">SICD – Students
                                Involvement in
                                Curriculum Development</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="col-md-12 roundWithShadow padding15px text-center iconBoxMinHeight">
                            <img src={Icon4Image} className="imgRes maxWidthIcon" />
                            <p className="text-center">Think-In – Mock
                                Personal Interview’s
                                and SIP inputs</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="col-md-12 roundWithShadow padding15px text-center iconBoxMinHeight">
                            <img src={Icon5Image} className="imgRes maxWidthIcon" />
                            <p className="text-center">Hustle -
                                Entrepreneurship
                                workshops</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="col-md-12 roundWithShadow padding15px text-center iconBoxMinHeight">
                            <img src={Icon6Image} className="imgRes maxWidthIcon" />
                            <p className="text-center">Board Room Talks -
                                Preparatory activities</p>
                        </div>
                    </div>
                    <p className="marginTop2em">Our alumni also provide immense assistance towards procuring SIP projects and final placements. ISBS alumni also are actively involved in
                        supporting Indira Groups Initiative – Indira Sustainable Maharashtra Initiative (ISMI). Under this noble cause, ISBS alumni cater to the
                        underprivileged children of the farmers. At ISMI - IGI believes that education and training are powerful tools against poverty and hunger.
                        Under this program, many of the alumnus have contributed in raising the necessary resources which are required to fulfil the objectives of the
                        program.
                    </p>
                </div>
            </div>
            <div className="backgroundGrey" style={{ paddingTop: 0 }}>
                <div className="container">
                    <div className="row">
                        <h3 className="blockTitle text-center">Methodology to Connect with Alumni and its implementation</h3>
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row darkGreyBg">
                                <div className="col-md-6 whiteBackground">
                                    <h4 className="paddingTop">Portal</h4>
                                    <p>We have a dedicated portal www.alumni.indiraisbs.ac.in for
                                        connecting the alumni and updating their current profiles.
                                        The alumnus is also given their username and password
                                        through which they upload information about internship, job
                                        opportunities etc.
                                    </p>
                                    <ul  className="reg">
                                        <li>The objective of having this portal is to bring all the
                                            alumni together on one platform, ensure networking
                                            among them and also serve as a platform for sharing
                                            the requirements in the companies in terms of
                                            internship, job opportunities and even lateral
                                            placements for experienced candidates.</li>
                                        <li>The portal also serves as a platform where the
                                            achievements, rewards, recognition and awards given
                                            to the alumni by any professional organization or their
                                            company acknowledging their work, appreciation is
                                            showcased and highlighted on the web portal.</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                <img src={AluminiImage1} alt="" className="imgRes" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container marginTop3em">
                    <div className="row">
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row darkGreyBg">
                                <div className="col-md-6">
                                <img src={AluminiImage2} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-6 whiteBackground">
                                    <h4 className="paddingTop">Homecoming - Alumni Meet</h4>
                                    <p>Alumni Meets are organized yearly to network and
                                        explore the opportunities available in the market /
                                        companies where our alumnus is working. We at ISBS,
                                        ensure that all the batched right from inception of the
                                        program attend the alumni meet
                                    </p>
                                    <ul className="reg">
                                        <li>The objective of having all the students physically
                                            present in the college or in some public place gives
                                            them an opportunity to meet the people with whom
                                            they have been interacting on the web portal for so
                                            long. </li>
                                        <li>The alumni meet also serves the purpose of
                                            community building. Having these students meet each
                                            other, meet the faculties who had once taught them
                                            reignites the patriotic feeling towards the alma mater.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container marginTop3em">
                    <div className="row">
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row darkGreyBg">
                                <div className="col-md-6 whiteBackground">
                                    <h4 className="paddingTop">Chapters</h4>
                                    <p>Currently have Pune Chapter of Alumni which is
                                        organized by the Pune Chapter representative. The
                                    </p>
                                    <ul className="reg">
                                        <li>Promote the interests and welfare of ISBS Alumni
                                            Association</li>
                                        <li>Provide opportunities to participate in and serve
                                            the ISBS Alumni Association</li>
                                        <li>Promote programs that enrich the professional,
                                            cultural and personal lives of alumni</li>
                                        <li>Represent alumni interests and concerns</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                <img src={AluminiImage3} alt="" className="imgRes" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container marginTop3em">
                    <div className="row">
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row">
                                <div className="col-md-6">
                                <img src={AluminiImage4} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-6 whiteBackground">
                                    <h4 className="paddingTop">Newsletters</h4>
                                    <p>Bi-Annual Online Magazine featuring alumni articles is
                                        published through which any achievement/ award/
                                        promotion that the alumnus has received is published.
                                    </p>
                                    <ul className="reg">
                                        <li>INSPIRA:<br />The newsletter serves as a good medium of
                                            disseminating information related to the
                                            organization. This newsletter also involves a
                                            separate heading with regards to the alumni
                                            contribution. The alumni newsletter also serves
                                            as a write up for showcasing different activities
                                            that have been initiated at ISBS and the past
                                            semester activities that have been concluded at
                                            ISBS.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container marginTop3em">
                    <div className="row">
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row darkGreyBg">
                                <div className="col-md-6 whiteBackground">
                                    <h4 className="paddingTop">Database</h4>
                                    <p>Periodic review of Alumni Database is done which is
                                        undertaken by the Alumni committee members. This
                                    </p>
                                    <ul className="reg">
                                        <li>INSPIRA:<br />The newsletter serves as a good medium of
                                            disseminating information related to the
                                            organization. This newsletter also involves a
                                            separate heading with regards to the alumni</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                <img src={AluminiImage5} alt="" className="imgRes" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AlumniCommittee
import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/phd/banner.png"
import UpdateIconImage from "../../assets/images/phd/updateIcon.png"
import ContactIconImage from "../../assets/images/phd/contactIcon.png"
import DocumentIconImage from "../../assets/images/phd/documentIcon.png"
import ProcessIconImage from "../../assets/images/phd/processIcon.png"
import AboutIconImage from "../../assets/images/phd/abIcon.png"
import ApplyImage from "../../assets/images/mba/apply-now-btn.png"
import DownloadImage from "../../assets/images/mba/download-btn.png"
import phdnotice from '../../assets/pdf/phd-Notice-Website.pdf'
import phdnotice1 from '../../assets/pdf/PhD-Interview-Round-II_Notice-2023.pdf'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';



const Phd = () => {
    const [About, setAbout] = useState(true);
    const [Process, setProcess] = useState(false);
    const [Documents, setDocuments] = useState(false);
    const [Contacts, setContacts] = useState(false);
    const [Update, setUpdate] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState("About PGRC");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const AboutHandler = () => {
        setAbout(true);
        setProcess(false)
        setDocuments(false)
        setContacts(false)
        setUpdate(false)
        setBreadcrumb("About PGRC")
    }
    const ProcessHandler = () => {
        setAbout(false);
        setProcess(true)
        setDocuments(false)
        setContacts(false)
        setUpdate(false)
        setBreadcrumb("The Process")
    }
    const DocumentsHandler = () => {
        setAbout(false);
        setProcess(false)
        setDocuments(true)
        setContacts(false)
        setUpdate(false)
        setBreadcrumb("Documents Required")
    }
    const ContactsHandler = () => {
        setAbout(false);
        setProcess(false)
        setDocuments(false)
        setContacts(true)
        setUpdate(false)
        setBreadcrumb("Contacts")
    }
    const UpdateHandler = () => {
        setAbout(false);
        setProcess(false)
        setDocuments(false)
        setContacts(false)
        setUpdate(true)
        setBreadcrumb("Latest Update")
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popupShow, setPopupShow] = useState(false);
    const handleClosePopup = () => setPopupShow(false);
    const handleShowPopup = () => setPopupShow(true);

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://widgets.in8.nopaperforms.com/emwgts.js";
        script.async = true;
      
        document.body.appendChild(script)
      
      }, []);
      
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <div className="buttonContainerCourse">
                    <img src={ApplyImage} alt="" onClick={handleShowPopup} />
                    <img src={DownloadImage} alt="" />

                    <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Business Leader of the Year 2022</Modal.Title>
                </Modal.Header>
                <Modal.Body>Congratulations to our Chairperson Dr. Tarita Ma'am and Group Director Prof. Chetan Sir for the awards at the Business Leader of the Year 2022 Ceremony.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className={popupShow?"enquireNowFormCont enableEnquire ":"enquireNowFormCont"}>
                <div className="closeBtn" onClick={handleClosePopup}>X</div>
                <div className="formtitle">
                    <h4 className="titleTxt">Enquire Now</h4>
                </div>

                <div class="npf_wgts" data-height="500px" data-w="ad6d9c5fe9fce98f41e97e97434181b3"></div>
                {/* <div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="username" name="username" placeholder="Name" required="" />
                    </div>
                    <div className="form-group">
                        <input type="Email" className="form-control" id="email" name="email" placeholder="Email Address" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="contact" name="contact" placeholder="Phone Number" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name="city" id="city" placeholder="City" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="state" name="state" placeholder="State" required="" />
                    </div>
                    <div className="checkbox">
                        <label><input name="checkbox" type="checkbox" id="checkbox" value="" required="" />I agree to receive information regarding my registration by signing up on Indira Group of Institutes, Pune *</label>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="registerBtn" id="register" onClick={handleClosePopup}>Register</button>
                    </div>
                </div>
                <div id="success_msg" className="alert alert-success" style={{ display: 'none' }}>
                    <strong>Thank you!</strong>
                </div>
                <div id="error_msg" className="alert alert-warning" style={{ display: 'none' }}>
                    <strong>Warning!</strong>
                </div> */}

            </div>
       </div>
    </div>
            <div className="col-md-12 paddingclass bgOrange1 mpaddingTop0 mpaddingBottom0">
                <div className="row">
                    <h3 className="blockTitle whiteText">Courses &#62; P.hD &#62; <span style={{ color: '#fff', fontWeight: '900' }}>{breadcrumb}</span></h3>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="paddingBottom3em paddingTop3em">
                        <div className="col-md-12 somi">
                            <div className="row marginZero tabViewHead">
                                <div className={About ? "col-md-2 wd50 active" : "col-md-2 wd50"}>
                                    <h3 className="tabTitle" onClick={AboutHandler}><img src={AboutIconImage} className="mbaSmIcon" /> About PGRC</h3>
                                </div>
                                <div className={Process ? "col-md-2 wd50 active" : "col-md-2 wd50"}>
                                    <h3 className="tabTitle" onClick={ProcessHandler}><img src={ProcessIconImage} className="mbaSmIcon" /> The process</h3>
                                </div>
                                <div className={Documents ? "col-md-2 wd50 active" : "col-md-2 wd50"}>
                                    <h3 className="tabTitle" onClick={DocumentsHandler}><img src={DocumentIconImage} className="mbaSmIcon" /> Documents req.</h3>
                                </div>
                                <div className={Contacts ? "col-md-2 wd50 active" : "col-md-2 wd50"}>
                                    <h3 className="tabTitle" onClick={ContactsHandler}><img src={ContactIconImage} className="mbaSmIcon" /> Contacts</h3>
                                </div>
                                <div className={Update ? "col-md-2 wd50 active" : "col-md-2 wd50"}>
                                    <h3 className="tabTitle" onClick={UpdateHandler}><img src={UpdateIconImage} className="mbaSmIcon" /> Latest Update</h3>
                                </div>
                            </div>
                        </div>
                        {About &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">About Us</h3>
                                                <p>ISBS Post Graduate Research Centre (PGRC) recently established in the year 2021. ISBS’s (PGRC) is approved and affiliated to Savitribai Phule Pune University for Ph.D. degree. PGRC provides guidance to the Ph.D. students in the following areas of specialization.</p>
                                                <ul className="paddingLeft15px liGb MpaddingLeft1em">
                                                    <li>Human Resource Management</li>
                                                    <li>Financial Management</li>
                                                    {/*<li>Marketing Management</li>*/}
                                                    <li>Organizational Management </li>
                                                </ul>
                                                {/*<p>At present 8 students are pursuing their PhD under ISBS PGRC</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">Functions of PGRC:</h3>
                                                <ul className="paddingLeft15px liGb MpaddingLeft1em">
                                                    <li>To Select/ Enrol research scholars for doctoral programme.</li>
                                                    <li>To Enrich research activities through organized research & developmental activities like Executive Education &
                                                        Management Development Programs.</li>
                                                    <li>To engage with and encourage research scholars to take up quality research in various areas through building a
                                                        research-oriented culture and by providing them with Training inputs Forums for collaboration on research,
                                                        conducting research workshops and sharing of research insights.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {Process &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">Pune University Ph.D Selection Process </h3>
                                                {/*<h3 className="blockTitle">Admission Process:</h3>*/}
                                                <p>The admission process is purely merit-based via PET examination score conducted by the SPPU.</p>
                                                <p>The process is ahead</p>
                                                <ul className="paddingLeft15px liGb MpaddingLeft1em">
                                                    <li>Entrance exam- A qualifying, MCQ based exam of total 100 marks.</li>
                                                    <li>Personal interview- Interview of candidates qualified in entrance test/ exempted from entrance examination, is
                                                        conducted as per the procedure prescribed in due courses.</li>
                                                    <li>The candidate fulfilling at least one of the following conditions will be exempted from the entrance examination-
                                                        <ul>
                                                            <li className="marginBottomZero">A candidate qualified in UGC-NET or teacher fellowship holder.</li>
                                                            <li>M Phil pass out from SPPU.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">How to Apply?</h3>
                                                <ul className="paddingLeft15px liGb MpaddingLeft1em">
                                                    <li>Visit the official university website portal<br />
                                                        <a href="http://bcud.unipune.ac.in/phd_entrance/applicant/login.aspx" target="_blank">(http://bcud.unipune.ac.in/phd_entrance/applicant/login.aspx)</a>
                                                    </li>
                                                    <li>Click on the link of the application form.</li>
                                                    <li>Fee payment via online mode or net banking. The candidate is selected for admission based on the PET entrance examination score</li>
                                                </ul>
                                                <h3 className="blockTitle">Note:</h3>
                                                <p>Before filling the online application form, the applicant must check the vacant positions for application displayed
                                                    on the university website.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {Documents &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">Documents Required:</h3>
                                                <p>Documents required during online application form filling process.</p>
                                                <ul className="paddingLeft15px liGb MpaddingLeft1em">
                                                    <li>Marks statement of the master’s degree.</li>
                                                    <li>Caste certificate (if from reserved category).</li>
                                                    <li>Non- creamy layer certificate (if from NCL category).</li>
                                                </ul>
                                                <h3 className="blockTitle">Note:</h3>
                                                <p>SPPU notifies well in advance on its website and via advertisement in two national newspapers (about
                                                    examination center for the entrance test, no. of seats, subject/discipline-wise distribution of available seats, criteria, and procedure for admission, etc).</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">Course Description:</h3>
                                                <p>For admission to the Ph.D. programme in a related subject in the faculty, applicants fulfilling any of the following</p>
                                                <ul className="paddingLeft15px liGb MpaddingLeft1em">
                                                    <li>Persons having passed Post Graduate Degree (master’s degree) Examination with at least 50% marks or equivalent
                                                        Grade Point Average (GPA)</li>
                                                    <li>Persons having passed Post Graduate Degree (master’s degree) Examination in the Faculty of Law with at least 55%
                                                        marks.5 % relaxation in the required percentage of marks will be given to the students of State of Maharashtra
                                                        belonging to SC/ST/DT/NT/SBC/O BC categories.</li>
                                                    <li>Persons working in National Laboratories/institutes/Government/Private Organization nominated/sponsored by the
                                                        respective employer. These persons should have a Post Graduate Degree and holding a rank of Assistant Director or</li>
                                                </ul>
                                                <h3 className="blockTitle">Note:</h3>
                                                <p>Above mentioned rules will also be applicable to the foreign candidates who have obtained a master’s Degree
                                                    from any of the recognized Indian Universities.</p>
                                                <p>The admission process is based on the criteria notified by the SPPU, keeping in view the norms issued by the
                                                    statutory bodies concerned, and the reservation policy of the Central/State Govt.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {Contacts &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">Contacts</h3>
                                                <p><b>PGRC Chairman:</b></p>
                                                <p>Dr. Komal Singh (Director ISBS)</p>
                                                <p><b>PGRC Head:</b></p>
                                                <p>Dr. Hetal Bhinde</p>
                                                <p><b>Email Id:</b> <a href="mailto:pgrc@indiraisbs.ac.in" style={{color:"#000"}}>pgrc@indiraisbs.ac.in</a></p>
                                                <p><b>Phone:</b><a href="tel:020-66759424" style={{color:"#000"}}> + 020-66759424</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {Update &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 marginTop2em">
                                            <div className="boxShadow paddingclass">
                                                <h3 className="blockTitle">Latest Update:</h3>
                                                <table className="table table-bordered" responsive>
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Sr. No.</b></td>
                                                            <td><b>Name of the Notice</b></td>
                                                            <td><b> URL</b></td>
                                                        </tr>

                                                        <tr>
                                                            <td>1</td>
                                                            <td>Interview Schedule for PhD Admission 2023</td>
                                                            <td><a href={phdnotice1} target="_blank">View</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Notice_Ph.D. Interview Round II</td>
                                                            <td><a href="https://indiraisbsmba.edu.in/assets/images/pdf/Notice_Ph.D.-Interview-round-II.pdf" target="_blank">View</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td> Ph.D Result 2021-22 : Human Resource Management</td>
                                                            <td><a href="https://indiraisbsmba.edu.in/assets/images/pdf/ApprovalToSelectedCandidates_Human Resource Management.pdf" target="_blank">View</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td>4</td>
                                                            <td>Ph.D Result 2021-22 : Financial Management</td>
                                                            <td><a href="https://indiraisbsmba.edu.in/assets/images/pdf/ApprovalToSelectedCandidates_Financial Management.pdf" target="_blank">View</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td>5</td>
                                                            <td>Ph.D Result 2021-22 : Marketing Management</td>
                                                            <td><a href="https://indiraisbsmba.edu.in/assets/ApprovalToSelectedCandidates_MarketingManagement.pdf" target="_blank">View</a></td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Phd
import React, { Fragment,useEffect } from "react";
import "./style.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Header from "../../components/Header";
import Icon_1 from "../../assets/images/landingPageImages/Icon1.png";
import Icon_2 from "../../assets/images/landingPageImages/Icon2.png";
import Icon_3 from "../../assets/images/landingPageImages/Icon3.png";
import Icon_4 from "../../assets/images/landingPageImages/Icon4.png";
import awardTitle from "../../assets/images/landingPageImages/awardTitle.png";
import whyC1 from "../../assets/images/landingPageImages/whyC1.svg";
import whyC2 from "../../assets/images/landingPageImages/whyC2.svg";
import whyC3 from "../../assets/images/landingPageImages/whyC3.svg";
import whyC4 from "../../assets/images/landingPageImages/whyC4.svg";
import whyC5 from "../../assets/images/landingPageImages/whyC5.svg";
import whyC6 from "../../assets/images/landingPageImages/whyC6.svg";
import studentbg1 from "../../assets/images/landingPageImages/studentbg1.png";
import orangetringle from "../../assets/images/landingPageImages/orangetringle.png";


const SupernumeraryMba = () => {
   useEffect(() => {
      const script = document.createElement('script');
    
      script.src = "https://widgets.in8.nopaperforms.com/emwgts.js";
      script.async = true;
    
      document.body.appendChild(script)

      
    
    }, []);
   return (
      <Fragment>
         <Header></Header>
         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddingForSection BgBanner">
            <div className="container ">
               <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 Suppernumary">

                     <div className="clearfix"></div>
                     <h1>MBA FOR WORKING PROFESSIONALS</h1>
                     <h2>2 Years | Hybrid | Minimum 1+ Years of Experience | Only 30 Seats</h2>
                     <h2 className="BgBannerh2 mhide" style={{ color: "#ffffff" }}>Autonomous Institute affiliated to Savitribai Phule Pune University</h2>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mhide">
                  <div className="npf_wgts" data-height="520px" data-w="ad6d9c5fe9fce98f41e97e97434181b3"></div>
                  </div>
               </div>
            </div>


         </div>


         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mshow">
            <div className="container ">
               <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 sppudiv">
                     <h2 className="BgBannerh2" style={{ color: "#ffffff" }}>Autonomous Institute affiliated to Savitribai Phule Pune University</h2>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                     <div className="npf_wgts" data-height="520px" data-w="ad6d9c5fe9fce98f41e97e97434181b3"></div>
                     {/* <script type="text/javascript">var s=document.createElement("script"); s.type="text/javascript"; s.async=true; s.src="https://widgets.in8.nopaperforms.com/emwgts.js"; document.body.appendChild(s);</script> */}
                  </div>

               </div>
            </div>
         </div>

         {/* Hide on mobile   */}
         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddingForSection SpecialisationsBg mhide">
            <div className="container-fluid">
               <div className="row">
                  <h2>Specialisations Offered</h2>
                  <div className="row">

                     <div className="col-lg-1 col-md-1 col-sm-1 col-xs-6">

                     </div>
                     <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 SpecialisationsBoxInner text-center">
                        <img src={Icon_1} alt="" />
                        <p>Marketing</p>
                     </div>
                     <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 SpecialisationsBoxInner">
                        <img src={Icon_2} alt="" />
                        <p>Human Resource</p>
                     </div>
                     <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 SpecialisationsBoxInner ">
                        <img src={Icon_3} alt="" />
                        <p>Finance</p>
                     </div>
                     <div className=" col-lg-2 col-md-2 col-sm-2 col-xs-6 SpecialisationsBoxInner ">
                        <img src={Icon_4} alt="" />
                        <p>Operations and Supply Chain Management</p>
                     </div>


                     <div className="col-lg-1 col-md-1 col-sm-1 col-xs-6">

                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* Hide on desktop (and larger tablets)  */}


         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddingForSection SpecialisationsBg mshow">
            <div className="container-fluid">
               <div className="row">
                  <h2>Specialisations Offered</h2>
                  <div className="">



                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 SpecialisationsBoxInner">
                     <img src={Icon_1} alt="" />
                     <p>Marketing</p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 SpecialisationsBoxInner">
                     <img src={Icon_2} alt="" />
                     <p>Human Resource</p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 SpecialisationsBoxInner ">
                     <img src={Icon_3} alt="" />
                     <p>Finance</p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 SpecialisationsBoxInner ">
                     <img src={Icon_4} alt="" />
                     <p>Operations and Supply Chain Management</p>
                  </div>

               </div>
            </div>
         </div>




         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marginminus ">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tabBox ">

                     <Tabs defaultActiveKey="overview" className="tabBoxButton">
                        <Tab eventKey="overview" title="Overview">
                           <div className="TabcantentBox">

                              <h3>Indira Group of Institutes (IGI)</h3>
                              <p>With a legacy spanning three decades, Indira Group of Institutes (IGI) is a leader in educational excellence. IGI is dedicated to nurturing talent and fostering innovation, consistently delivering quality education across diverse disciplines.</p>
                              <h3>Management Programs for Working Professionals</h3>
                              <p>IGI now offers flagship management programs designed for working professionals. These pro-grams allow professionals to enrol in degree courses through flexible scheduling and tailored cur-ricula without taking a career break. Upon completing the two-year program, students will re-ceive a regular MBA Degree.</p>
                              <h3>Supernumerary Seats</h3>
                              <p>The intake for working professionals will be through Supernumerary Seats, which are additional seats beyond the regular capacity. Eligible candidates must have a minimum of one year of experience in a registered industry, organization, or company.
                              </p>
                           </div>
                        </Tab>
                        <Tab eventKey="ProgramHighligh" title="Program Highlight">
                           <div className="TabcantentBox lifontcertificate">
                              <ul>
                                 {/* <li>Indira Institute of Management-IIMP is the Flagship Institute of Shree Chanakya Education Society's-SCES's Indira Group of Institutes affiliated to Savitribai Phule Pune University. IIMP is now an Autonomous Institute. </li>

                        <li>IIMP focuses on Value-Based Education due to its diversity, profound cultural base, collective thinking, and possession of self-respect with the help of dedicated, passionate, and competent Faculty members and Staff </li>

                        <li>IIMP MBA Program is intensive and rigorous that prepares individuals for senior management and leadership roles in today's Business World.  </li>

                        <li>MBA Specializations offered by IIMP enable students to concentrate on the multi-disciplinary aspect of Business Management that includes Marketing, Finance, Human Resources, Operations & Supply Chain Management, Business Analytics. </li>

                        <li>IIMP is the first business management institute in Pune and third in Maharashtra for its MBA Program accredited four times by the National Board of Accreditation-NBA.  </li>

                        <li>IIMP's dedication to excellence extends to the facilities, infrastructure, library, and classrooms equipped with advanced educational technology.  </li>

                        <li>In its pursuit of excellence, IIMP focuses on every aspect of development, thereby enhancing the quality of individuals even with the help of associated Alumni base. </li>
                           */}
                                 <li>	Quality is the hallmark of our teaching learning process at ISBS. </li>
                                 <li>The Institute has been accredited twice by the National Board of Accreditation (NBA), New Delhi. </li>
                                 <li>Impeccable placement record makes ISBS the most sought-after MBA college under Pu-ne University.</li>
                                 <li>We offer practical and application-oriented curriculum that meet the industry require-ments.</li>
                                 <li>We foster analytical and critical thinking abilities.</li>
                                 <li>We forge industry and academic interface for our students through various guest lectures, industry driven events, certifications, workshops, and Alumni led sessions.</li>
                                 <li>ISBS-MBA program has been conferred Autonomous status by UGC and SPPU</li>


                              </ul>


                              <h2>Specializations Offered: </h2>


                              <div className="row">
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                    <div className="bgCourseofferd">
                                       <h4>Marketing Management</h4>
                                    </div>
                                 </div>
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  ">
                                    <div className="bgCourseofferd">
                                       <h4>Financial Management </h4>
                                    </div>
                                 </div>
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  ">
                                    <div className="bgCourseofferd">
                                       <h4>Human Resource Management </h4>
                                    </div>
                                 </div>
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  ">
                                    <div className="bgCourseofferd">
                                       <h4>Operations and Supply Chain Management </h4>
                                    </div>
                                 </div>
                                 {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  ">
                           <div className="bgCourseofferd">
                              <h4>Business Analytics </h4>
                           </div>
                        </div> */}
                              </div>


                              <br />< br />



                           </div>
                        </Tab>
                        <Tab eventKey="LearningOpportunities" title="Learning Opportunities at IGI ">
                           <div className="TabcantentBox">
                              <h2 className="h2text"><span>  <img src={awardTitle} alt="" /></span>Specialization based Certifications</h2>
                              <div className="row">
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  lifontcertificate">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ZeroPadding topmargin">
                                       <div className="arrowboxText1">
                                          <p ><b>Marketing </b></p>
                                       </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <ul>
                                       <li>Selling Skills Workshop </li>
                                       <li> IMC Workshop </li>
                                       <li>Digital Marketing Workshop </li>
                                       <li> CRM Workshop </li>
                                       <li> AI in Marketing Workshop </li>
                                       <li> Workshop on Marketing in Real World </li>
                                       <li> Six Sigma workshop </li>
                                       <li> Data Analytics Workshop using Power BI </li>
                                       <li> Advanced Excel Workshop </li>
                                       <li> Finishing School Workshop </li>
                                       <li> Business Analytics workshop </li>
                                    </ul>
                                 </div>
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  lifontcertificate">
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 ZeroPadding topmargin">
                                       <div className="arrowboxText1">
                                          <p ><b>Human Resource Management  </b></p>
                                       </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <ul>
                                       <li>ndian Payroll Management </li>
                                       <li> Performance Management System </li>
                                       <li> HR Analytics </li>
                                       <li> Simulation Workshop on 'Assessment Centre'</li>
                                       <li> Talent Acquisition </li>
                                       <li> Role of HR in CSR </li>
                                       <li> HR Scorecard </li>
                                       <li> HR Metrics </li>
                                       <li> Finishing School Workshop </li>
                                       <li> Business Analytics workshop Advanced </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="row">
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  lifontcertificate">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ZeroPadding topmargin">
                                       <div className="arrowboxText1">
                                          <p ><b>Finance  </b></p>
                                       </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <ul>
                                       <li>orkshop on Financial Modelling </li>
                                       <li> Workshop on Technical Analysis </li>
                                       <li> Workshop on Financial Statement Analysis </li>
                                       <li> Workshop on Emerging Trends in Finance </li>
                                       <li> Workshop on Merger & Acquisition </li>
                                       <li> Workshop on US Taxation </li>
                                       <li> Workshop on Versant Training </li>
                                       <li> Workshop on Advanced Excel </li>
                                       <li> Finance Gym Certification </li>
                                       <li> Finishing School Workshop </li>
                                       <li> Business Analytics workshop  </li>
                                    </ul>
                                 </div>
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  lifontcertificate marginBottom">
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 ZeroPadding topmargin">
                                       <div className="arrowboxText1">
                                          <p ><b>Skill Development Program  </b></p>
                                       </div>
                                    </div>
                                    <ul>
                                       <li>Vocabulary building modules  </li>
                                       <li>Soft Skills Program  </li>
                                       <li>Goals setting and career planning </li>
                                       <li>Positive Thinking and Motivations </li>
                                       <li>Time Management </li>
                                       <li>Inter-personal Skills </li>
                                       <li>Business Etiquettes </li>
                                       <li>Personality Enrichment </li>
                                       <li>Art of Self- Management </li>
                                       <li>Team Building </li>
                                       <li>Personality Make-over </li>
                                       <li>Effective Communication Skills </li>
                                       <li>Presentation Skills </li>
                                       <li>Effective Body Language </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </Tab>
                     </Tabs>




                  </div>
               </div>
            </div>
         </div>
         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddingForSection WhyChooseUsbg">
            <div className="container">
               <div className="row">
                  <h2>Why choose us?</h2>
                  <br /><br />
                  <div className="border-bottom"></div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionThings">
                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 imageAfter" >
                           <img src={whyC1} alt="" />
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 WhyChooseUsbox">
                           <h2>Flexible Learning Options</h2>
                           <p>Choose from flexible learning formats, including weekend workshops, online courses,
                              and customized training programs, to fit your busy schedule and learning preferences.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionThings">
                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 imageAfter" >
                           <img src={whyC2} alt="" />
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 WhyChooseUsbox">
                           <h2>Comprehensive Curriculum</h2>
                           <p>Our program offers a diverse range of Subjects covering key areas such as leadership
                              development, strategic management, innovation, digital transformation, and more. .
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionThings">
                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 imageAfter" >
                           <img src={whyC3} alt="" />
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 WhyChooseUsbox">
                           <h2>Industry-Relevant Learning</h2>
                           <p>Management programs at IGI prioritize industry relevance, with a focus on practical skills and real-world applications.  </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionThings">
                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 imageAfter" >
                           <img src={whyC4} alt="" />
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 WhyChooseUsbox">
                           <h2>Renowned Faculty</h2>
                           <p>Learn from world-class faculty members, industry experts and thought leaders who bring real-world insights and cutting-edge research into the classroom. .</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionThings">
                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 imageAfter" >
                           <img src={whyC5} alt="" />
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 WhyChooseUsbox">
                           <h2>Networking Opportunities</h2>
                           <p>Reconnect with fellow alumni, expand your professional network, and collaborate with industry leaders through exclusive networking events, seminars, and workshops.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionThings">
                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 imageAfter" >
                           <img src={whyC6} alt="" />
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 WhyChooseUsbox">
                           <h2>Certification</h2>
                           <p>Earn the regular MBA degree from Savitribai Phule Pune University after completion of program. </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  admissionWorking ZeroPadding">
            <img src={studentbg1} alt="" />
            <img src={orangetringle} alt="" className="tringleImge" />
            <img src={orangetringle} alt="" className="tringleImge1" />
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
               <div className="container-fluid">
                  <div className="row">
                     <h1>Admissions for Working Professionals <br /><span>Supernumerary Seats</span></h1>
                     <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tabBox  tabBox2">
                        <Tabs defaultActiveKey="Eligibilty" className="tabBoxButton">
                           <Tab eventKey="Eligibilty" title="Eligibilty">
                              <div className="TabcantentBox">
                                 <h5>·	Professionals working in Registered Industry / Organization (Central / State) / Private/Public Limited Company/ MSMEs located within 50 Km radial distance from the Institute.
                                 </h5>
                                 <div className="border-bottom"></div>
                                 <h5>Minimum of ONE Year Full time / Regular working Experience </h5>
                                 <div className="border-bottom"></div>
                                 <h5>Qualification as Specified in Approval Process Handbook published by AICTE from time to time.</h5>
                              </div>
                           </Tab>
                           <Tab eventKey="Intake" title="Intake">
                              <div className="TabcantentBox">
                                 <h2>Intake</h2>
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  tableDiv">
                                    <br />

                                    <table className="table table-responsive  table-bordered" responsive>
                                       <tbody>
                                          <tr>
                                             <td> Sr. No.  </td>
                                             <td>Name of Institute  </td>
                                             <td>Name Of Program </td>
                                             <td>Intake</td>
                                          </tr>

                                          <tr>
                                             <td>01 </td>
                                             <td>ISBS  </td>
                                             <td>SUPERNUMERARY MBA</td>
                                             <td>30</td>

                                          </tr>
                                       </tbody>
                                    </table>

                                 </div>
                                 <br />
                                 <div className="clearfix"></div>
                              </div>
                           </Tab>
                           <Tab eventKey="AdmissionProcess" title="Admission Process">
                              <div className="TabcantentBox">
                                 <h5>The Indira School of Business Studies (ISBS) an autonomous institution affiliated to Savitribai Phule Pune University offers a MBA program. Admissions to the MBA program are done as per the rules & regulations framed by the Directorate of Technical Education, (DTE), Maharashtra State. </h5>
                                 <div className="border-bottom"></div>
                                 <h5>The details of various dates (schedules) are published by CET Cell, DTE in their information bro-chure and are available on their website. Interested candidates must regularly visit the web-site:<a href="https://cetcell.mahacet.org" > https://cetcell.mahacet.org</a> for eligibility, procedure & participation in the Common Admis-sion Process also referred to as CAP. </h5>
                                 <div className="border-bottom"></div>
                                 <h5>Candidates are advised to do their registration for the Admission Process with CET Cell, get their documents verified at designated Facilitation Centres (FC) allotted by CET Cell. After the examination process and verification of documents, CET Cell will prepare the final merit list. Once the final merit list is published by CET Cell then the aspiring candidates need to fill in the preference form for admitting to their preferred institute for MBA program. The CET will pub-lish the allotment list for admission considering the preferences filled by the candidate and the candidate's merit. On allocation of the institute to the candidate he/ she needs to report to the institute in the prescribed time frame and complete the procedure of admission at the institute.</h5>
                                 <div className="border-bottom"></div>
                                 <h5>The Indira School of Business Studies DTE Code is 0646010110 & the Choice Code for Admis-sion is 0646010110. For specific queries one should personally visit ISBS Admission Cell on the campus.</h5>
                                 <div className="border-bottom"></div>
                                 <div className="clearfix"></div>
                                 {/* <h2 style={{
                                    textAlign: "left",
                                    marginTop: "2%"
                                 }}>Guidelines For Working Professionals </h2>
                                 <div className="clearfix"></div>
                                 <h5>Total course duration NOT less than the duration prescribed by AICTE and shall fulfil the total contact hours as prescribed AICTE for completion of duration of the course. </h5>
                                 <div className="border-bottom"></div>
                                 <h5>Timings for conduct of classes normally shall be in the evening hours/any flexible convenient timings in alignment with timings of Industry / Organization. </h5>
                                 <div className="border-bottom"></div>
                                 <h5>Mode of Conduct of Classes shall be as applicable for Regular Programs as defined in the AICTE Public Notice Vide. No. AICTE/AB/Academic/2020-21, dated 13th August, 2020(In Regular Face to Face Mode). </h5>
                               */}
                               </div>
                           </Tab>
                           <Tab eventKey="feeStructure" title="Fee Structure">
                              <div className="TabcantentBox">
                                 <h2>Fees Structure with break up[AY 2024-2025]</h2>
                                 {/* <h3>1st Year Fees [2024-25]<br/> Annual Fees PGDM E-Business Management for Supernumerary Seats </h3> */}
                                 <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12  tableDiv">
                                    <br />

                                    <table className="table table-responsive  table-bordered" responsive>
                                       <tbody>
                                          <tr><td>Sr. No.</td><td>Category</td><td>1st Year Fees [2024-25] Annual Fees</td></tr>
                                          <tr><td>1</td><td>Tuition Fees</td><td>221739</td></tr>
                                          <tr><td>2</td><td>Development Fees</td><td>33261</td></tr>
                                          <tr><td>3</td><td>Total </td><td>255000</td></tr>
                                       </tbody>
                                    </table>

                                 </div>
                                 <br />
                                 <div className="clearfix"></div>
                                 <p>*Note:AICTE Policy applies to cancellations and refunds.</p>
                              </div>
                           </Tab>
                        </Tabs>
                     </div>

                  </div>
               </div>
            </div>
         </div>
         <div className="clearfix"></div>
      </Fragment>

   );
};
export default SupernumeraryMba;

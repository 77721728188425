import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/student-life/SPORTS ACTIVITY.png"
import Img1Image from "../../assets/images/about/sport1.jpg"
import DressImage from "../../assets/images/student-life/about.png"
import About2Image from "../../assets/images/student-life/about2.png"
import About3Image from "../../assets/images/student-life/about3.png"
import About4Image from "../../assets/images/student-life/about4.png"
import About5Image from "../../assets/images/student-life/about5.png"
import About6Image from "../../assets/images/student-life/about6.png"
import OMTImage from "../../assets/images/student-life/OMT.png"
import S1Image from "../../assets/images/student-life/s1.png"
import S2Image from "../../assets/images/student-life/s2.png"
import S3Image from "../../assets/images/student-life/s3.png"
import S4Image from "../../assets/images/student-life/s4.png"
import S5Image from "../../assets/images/student-life/s5.png"
import S6Image from "../../assets/images/student-life/s6.png"
import S7Image from "../../assets/images/student-life/s7.png"
import S8Image from "../../assets/images/student-life/s8.png"
import S9Image from "../../assets/images/student-life/s9.png"
import S10Image from "../../assets/images/student-life/s10.png"
import S11Image from "../../assets/images/student-life/s11.png"

const SportsActivity = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout leftAlignBannerTxt">Sports Activity</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Student Life &#62; <span> Sports Activity</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={DressImage} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={DressImage} alt="" className="circleAboutIconMob1 mobShow" />
                            {/* <h4 className="paddingTop"></h4> */}
                            <p className="paddingTop">ISBS believes that physical fitness is essential for mental well - being of students. Therefore students are provided with
                                platforms to pursue their sporting passion and to make them better team players. Sports Committee constituted for this
                                purpose organizes various sports events which is a combination of outdoor and indoor games. The committee also
                                organises regular fitness regime for students encompassing work out at the campus gymnasium, yoga sessions and
                                besides encourages students to participate in various intercollege competitions organized by different B-Schools. ISBS has
                                been a winner of the overall trophy of intercollegiate events organised by Savitribai Phule Pune University (SPPU) for
                                several years.
                            </p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={About2Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={About2Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Thus initiatives for sports aim at:</h4>
                            <ul className="liGb">
                                <li>Providing an environment for physical development of the students.</li>
                                <li>Providing opportunity to the student to showcase their talent in sports.</li>
                                <li>Promote sportsmanship among students by organizing various sporting activities.</li>
                                <li>Aid in holistic well-being of the student</li>
                                <li>Providing guidance and mentoring to students and motivate them to participate in inter and intra institute competitions.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={About3Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={About3Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Following are the details of available facilities for students:</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="col-md-12 liGb1">
                                        <p><b>Indoor Sports Facilities</b></p>
                                        <ul className="reg1">
                                            <li>Gymnasium</li>
                                            <li>Table-tennis Tables</li>
                                            <li>Carrom Board</li>
                                            <li>Chess Board</li>
                                            <li>Zumba Coaching</li>
                                            <li>Yoga Classes</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-12 liGb1">
                                        <p><b>Outdoor Sports Facilities</b></p>
                                        <ul className="reg1">
                                            <li>Basketball Court</li>
                                            <li>Volleyball Court</li>
                                            <li>Badminton</li>
                                            <li>Through Ball / Box Cricket /Cricket</li>
                                            <li>Indira National School Ground shared for sports
                                                such as cricket and outdoor management training
                                                programs.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={About4Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={About4Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">INVICTUS</h4>
                            <p>“Sports do not built character. They reveal it.” - Heywood Broun</p>
                            <p>Sports have always been not just the key of fitness and good health but also a capsule which encapsulates mental piece,
                                relaxation and also leadership and team work. Sports are also a great source of recreation; this is perhaps the only
                                recreation which has no evil effect. Sports have a humanizing effect and make us better men. Sports are catalyst that
                                promote our health and help in all-round development of personality. The biggest word that has come out with sports is
                                sportsmanship. It is an attitude that strives for fair play, courtesy toward teammates, and opponents, ethical behavior and
                                integrity, and grace in victory or defeat.</p>
                            <p>“Winning is not everything but making the effort to Win is” - Vince Lombardi</p>
                            <p>Indira School of Business Studies, ISBS, top management college of Pune, not only focuses on studies but on all-round
                                growth of student. And INVICTUS Sports Cell of ISBS helps vitally in achieving this goal. ISBS proudly holds the honor of
                                being a sporting college with great academics as well.</p>
                            <p>Headed by Dr. Kalpna Deshmukh and with support of Junior and Senior cell members, Invictus organizes various sporting
                                event for students some of their flagships are: OMT, Sportacus, Ranbhoomi etc. Invictus not only focuses on internal events
                                but also on external competitions as well, some of them are: PUMBA organized by Pune University, KARMANTA organized
                                by IMERT, etc.</p>
                            <img src={Img1Image} alt="" className="imgRes" />
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={About5Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={About5Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">OMT (Outdoor management Training)</h4>
                            <p>Management is never taught but learnt from the experiences and one such experience is OMT i.e., Outdoor Management Training.</p>
                            <p>AICTE approved Indira School of Business Studies top PGDM College in Pune, organized OMT on 19th and 20th July, 2019
                                an entire two day’s program at Mass communication and INS campus for all the new students managed by Trainer and
                                inputs were given by Faculties.</p>
                            <p>ISBS being a premium b-school of Pune, ensures every possible means of giving learning to their students, and OMT is one
                                such Flagship event where learning is experiential.</p>
                            <img src={OMTImage} alt="" className="imgRes" />
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={About6Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={About6Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">SPORTACUS</h4>
                            <p>Outcome matters but what matters most is the efforts that an individual puts in to achieve it.</p>
                            <p>SPORTACUS another flagship event of INVICTUS was organized on 5th and 6th October 2020. This two day’s event
                                witnessed some great sporting performances by the participating students who went against different divisions to win the
                                prestigious “Best Division trophy”. This two day’s event witnessed epic battle of Basketball, Football, Box-cricket,
                                Volleyball, Tug of war, Throw-ball, where approximately 500 students across all division of Juniors and Seniors battled it
                                out. This year the event was full of high spirit of participation and enthusiasm. What drove students to win was their zeal
                                and willingness to win.
                            </p>
                            <div className="row">
                                <div className="col-md-3 marginBottom2em">
                                    <img src={S1Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-3 marginBottom2em">
                                    <img src={S2Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-3 marginBottom2em">
                                    <img src={S3Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-3 marginBottom2em">
                                    <img src={S4Image} alt="" className="imgRes" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 marginBottom2em">
                                    <img src={S5Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-6 marginBottom2em">
                                    <img src={S6Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-3 marginBottom2em">
                                    <img src={S7Image} alt="" className="imgRes" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <img src={S8Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-3">
                                    <img src={S9Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-3">
                                    <img src={S10Image} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-3 marginBottom2em">
                                    <img src={S11Image} alt="" className="imgRes" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SportsActivity
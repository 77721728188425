import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/student-life/STUDENT CLUBS.png"
import AboutImage from "../../assets/images/student-life/about.png"
import FinovateImage from "../../assets/images/student-life/Finovate.png"
import IngeniusImage from "../../assets/images/student-life/Ingenius.png"
import EnsembleImage from "../../assets/images/student-life/Ensemble.png"

const StudentClub = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout leftAlignBannerTxt whiteText">Student<br />Club</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Student Life &#62; <span> Student Clubs</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={AboutImage} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={AboutImage} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">About Us</h4>
                            <p className="paddingTop">The student clubs at Indira School of Business Studies (ISBS) can be considered as the network lifeline, there are 3 clubs at
                                ISBS. A student can be a part of any club based on the specialization that he opts in the second semester. These clubs help
                                the student build interpersonal skills, leadership qualities and help run the activities across the institute. The 3 student clubs
                                at ISBS are:
                            </p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={IngeniusImage} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={IngeniusImage} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“Ingenius”- Indira’s Genius!!!! (Marketing Club)</h4>
                            <p className="paddingTop">ISBS believes in nurturing “creative instincts” of its students so that their talent gets an apt platform to flourish!! Ingenius- the Marketing Club conducts myriad of events such as Quiz, Brand Revival, Debate, Extempore, Logo Designing and Ad Making etc. to get the best out of budding professionals!! ISBS Marketing aspirants participate in various marketing oriented activities with vigor and prove their mettle.
                            </p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={FinovateImage} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={FinovateImage} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“Finovate” (Finance Club)</h4>
                            <p className="paddingTop">‘FINOVATE’ – The Finance Club is a student driven initiative to provide a platform for students to acquire practical financial education with job-specific knowledge. This year we have organized activities and workshops such as Debate Competition, Skit Competition, Finance Gym, Finance Modelling, Budget Decoded etc. to ensure that students are abreast with all the current trends in finance.
                            </p>
                        </div>
                    </div>
                    
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={EnsembleImage} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={EnsembleImage} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“Ensemble” (HR Club)</h4>
                            <p className="paddingTop"><b>The Philosophy:</b> The HR club is an activity run mainly for HR specialization students. It is aimed to enhance the knowledge and perspectives of HR students other than their routine studies and classes. It includes discussions, case studies and domain tests regarding marketing concepts and developments, which will provide them with more practical and industrial concepts and development, which will provide them more practical and industrial exposure to HR-oriented firms. This is an effort put to sharpen the HR skills of students in a more comprehensive manner so that they turn out to be the best HR specialists.</p>
                            <p><b>Objectives:</b> To enhance the knowledge and perspectives of students in the subject of HR using distinctive modes of learning.</p>
                            <p><b>Modus operandi:</b> Faculty conducting HR specialization topics other that the subjects, field research assignments, writing and discussion of research papers, interaction with industrial experts, Presentation on the current trends etc.</p>
                            <p><b>Key deliverables:</b></p>
                            <p>Enhanced practical and industrial exposure to HR oriental firms and marketing subjects overall.</p>
                            <p>Sharpen skills of all HR club members in HR in a more comprehensive manner so that they turn out to be the best core HR specialists.</p>
                            <p>Finally, building good prospects for placement.</p>
                            <p><b>Performance Measures or evaluation criteria:</b> This would be measured in terms of the number of students attending these sessions, feedback from students as well as the Domain test scores at the end of the semester.</p>
                            {/*<p className="paddingTop">Ensemble Club is driven by HR students. It emphasizes on the changing roles of HR and makes students understand the insights on current trends, career paths, and internship experiences and guidance for laying the foundation of an Ensemble – The HR Club promising career in human resource management. The club also assists students in gaining membership to national HR societies such as NIPM, CII and MCCIA. HR Club indulges into activities such Scrapbook for HR, Workshops on HR Analytics and Book Reviews etc.
                            </p>*/}
                        </div>
                    </div>

                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={EnsembleImage} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={EnsembleImage} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">CSR - “PEHEL”</h4>
                            <p> CSR  “PEHEL” at ISBS aims to develop sensible, responsible, seasoned top
professionals. The students aim to use the committee as a venue
for creating awareness and learning of contemporary social and
environmental issues in the context of sustainability and corporate
social responsibility.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default StudentClub
import React, { Fragment } from "react"
import FooterLogo from "../assets/images/footer-logo.png"
import FacebookLogo from "../assets/images/fb.png"
import TwitterLogo from "../assets/images/twitter.png"
import InstaLogo from "../assets/images/insta.png"
import BlogLogo from "../assets/images/blog.png"
import LinkedIn from "../assets/images/linkdin.png"
import pdfFile from '../assets/pdf/FilledsurveyNIRF.pdf'
import nirfpdf from '../assets/pdf/Indira-School-of-Business-Studies-2024.pdf'
import MandatoryDispdf from '../assets/pdf/ISBS-Mandatory-Disclosure.pdf'
import pdfFile2 from '../assets/pdf/mandatorydisclosuremba2021.pdf'
import AICTEpdf from '../assets/pdf/AICTE-Approval-letter-2023-24.pdf'
import NBA from '../assets/pdf/NBA-Accreditation-ISBS.pdf'
import { NavLink } from "react-router-dom";
const Footer = () => {
    return (
        <Fragment>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mobTextCenter">
                            <img src={FooterLogo} className="footerLogo" />
               <p><b>Approved by AICTE | Accredited by NBA  | Permanently <br/> Affiliated to Savitribai Phule Pune University</b></p>
                            <p className="paddingSpace"><NavLink to="/infrastructure" style={{color:"#000"}}>
                                Infrastructure  </NavLink> | 
                             <a href={nirfpdf} target="_blank" style={{color:"#000"}} rel="noreferrer"> NIRF  </a>

                             {/*<NavLink to="/nba" style={{color:"#000"}}> NBA </NavLink>| */}
                             <a href={NBA} target="_blank"  style={{color:"#000"}} rel="noreferrer">|
                                NBA </a>
                            |
                              <a href="https://www.vidyalakshmi.co.in/Students/" target="_blank" style={{color:"#000"}} > Vidya Lakshmi Portal </a>

                              | &nbsp; 
                            <a href={MandatoryDispdf} target="_blank"  style={{color:"#000"}} rel="noreferrer"> 
                            ISBS Mandatory Disclosure</a>
                                &nbsp; | &nbsp;
                            <a href={AICTEpdf} target="_blank" style={{color:"#000"}} rel="noreferrer">AICTE Approval Letters</a>

                             {/* &nbsp; | &nbsp;  
                            <a href="https://shreechanakya-my.sharepoint.com/:v:/g/personal/info_indiraisbs_edu_in/EVrxHpBuy6xOvweN1RQA6x4BFusknwwakc_jNLbDqlIJwA?e=J9oTxI" target="_blank"  style={{color:"#000"}} rel="noreferrer"> 
                            ISBS AICTE Film</a>  */}

                            &nbsp; | &nbsp;  
                           
                            <NavLink to="/IQAC"><span style={{color:"#000"}}>IQAC 2022-23</span></NavLink>
                                </p>

                                
                            <p className="mobHide">@ 2022. Indira School of Business Studies, Pune</p>


                        </div>
                        <div className="col-md-6 mobTextCenter">
                            <ul className="socialMediaLogo">
                            <a href="https://www.facebook.com/isbs.indira" target="_blank" rel="noreferrer"><li><img src={FacebookLogo} /></li></a>
                                    <a href="https://www.youtube.com/c/indiracollege/videos" target="_blank" rel="noreferrer"><li><img src={TwitterLogo} /></li></a>
                                    <a href="https://www.linkedin.com/in/indira-school-of-business-studies-pune-7a827617b" target="_blank" rel="noreferrer"><li><img src={LinkedIn} /></li></a>
                                    <a href="https://blog.indiraedu.com/" target="_blank" rel="noreferrer"><li><img src={BlogLogo} /></li></a>
                                    <a href="https://www.instagram.com/indira_isbs" target="_blank" rel="noreferrer"><li><img src={InstaLogo} /></li></a>

                            </ul>
                            <p><b>Address:</b></p>
                            {/* <p>'Abhinavan',89/2A, New Pune-Mumbai Highway,Tathawade,
                                Pune - 411033, Maharashtra, India</p> */}
                                <p>Sr. No. 85/5B, 6D/1 and 6D/2, Unity Campus,<br/> New Pune Mumbai Highway, Tathawade,<br/> Pune 411033, Maharashtra</p>
                                {/* <p><b>Phone Number:</b>  MBA +91-20-66759500 / 415 / 404</p> */}
                                <p><b>Phone Number:</b> <a href="tel:+912067103900" style={{color:"#000"}}>020-67103900</a> / <a href="tel:+912067103981" style={{color:"#000"}}>67103981</a></p>
                                <p><b>Email: </b><a href="mailto:info@indiraisbs.edu.in" style={{color:"#000"}}>info@indiraisbs.edu.in</a></p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mobShow mobBottomFooter">
                        <p className="paddMgZero">@ 2022. Indira School of Business Studies, Pune</p>
                        </div>
        </Fragment>
    )
}
export default Footer;
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/home/Home';
import ShreeChanakyaEducationSociety from './pages/about/ShreeChanakyaEducationSociety';
import IndiraGroupOfInstitutes from './pages/indira-group-of-institutes/IndiraGroupOfInstitutes';
import IndiraSchoolOfBusinessStudies from './pages/indira-school-of-business-studies/IndiraSchoolOfBusinessStudies';
import Recognition from './pages/recognition/Recognition';
import Mba from './pages/mba/Mba';
import Phd from './pages/phd/Phd';
import AlumniAssociation from './pages/alumni-association/AlumniAssociation';
import AlumniCommittee from './pages/alumni-committee/AlumniCommittee';
import AlumniNetwork from './pages/alumni-network/AlumniNetwork';
import Contact from './pages/contact/Contact';
import LifeAtISBS from './pages/life-at-isbs/LifeAtISBS';
import ComplianceCommittee from './pages/compliance-committee/ComplianceCommittee';
import SportsActivity from './pages/sports-activity/SportsActivity';
import StudentClub from './pages/student-club/StudentClub';
import StudentEngagement from './pages/student-engagement/StudentEngagement';
import Footer from './components/Footer';
import PlacementCell from './pages/placement-cell/PlacementCell';
import PlacementAtISBS from './pages/placement-at-isbs/PlacementAtISBS';
import Recruiters from './pages/recruiters/Recruiters';
import PlacementReport from './pages/placement-report/PlacementReport';
import Mdp from './pages/mdp/Mdp';
import Editorial from './pages/editorial/Editorial';
import ConferenceProceedings from './pages/conference-proceedings/ConferenceProceedings';
import ResearchJournal from './pages/research-journal/ResearchJournal';
import Conference from './pages/conference/Conference';
import IndiraManagementReview from './pages/indira-management-review/IndiraManagementReview';
import ResearchFacultyDevelopment from './pages/research-faculty-development/ResearchFacultyDevelopment';
import StudentCommittee from './pages/StudentCommittee/StudentCommittee';
import Hostel from './pages/hostel/Hostel';
import IvyLeagueHouse from './pages/ivy-league-house/IvyLeagueHouse';
import NBA from './pages/nba/NBA';
import Infrastructure from './pages/infrastructure/Infrastructure';
import BrandSlam from './pages/brandSlam/BrandSlam';
import Iqac from './pages/IQAC/iqac';
import VideoGallery from './pages/video-gallery/VideoGallery';
import SupernumeraryMba from './pages/Supernumerary-mba/SupernumeraryMba';
function App() {
  return (
    <div className="App">
      <Switch>

        <Route path="/" exact>
          <Home></Home>
        </Route>

        <Route path="/shree-chanakya-education-society">
          <ShreeChanakyaEducationSociety></ShreeChanakyaEducationSociety>
        </Route>
        <Route path="/indira-group-of-institutes">
          <IndiraGroupOfInstitutes></IndiraGroupOfInstitutes>
        </Route>
        <Route path="/indira-school-of-business-studies">
          <IndiraSchoolOfBusinessStudies></IndiraSchoolOfBusinessStudies>
        </Route>
        <Route path="/recognition">
          <Recognition></Recognition>
        </Route>
        <Route path="/hostel">
          <Hostel></Hostel>
        </Route>
        <Route path="/ivy-league-house">
          <IvyLeagueHouse></IvyLeagueHouse>
        </Route>
        <Route path="/nba">
          <NBA></NBA>
        </Route>
        <Route path="/infrastructure">
          <Infrastructure></Infrastructure>
        </Route>

        
        <Route path="/mba">
          <Mba></Mba>
        </Route>
        
        <Route path="/phd">
          <Phd></Phd>
        </Route>

        <Route path="/alumni-association">
          <AlumniAssociation></AlumniAssociation>
        </Route>
        <Route path="/alumni-committee">
          <AlumniCommittee></AlumniCommittee>
        </Route>
        <Route path="/alumni-network">
          <AlumniNetwork></AlumniNetwork>
        </Route>

        <Route path="/placement-cell">
          <PlacementCell></PlacementCell>
        </Route>
        <Route path="/placement-at-isbs">
          <PlacementAtISBS></PlacementAtISBS>
        </Route>
        <Route path="/recruiters">
          <Recruiters></Recruiters>
        </Route>
        <Route path="/placement-report">
          <PlacementReport></PlacementReport>
        </Route>

        <Route path="/mdp-about">
          <Mdp mdp={'About'}></Mdp>
        </Route>
        <Route path="/mdp-programs">
          <Mdp mdp={'Programs'}></Mdp>
        </Route>
        <Route path="/mdp-client">
          <Mdp mdp={'client'}></Mdp>
        </Route>
        <Route path="/mdp-clients">
          <Mdp mdp={'Clients'}></Mdp>
        </Route>
        <Route path="/mdp-trainers">
          <Mdp mdp={'Trainers'}></Mdp>
        </Route>
        <Route path="/mdp-gallery">
          <Mdp mdp={'Gallery'}></Mdp>
        </Route>
        <Route path="/mdp-contacts">
          <Mdp mdp={'Contact'}></Mdp>
        </Route>

        <Route path="/contact">
          <Contact></Contact>
        </Route>

        <Route path="/life-at-isbs">
          <LifeAtISBS></LifeAtISBS>
        </Route>
        <Route path="/compliance-committee">
          <ComplianceCommittee></ComplianceCommittee>
        </Route>
        <Route path="/sports-activity">
          <SportsActivity></SportsActivity>
        </Route>
        <Route path="/student-club">
          <StudentClub></StudentClub>
        </Route>

        <Route path="/conference-proceedings">
          <ConferenceProceedings></ConferenceProceedings>
        </Route>
        <Route path="/editorial">
          <Editorial></Editorial>
        </Route>
        <Route path="/research-journal">
          <ResearchJournal></ResearchJournal>
        </Route>

        <Route path="/conference">
          <Conference></Conference>
        </Route>
        <Route path="/indira-management-review">
          <IndiraManagementReview></IndiraManagementReview>
        </Route>
        <Route path="/research-faculty-development">
          <ResearchFacultyDevelopment></ResearchFacultyDevelopment>
        </Route>

        <Route path="/student-committee">
          <StudentCommittee></StudentCommittee>
        </Route>
        <Route path="/student-engagement">
          <StudentEngagement></StudentEngagement>
        </Route>
        <Route path="/brandSlam">
          <BrandSlam></BrandSlam>
        </Route>

        <Route path="/IQAC">
          <Iqac></Iqac>
        </Route>

        <Route path="/video-gallery">
          <VideoGallery></VideoGallery>
        </Route>
        <Route path="/mba-for-working-professionals">
          <SupernumeraryMba></SupernumeraryMba>
        </Route>

      

      </Switch>
      <Footer></Footer>
    </div>
  );
}

export default App;

import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/student-life/STUDENT COMMITTEES.png"

import Rc1Image from "../../assets/images/student-life/rc1.jpg"
import sc1Image from "../../assets/images/student-life/sc1.png"
import sc2Image from "../../assets/images/student-life/sc2.png"
import sc3Image from "../../assets/images/student-life/sc3.png"
import sc4Image from "../../assets/images/student-life/sc4.png"
import sc5Image from "../../assets/images/student-life/sc5.png"
import Carousel from 'react-bootstrap/Carousel';
const StudentCommittee = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout leftAlignBannerTxt">Student Committees</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Student Life &#62; <span> Student Committees</span></h3>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 marginTop2em paddingBottom1em paddingTop1em">
                            <p>We at INDIRA strongly believe that “challenges can be converted into opportunities with hard work and an integrated approach.” All student
                                activities are conducted through total student involvement and participation. We strive to emulate the principles of teamwork and synergy, so as
                                to derive greater benefits. “Nexus” (Students Council) The Student’s Council is headed by a President and a Vice-President and acts as a guiding
                                system for the Institute’s success. The Student council looks after and guides the various committees at ISBS such as:</p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc1Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc1Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“Arohan” (E-Cell)</h4>
                            <p>E-cell has been constituted with the broad purpose of building an entrepreneurship oriented culture within the institute.
                                The objective is to encourage students to enhance their enterprising skills to benefit the external stakeholders (Industry
                                and Society at large). The cell achieves this by way of providing students with the space, time, training, support and
                                opportunities to engage with internal and external stakeholders. </p>
                            <p>The Cell aims to enhance the entrepreneurial quotient in the students. This cell is largely driven by students with the
                                involvement of faculty in charge. Arohan organizes several seminars on topics of current importance and relevance. They
                                interact with CEOs, Entrepreneurs, Consultants, Managers and Professionals from various spheres of business, who are
                                invited as Guest Speakers in order to throw light the subject matter, with a view to change mind sets and broaden
                                horizons.</p>
                           {/* <Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                </Carousel>*/}
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc2Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc2Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“Dor” (Alumni Cell)</h4>
                            <p>The Alumni committee works as a conduit between the alumni and the alma-mater, both at the corporate and personal
                                level. The alumni are our best emissaries in the corporate world and a vital link in the Industry-Institute relationship.</p>
                            <p>The Alumni Association plays an important role in helping to shape the future of our ISBS students by representing the
                                views of its members and contributing to building an engaged and supportive alumni community. Our alumni hold
                                prominent positions in various sectors and are always on the fore-front to mentor/counsel/guide our current students. This
                                is done in a structured pattern:</p>
                            <ul>
                                <li>General Interaction (Alumni tells the current students as to what to expect in their two years’ journey in ISBS)</li>
                                <li>Grooming sessions by alumnus and alumnae</li>
                                <li>Specialization selection counseling</li>
                                <li>Mock PI</li>
                                <li>SIP inputs</li>
                                <li>Entrepreneurship workshops</li>
                                <li>Preparatory activities</li>
                            </ul>
                            {/*<Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>*/}
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc3Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc3Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“Invictus” (Sports Cell)</h4>
                            <p>Students, who are under constant pressure to learn and excel, need space and time to relax and revitalize themselves.
                                Sports Committee focuses on physical and mental well – being of students and assists in rejuvenating students. It
                                provides students with platform to pursue their sporting passion and to make them better team players. The committee
                                organizes various sports events which is a combination of outdoor and indoor games. The committee also encourages
                                students to participate in various intercollege competitions organized by various B-Schools. </p>
                            <p>The committee is largely driven by students under the guidance of faculty in charge. The sports committee organizes OMT
                                tournaments such as cricket, basketball, volleyball, football, badminton, Yoga and fitness to enliven the sporting spirit and
                                enable students to feel rejuvenated.
                            </p>
                            {/*<Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                        </Carousel>*/}
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc4Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc4Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“Udaan” (Cultural Cell)</h4>
                            <p>To provide a platform to students to display their talent, creativity, imagination and aid in their holistic development, we have
                                Udaan – the cultural cell at ISBS. The cell organizes various events and competitions at institute viz independence day,
                                teacher’s day, theme days, simulation competition, management games etc. The committee also identifies various intercollege
                                competitions organized by various B- Schools and encourages student’s participation for such competitive platforms. The
                                committee is largely driven by students under the guidance of faculty in charge. Udaan adds colors to student’s life.
                            </p>
                            {/*<Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                    </Carousel>*/}
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc5Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc5Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">“PEHEL” (Corporate Social Responsibility CSR Committee)</h4>
                            <p>“PEHEL” at ISBS includes the participation of stake holders such as ISBS students and staff, non-government organizations
                                (NGO), and the local community. The students and faculty actively participate in outreach program initiatives where they
                                contribute their knowledge, skill and technical expertise to the community. ISBS has supported the program activities of
                                several NGO’s operating in the community. Apart from funding various activities for the community, ISBS also extends their
                                expertise in terms of knowledge and human resources for the success of NGO initiative and programs.
                            </p>
                            {/*<Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6">
                                        <img src={Rc1Image} alt="" className="imgRes mobHide" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                </Carousel>*/}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default StudentCommittee
import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/placement/recruiters.png"
import Icon1Image from "../../assets/images/placement/icon31.png"
import Icon2Image from "../../assets/images/placement/icon32.png"
import Icon3Image from "../../assets/images/placement/icon33.png"
import R1Image from "../../assets/images/placement/r1.png"
import R2Image from "../../assets/images/placement/r2.png"
import R3Image from "../../assets/images/placement/r3.png"
import R4Image from "../../assets/images/placement/r4.png"
import R5Image from "../../assets/images/placement/r5.png"
import R6Image from "../../assets/images/placement/r6.png"
import R7Image from "../../assets/images/placement/r7.png"
import R8Image from "../../assets/images/placement/r8.png"
import R9Image from "../../assets/images/placement/r9.png"
import R10Image from "../../assets/images/placement/r10.png"
import R11Image from "../../assets/images/placement/r11.png"
import R12Image from "../../assets/images/placement/r12.png"
import R13Image from "../../assets/images/placement/r13.png"
import R14Image from "../../assets/images/placement/r14.png"
import R15Image from "../../assets/images/placement/r15.png"
import R16Image from "../../assets/images/placement/r16.png"
import R17Image from "../../assets/images/placement/r17.png"
import R18Image from "../../assets/images/placement/r18.png"
import R19Image from "../../assets/images/placement/r19.png"
import R20Image from "../../assets/images/placement/r20.png"
import R21Image from "../../assets/images/placement/r21.png"
import R22Image from "../../assets/images/placement/r22.png"
import R23Image from "../../assets/images/placement/r23.png"
import R24Image from "../../assets/images/placement/r24.png"
import R25Image from "../../assets/images/placement/r25.png"
import R26Image from "../../assets/images/placement/r26.png"
import R27Image from "../../assets/images/placement/r27.png"
import R28Image from "../../assets/images/placement/r28.png"
import R29Image from "../../assets/images/placement/r29.png"
import R30Image from "../../assets/images/placement/r30.png"
import R31Image from "../../assets/images/placement/r31.png"
import R32Image from "../../assets/images/placement/r32.png"
import R33Image from "../../assets/images/placement/r33.png"
import R34Image from "../../assets/images/placement/r34.png"
import R35Image from "../../assets/images/placement/r35.png"
import R36Image from "../../assets/images/placement/r36.png"
import R37Image from "../../assets/images/placement/r37.png"
import R38Image from "../../assets/images/placement/r38.png"
import R39Image from "../../assets/images/placement/r39.png"
import R40Image from "../../assets/images/placement/r40.png"
import R41Image from "../../assets/images/placement/r41.png"
import R42Image from "../../assets/images/placement/r42.png"

const Recruiters = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout rightAlignBannerTxt whiteText">OUR RECRUITERS</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Placement &#62; <span> Our Recruiters</span></h3>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row marginBottom3em">
                    <div className="col-md-4 text-center">
                        <img src={Icon1Image} alt="" className="clickBoxIcon" />
                        <p>Every year more than 350+<br />
                            companies on campus.</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <img src={Icon2Image} alt="" className="clickBoxIcon" />
                        <p><b>Average Salary:</b> 5 lacs per year<br />
                            <b>Highest:</b> 13.8 lacs per year</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <img src={Icon3Image} alt="" className="clickBoxIcon" />
                        <p>Leading companies from all<br />
                            major sectors.</p>
                    </div>
                    <div className="col-md-12">
                        <h3 className="blockTitle">Some of Our Recruiters Are</h3>
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R1Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R2Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R3Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R4Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R5Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R6Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R7Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R8Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R9Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R10Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R11Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R12Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R13Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R14Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R15Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R16Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R17Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R18Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R19Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R20Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R21Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R22Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R23Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R24Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R25Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R26Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R27Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R28Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R29Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R30Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R31Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R32Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R33Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R34Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R35Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R36Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R37Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R38Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R39Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R40Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R41Image} alt="" className="imgRes" />
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={R42Image} alt="" className="imgRes" />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Recruiters
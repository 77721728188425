import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/about/compliance-comettee.png"
import Icon1Image from "../../assets/images/placement/icon21.png"
import Icon2Image from "../../assets/images/placement/icon22.png"
import Icon3Image from "../../assets/images/placement/icon23.png"
import DrugImage from "../../assets/images/student-life/drug.png"
import DressImage from "../../assets/images/student-life/dress.png"
import HostelImage from "../../assets/images/student-life/hostel.png"

import VisionImage from "../../assets/images/about/vision.png"
import { useState } from "react";
import Table from 'react-bootstrap/Table';

const ComplianceCommittee = () => {
    const [gravience, setGravience] = useState(true)
    const [Complaint, setComplaint] = useState(false)
    const [Ragging, setRagging] = useState(false)
    const [SCST, setSCST] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const gravienceHandler = () => {
        setGravience(true)
        setComplaint(false)
        setRagging(false)
        setSCST(false)
    }
    const ComplaintHandler = () => {
        setGravience(false)
        setComplaint(true)
        setRagging(false)
        setSCST(false)
    }
    const RaggingHandler = () => {
        setGravience(false)
        setComplaint(false)
        setRagging(true)
        setSCST(false)
    }
    const SCSTHandler = () => {
        setGravience(false)
        setComplaint(false)
        setRagging(false)
        setSCST(true)
    }
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout leftAlignBannerTxt whiteText">Compliance<br />Committee</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Student Life &#62; <span> Compliance Committee</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain paddingBottom3em">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 cursorPointer" onClick={gravienceHandler}>
                            <div className={gravience ? "colClickBox minHeight190" : "colClickBox"}>
                                <div className="clickIconBOx">
                                    <img src={Icon1Image} alt="" className="clickBoxIcon" />
                                </div>
                                <h3 className="blockTitle">Grievance<br />
                                    Committee 2023-24</h3>
                            </div>
                        </div>
                        <div className="col-md-3 cursorPointer" onClick={ComplaintHandler} >
                            <div className={Complaint ? "colClickBox minHeight190" : "colClickBox"}>
                                <div className="clickIconBOx">
                                    <img src={Icon2Image} alt="" className="clickBoxIcon" />
                                </div>
                                <h3 className="blockTitle">Internal Compliance Committee <br />
                                    2023-24</h3>
                            </div>
                        </div>
                        <div className="col-md-3 cursorPointer" onClick={RaggingHandler}>
                            <div className={Ragging ? "colClickBox minHeight190" : "colClickBox"}>
                                <div className="clickIconBOx">
                                    <img src={Icon3Image} alt="" className="clickBoxIcon" />
                                </div>
                                <h3 className="blockTitle text-center">Anti-Ragging<br />
                                    Committee 2023 - 24</h3>
                            </div>

                        </div>
                        <div className="col-md-3 cursorPointer" onClick={SCSTHandler}>
                            <div className={SCST ? "colClickBox minHeight190" : "colClickBox"}>
                                <div className="clickIconBOx">
                                    <img src={Icon3Image} alt="" className="clickBoxIcon" />
                                </div>
                                <h3 className="blockTitle">SC/ST<br />
                                    Committee 2023 - 24</h3>
                            </div>
                        </div>
                        {gravience &&
                            <div className="paddingLeft">
                                <div className="col-md-12 padding15px whiteBackground">
                                    <p className=" paddingTop1em">According to AlCTE's Establishment of Mechanism for Grievance Redressal, Regulation 2012, the Grievance Redressal Committee
                                        has been constituted with the objective of resolving the grievances of students, parents and others. All the aggrieved students, their
                                        parents and others may henceforth approach the Grievance Redressal Committee.</p>
                                    <p className="text-center"><b>Staff Grievance Committee 2023-24</b></p>
                                    <div className="col-md-12">
                                        <Table class="table table-responsive table-bordered" responsive>

                                            <tbody>
                                                <tr>
                                                    <td><strong>Sr. No.</strong></td>
                                                    <td><strong>Name of the Committee Members</strong></td>
                                                    <td><strong>Designation in Committee</strong></td>
                                                    <td><strong>Mobile No.</strong></td>
                                                    <td><strong>E-mail-ID</strong></td>
                                                </tr>
                                                <tr><td>1</td><td>Dr. Komal Singh</td><td>Chairperson</td><td>9624038181</td><td><a href="mailto:komal.singh@indiraisbs.ac.in">komal.singh@indiraisbs.ac.in </a></td></tr>
                                                <tr><td>2</td><td>Dr. Mrityunjay Kumar </td><td>Secretary</td><td>9987994617</td><td><a href="mailto:mrityunjay.kumar@indiraisbs.ac.in">mrityunjay.kumar@indiraisbs.ac.in</a></td></tr>
                                                <tr><td>3</td><td>Dr. Arvind Burande</td><td>Faculty Member </td><td>9922802674</td><td><a href="mailto:arvind.burande@indiraisbs.ac.in">arvind.burande@indiraisbs.ac.in</a></td></tr>
                                                <tr><td>4</td><td>Dr. Kalpana Deshmukh </td><td>Faculty Member </td><td>9764929158</td><td><a href="mailto:kalpana.deshmukh@indiraisbs.ac.in">kalpana.deshmukh@indiraisbs.ac.in</a></td></tr>
                                                <tr><td>5</td><td>Ms. Sharvari Ratnakar </td><td>Non-teaching staff member </td><td>9850898864</td><td><a href="mailto:sharvari.ratnakar@indiraisbs.ac.in">sharvari.ratnakar@indiraisbs.ac.in</a></td></tr>
                                                <tr><td>6</td><td>Mr. Rushikesh Pawade</td><td>Non-teaching staff member </td><td>7028062843</td><td><a href="mailto:rushikesh.pawade@indiraisbs.edu.in">rushikesh.pawade@indiraisbs.edu.in</a></td></tr>

                                            </tbody>

                                        </Table>
                                    </div>
                                    <p className="text-center"><b>Student Grievance Committee 2023-24</b></p>
                                    
                                    <div className="col-md-12">
                                        <Table class="table table-responsive table-bordered" responsive>

                                            <tbody>
                                                <tr>
                                                    <td><strong>Sr. No.</strong></td>
                                                    <td><strong>Name of the Committee Members</strong></td>
                                                    <td><strong>Designation in Committee</strong></td>
                                                    <td><strong>Mobile No.</strong></td>
                                                    <td><strong>E-mail-ID</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Dr. Komal Singh</td>
                                                    <td>Chairperson</td>
                                                    <td>9624038181</td>
                                                    <td><a href="mailto:komal.singh@indiraisbs.ac.in">komal.singh@indiraisbs.ac.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Dr. Abhijeet Bobde</td>
                                                    <td>Secretary</td>
                                                    <td>9769211665</td>
                                                    <td><a href="mailto:abhijit.bobde@indiraisbs.ac.in">abhijit.bobde@indiraisbs.ac.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Dr. Manisha Maddel</td>
                                                    <td>Member-Faculty Co-ordinator</td>
                                                    <td>9923317264</td>
                                                    <td><a href="mailto:manisha.maddel@indiraisbsmba.edu.in">manisha.maddel@indiraisbsmba.edu.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Dr. Arvind Burande</td>
                                                    <td>Member- Faculty</td>
                                                    <td>9922802674</td>
                                                    <td><a href="mailto:arvind.burande@indiraisbs.ac.in">arvind.burande@indiraisbs.ac.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Mr. Premchand Agrawala</td>
                                                    <td>Student Member- MBA(Batch 2022-24)</td>
                                                    <td>7008199948</td>
                                                    <td><a href="mailto:premchand.agrawala@indiraisbs.edu.in">premchand.agrawala@indiraisbs.edu.in</a></td>
                                                </tr>
                                                {/*<tr>
                                                <td><strong>Sr. No.</strong></td>
                                                <td><strong>Name of the Committee Members</strong></td>
                                                <td><strong>Designation in Committee</strong></td>
                                                <td><strong>Mobile No.</strong></td>
                                                <td><strong>E-mail-ID</strong></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Renu Bhargava</td>
                                                <td>Chairperson</td>
                                                <td>9890669774</td>
                                                <td><a href="mailto:renub@indiraedu.com">renub@indiraedu.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dr. Abhijeet Bobde</td>
                                                <td>Secretary</td>
                                                <td>9769211665</td>
                                                <td><a href="mailto:abhijit.bobde@indiraisbs.ac.in">abhijit.bobde@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Dr. Komal Singh</td>
                                                <td>Faculty Co-ordinator</td>
                                                <td>9624038181</td>
                                                <td><a href="mailto:komal.singh@indiraisbs.ac.in">komal.singh@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Prof. Manmohan Vyas</td>
                                                <td>Member- Faculty</td>
                                                <td>9552503733</td>
                                                <td><a href="mailto:manmohan.vyas@indiraisbs.ac.in">manmohan.vyas@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Ms. Manya Mishra</td>
                                                <td>Student Council Representative (Batch 2020-22)</td>
                                                <td>8007822622</td>
                                                <td><a href="mailto:manya.mishra@indiraisbsmba.edu.in">manya.mishra@indiraisbsmba.edu.in</a></td>
                        </tr>*/}
                                            </tbody>

                                        </Table>
                                        <p>Online Grievance Mechanism:</p><a href="https://erp.indiraedu.com/studentEmployeeGreviance.htm" target="_blank">https://erp.indiraedu.com/studentEmployeeGreviance.htm</a>
                                    </div>
                                    
                                    

                                    <p className="text-center"><b>Student Counsellor Committee 2023-24</b></p>
                                    <div className="col-md-12">
                                        <Table class="table table-responsive table-bordered" responsive>

                                            <tbody>
                                                <tr>
                                                    <td><strong>Sr. No.</strong></td>
                                                    <td><strong>Name of the Committee Members</strong></td>
                                                    <td><strong>Profession & Designation</strong></td>
                                                    <td><strong>Mobile No.</strong></td>
                                                    <td><strong>E-mail-ID</strong></td>
                                                </tr>
                                                <tr><td>1</td><td>Dr. Arvind Burande</td><td>Chairman- Faculty Member</td><td>9922802674</td><td><a href="mailto:arvind.burande@indiraisbs.ac.in">arvind.burande@indiraisbs.ac.in</a></td></tr>
                                                <tr><td>2</td><td>Dr. Mrityunjay Kumar</td><td>Faculty Member</td><td>9987994617</td><td><a href="mailto:mrityunjay.kumar@indiraisbs.ac.in">mrityunjay.kumar@indiraisbs.ac.in</a></td></tr>
                                                <tr><td>3</td><td>Dr. Kalpana Deshmukh</td><td>Faculty Member</td><td>9764929158</td><td><a href="mailto:kalpana.deshmukh@indiraisbs.ac.in">kalpana.deshmukh@indiraisbs.ac.in</a></td></tr>
                                                <tr><td>4</td><td>Ms. Gauri Kulkarni </td><td>NGO Member-Counsellor</td><td>9657998921</td><td><a href="mailto:savali.gauri@gmail.com">savali.gauri@gmail.com</a></td></tr>
                                            </tbody>

                                        </Table>
                                    </div>
                                </div>
                            </div>
                        }
                        {Complaint &&
                            <div className="paddingLeft">
                                <div className="col-md-12 padding15px whiteBackground">
                                    <p className="paddingTop1em">Internal Complaint Committee has been constituted by the Director with the powers vested in him/her by the Governing council of
                                        Indira School of Business Studies as per the Governing Council meeting held on 29th June, 2013 to address Internal Complaint
                                        Committee (ICC) activities with the broad purpose of creating a fair workplace with gender equality. The cell is especially created to
                                        prevent or deter the commission of acts of gender inequality and sexual harassment and to provide the procedure for the resolution,
                                        settlement or prosecution of acts of sexual harassment and gender biases by taking all steps required</p>
                                    <p className="text-center"><b>The following are the Internal Complaint  Committee  2023-24 members for the Academic Year 2023-24:</b></p>
                                    <p className="text-center"><b>Internal Complaint  Committee  2023-24</b></p>
                                    <Table class="table table-responsive table-bordered" responsive>
                                        <tbody>

                                            <tr>
                                                <td><strong>Sr. No.</strong></td>
                                                <td><strong>Name of the Committee Members</strong></td>
                                                <td><strong>Designation in Committee</strong></td>
                                                <td><strong>Mobile No.</strong></td>
                                                <td><strong>E-mail-ID</strong></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td width="406">Dr. Kalpana Deshmukh</td>
                                                <td width="283">Teaching-Presiding Officer </td>
                                                <td>9764929458</td>
                                                <td><a href="mailto:kalpana.deshmukh@indiraisbs.ac.in">kalpana.deshmukh@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td width="406">Prof.&nbsp;Amrita Karnavat</td>
                                                <td width="283">Teaching- Faculty Member </td>
                                                <td>9429787345</td>
                                                <td><a href="mailto:amrita.karnavat@indiraisbs.ac.in">amrita.karnavat@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td width="406">Dr.Abhijit Bobde</td>
                                                <td width="283">Teaching- Faculty Member </td>
                                                <td>9769211665</td>
                                                <td><a href="mailto:abhijit.bobde@indiraisbs.ac.in">abhijit.bobde@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td width="406">Ms. Sharvari Ratnakar</td>
                                                <td width="283">Non-teaching- Staff member </td>
                                                <td>9850898864</td>
                                                <td><a href="mailto:sharvari.ratnakar@indiraisbs.ac.in">sharvari.ratnakar@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td width="406">Mr. Rushikesh Pawade </td>
                                                <td width="283">Non-teaching- Staff member </td>
                                                <td>7028062843</td>
                                                <td><a href="mailto:rushikesh.pawade@indiraisbs.edu.in">rushikesh.pawade@indiraisbs.edu.in</a></td>
                                            </tr>



                                            <tr>
                                                <td>6</td>
                                                <td width="406">Ms. Lipi Bowaria</td>
                                                <td width="283">Student Member (2022-24 Batch)</td>
                                                <td>9657998921</td>
                                                <td><a href="mailto:lipi.bowaria@indiraisbs.edu.in">lipi.bowaria@indiraisbs.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td width="406">Mr. Rishi Patil</td>
                                                <td width="283">Student Member (2022-24 Batch)</td>
                                                <td>7389182321</td>
                                                <td><a href="mailto:rishi.patil@indiraisbs.edu.in">rishi.patil@indiraisbs.edu.in</a></td>
                                            </tr>

                                            <tr>
                                                <td>8</td>
                                                <td width="406">Mr. Kunal Pawar</td>
                                                <td width="283">Student Member (2023-25 Batch)</td>
                                                <td>8623895307</td>
                                                <td><a href="mailto:kunal.pawar2325@indiraisbs.edu.in">kunal.pawar2325@indiraisbs.edu.in</a></td>
                                            </tr>

                                            <tr>
                                                <td>9</td>
                                                <td width="406">Ms. Mayuri Kamthe </td>
                                                <td width="283">Student Member (2023-25 Batch)</td>
                                                <td>9172318854</td>
                                                <td><a href="mailto:mayuri.kamthe@indiraisbs.edu.in">mayuri.kamthe@indiraisbs.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td width="406">Mr. Harshal Dabhade  </td>
                                                <td width="283">Student Member (2023-25 Batch)</td>
                                                <td>8698562293</td>
                                                <td><a href="mailto:Harshal.dabhade@indiraisbs.edu.in">Harshal.dabhade@indiraisbs.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td width="406">Ms. Gauri Kulkarni</td>
                                                <td width="283">NGO Member</td>
                                                <td>7020649303</td>
                                                <td><a href="mailto:savali.gauri@gmail.com">savali.gauri@gmail.com</a></td>                                            </tr>

                                        </tbody>
                                        {/* <tr>
                                                <td>1</td>
                                                <td width="406">Dr. Kalpana Deshmukh</td>
                                                <td width="283">Presiding Officer</td>
                                                <td>9764929458</td>
                                                <td><a href="mailto:kalpana.deshmukh@indiraisbs.ac.in">kalpana.deshmukh@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td width="406">Prof.&nbsp;Amrita Karnavat</td>
                                                <td width="283">Faculty Member</td>
                                                <td>9429787345</td>
                                                <td><a href="mailto:amrita.karnavat@indiraisbs.ac.in">amrita.karnavat@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td width="406">Prof. Tanay Kurode</td>
                                                <td width="283">Faculty Member</td>
                                                <td>7875202818</td>
                                                <td><a href="mailto:tanay.kurode@indiraisbs.ac.in">tanay.kurode@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td width="406">Ms. Sharvari Ratnakar</td>
                                                <td width="283">Non-teaching staff member</td>
                                                <td>9850898864</td>
                                                <td><a href="mailto:sharvari.ratnakar@indiraisbs.ac.in">sharvari.ratnakar@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td width="406">Mr. Madhukar Jadhav</td>
                                                <td width="283">Non-teaching staff member</td>
                                                <td>9763617683</td>
                                                <td><a href="mailto:madhukar.jadhav@indiraisbs.ac.in">madhukar.jadhav@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td width="406">Mr Chinmay Sontake</td>
                                                <td width="283">Student Member - MBA (Batch 2020-22)</td>
                                                <td>7066771343</td>
                                                <td><a href="mailto:chinmay.sontake@indiraisbsmba.edu.in">chinmay.sontake@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td width="406">Ms Anjana</td>
                                                <td width="283">Student Member - MBA (Batch 2020-22)</td>
                                                <td>9525292433</td>
                                                <td><a href="mailto:anjana.2020@indiraisbsmba.edu.in">anjana.2020@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td width="406">Ms Shiwani Sewaiwar</td>
                                                <td width="283">Student Member - MBA (Batch 2020-22)</td>
                                                <td>8669117198</td>
                                                <td><a href="mailto:shiwani.sewaiwar@indiraisbsmba.edu.in">shiwani.sewaiwar@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td width="406">Ms. Gauri Kulkarni</td>
                                                <td width="283">NGO Member</td>
                                                <td>9657998921</td>
                                                <td><a href="mailto:savali.gauri@gmail.com">savali.gauri@gmail.com</a></td>
                                            </tr>
                                        </tbody>*/}
                                    </Table>
                                </div>
                            </div>
                        }
                        {Ragging &&
                            <div className="paddingLeft">
                                <div className="col-md-12 padding15px whiteBackground">
                                    <p className="paddingTop1em">Ragging is the term used for the so-called "initiation ritual" practiced in higher education institutions but sometimes it involves abuse,
                                        humiliation or harassment of new entrants or junior students by the senior students. It often takes a malignant form wherein the
                                        newcomers may be subjected to psychological or physical torture. Ragging has ruined countless innocent lives and careers. To root
                                        out ragging in all its forms from universities, colleges and other educational institutions this committee has been formed in every
                                        educational institute under the guidelines given by Hon’ble Supreme Court in 2007.</p>
                                    <p>ISBS has zero-tolerance policy on ragging, which is a punishable offence.
                                    </p>
                                    <p className=""><b>What Constitutes Ragging?</b></p>
                                    <p>Any disorderly conduct whether by words spoken or written or by an act which has the effect of teasing, treating or handling with
                                        rudeness any other student, indulging in rowdy or undisciplined activities which causes or is likely to cause annoyance, hardship or
                                        psychological harm or to raise fear or apprehension thereof in a fresher or a junior student or asking the students to do any act or
                                        perform something which such student will not in the ordinary course and which has the effect of causing or generating a sense of
                                        shame or embarrassment so as to adversely affect the physique or psyche of a fresher or a junior student.</p>
                                    <p>As per AICTE guidelines for Student Grievance Cell , an Online Redressal mechanism has been established and it is available to the
                                        student in his ERP login www.erp.indiraedu.co</p>
                                    <h3 className="blockTitle text-center">Anti-Ragging Committee 2023-24</h3>
                                    <Table class="table table-bordered table-responsive" responsive>
                                        <tbody>
                                            <tr>
                                                <td><strong>Sr. No.</strong></td>
                                                <td className="twidth"><strong>Name of the Committee Members</strong></td>
                                                <td><strong>Designation in Committee</strong></td>
                                                <td><strong>Mobile No.</strong></td>
                                                <td><strong>E-mail-ID</strong></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Komal Singh</td>
                                                <td>Chairperson</td>
                                                <td>9624038181</td>
                                                <td><a href="mailto:komal.singh@indiraisbs.ac.in">komal.singh@indiraisbs.ac.in</a></td>
                                            </tr>

                                            <tr>
                                                <td>2</td>
                                                <td>Dr. Mrityunjay Kumar</td>
                                                <td>Secretary</td>
                                                <td>9987994617</td>
                                                <td><a href="mailto:mrityunjay.kumar@indiraisbs.ac.in">mrityunjay.kumar@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Dr. Manisha Maddel</td>
                                                <td>Member- Faculty Co-ordinator</td>
                                                <td>9923317264</td>
                                                <td><a href="mailto:manisha.maddel@indiraisbsmba.edu.in">manisha.maddel@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Dr. Kalpana Deshmukh</td>
                                                <td>Member- Faculty</td>
                                                <td>9764929158</td>
                                                <td><a href="mailto:kalpana.deshmukh@indiraisbs.ac.in">kalpana.deshmukh@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td width="368">Prof. Arvind Burande</td>
                                                <td>Member- Faculty</td>
                                                <td>9922802674</td>
                                                <td><a href="mailto:arvind.burande@indiraisbs.ac.in">arvind.burande@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Mr. Avinash Rathod</td>
                                                <td>Admin Co-ordinator</td>
                                                <td>9604490485</td>
                                                <td><a href="mailto:avinash.rathod@indiraisbs.ac.in">avinash.rathod@indiraisbs.ac.in</a></td>
                                            </tr>
                                            {/*} <tr>
                                                <td>7</td>
                                                <td>Mr. Chintaman Dhengale</td>
                                                <td>Non-Teaching-Member – Administration</td>
                                                <td>9284073984</td>
                                                <td><a href="mailto:chintaman.dhengale@indiraisbs.ac.in">chintaman.dhengale@indiraisbs.ac.in</a></td>
                        </tr>*/}
                                            <tr>
                                                <td>7</td>
                                                <td>Mr. Rushikesh Sunil Pawade</td>
                                                <td>Member (Admin Officer)</td>
                                                <td>7028062843</td>
                                                <td><a href="mailto:rushikesh.pawade@indiraisbs.edu.in">rushikesh.pawade@indiraisbs.edu.in</a></td>
                                            </tr>

                                            <tr>
                                                <td>8</td>
                                                <td>Mr. Premchand Agrawala</td>
                                                <td>Student Member- MBA(Batch 2022-24)</td>
                                                <td>7008199948</td>
                                                <td><a href="mailto:premchand.agrawala@indiraisbs.edu.in">premchand.agrawala@indiraisbs.edu.in</a></td>
                                            </tr>

                                            <tr>
                                                <td>9</td>
                                                <td>Mr. Bhavesh Khadka</td>
                                                <td>Student Member- MBA (2023-2025)</td>
                                                <td>9763896113</td>
                                                <td><a href="mailto:bhavesh.khadka@indiraisbs.edu.in">bhavesh.khadka@indiraisbs.edu.in</a></td>
                                            </tr>

                                            <tr>
                                                <td>10</td>
                                                <td>Ms. Pranjali Koltharkar</td>
                                                <td>Student Member- MBA(Batch 2023-25)</td>
                                                <td>7498039038</td>
                                                <td><a href="mailto:pranjali.koltharkar@indiraisbs.edu.in">pranjali.koltharkar@indiraisbs.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td width="368">Adv. Nelson Narohna</td>
                                                <td> Member - Counsellor </td>
                                                <td>9665094671</td>
                                                <td><a href="mailto:adv.nelsonnoronha@gmail.com">adv.nelsonnoronha@gmail.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td width="368">Ms. Gauri Kulkarni</td>
                                                <td> Representative - NGO</td>
                                                <td>9657998921</td>
                                                <td><a href="mailto:savali.gauri@gmail.com">savali.gauri@gmail.com</a></td>
                                            </tr>
                                        </tbody>
                                        {/*<tbody>
                                            <tr>
                                                <td><strong>Sr. No.</strong></td>
                                                <td class="twidth"><strong>Name of the Committee Members</strong></td>
                                                <td><strong>Designation in Committee</strong></td>
                                                <td><strong>Mobile No.</strong></td>
                                                <td><strong>E-mail-ID</strong></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Renu Bhargava</td>
                                                <td>Chairperson</td>
                                                <td>9890669774</td>
                                                <td><a href="mailto:renub@indiraedu.com">renub@indiraedu.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dr. Mrityunjay Kumar</td>
                                                <td>Secretary</td>
                                                <td>9987994617</td>
                                                <td><a href="mailto:mrityunjay.kumar@indiraisbs.ac.in">mrityunjay.kumar@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Dr. Komal Singh</td>
                                                <td>Faculty Co-ordinator</td>
                                                <td>9624038181</td>
                                                <td><a href="mailto:komal.singh@indiraisbs.ac.in">komal.singh@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Prof. Manmohan Vyas</td>
                                                <td>Member- Faculty</td>
                                                <td>9552503733</td>
                                                <td><a href="mailto:manmohan.vyas@indiraisbs.ac.in">manmohan.vyas@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td width="368">Prof. Arvind Burande</td>
                                                <td>Member- Faculty</td>
                                                <td>9922802674</td>
                                                <td><a href="mailto:arvind.burande@indiraisbs.ac.in">arvind.burande@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Mr. Madhukar Jadhav</td>
                                                <td>Admin coordinator</td>
                                                <td>9763617683</td>
                                                <td><a href="mailto:madhukar.jadhav@indiraisbs.ac.in">madhukar.jadhav@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Mr. Chintaman Dhengale</td>
                                                <td>Member – Administration</td>
                                                <td>9284073984</td>
                                                <td><a href="mailto:chintaman.dhengale@indiraisbs.ac.in">chintaman.dhengale@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Ms. Manya Mishra</td>
                                                <td>Student Member- MBA(Batch 2020-22)</td>
                                                <td>8007822622</td>
                                                <td><a href="mailto:manya.mishra@indiraisbsmba.edu.in">manya.mishra@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Mr. Sumit Deshmukh</td>
                                                <td>Student Member- MBA(Batch 2020-22)</td>
                                                <td>8411026669</td>
                                                <td><a href="mailto:deshmukh.gulabrao@indiraisbsmba.edu.in">deshmukh.gulabrao@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Mr. Parag Mehta</td>
                                                <td>Student Member- MBA(Batch 2021-23)</td>
                                                <td>8983759702</td>
                                                <td><a href="mailto:parag.mehta@indiraisbsmba.edu.in">parag.mehta@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td width="368">Adv. Nelson Narohna</td>
                                                <td>Member - Counsellor</td>
                                                <td>9665094671</td>
                                                <td><a href="mailto:adv.nelsonnoronha@gmail.com">adv.nelsonnoronha@gmail.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td width="368">Ms. Gauri Kulkarni</td>
                                                <td>Representative - NGO</td>
                                                <td>9657998921</td>
                                                <td><a href="mailto:savali.gauri@gmail.com">savali.gauri@gmail.com</a></td>
                                            </tr>
                        </tbody>*/}
                                    </Table>
                                    <h3 className="blockTitle text-center">Anti-Ragging Squad 2023-24</h3>
                                    <Table class="table table-bordered table-responsive" responsive>

                                        <tbody>
                                            <tr>
                                                <td><strong>Sr. No.</strong></td>
                                                <td class="twidth"><strong>Name of the Committee Members</strong></td>
                                                <td><strong>Designation in Committee</strong></td>
                                                <td><strong>Mobile No.</strong></td>
                                                <td><strong>E-mail-ID</strong></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Manisha Maddel</td>
                                                <td>Member Co-ordinator</td>
                                                <td>9923317264</td>
                                                <td><a href="mailto:manisha.maddel@indiraisbsmba.edu.in">manisha.maddel@indiraisbsmba.edu.in</a></td>
                                            </tr>


                                            <tr>
                                                <td>2</td>
                                                <td>Dr. Kalpana Deshmukh</td>
                                                <td>Teaching-Member</td>
                                                <td>9764929158</td>
                                                <td><a href="mailto:kalpana.deshmukh@indiraisbs.ac.in">kalpana.deshmukh@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td width="368">Dr. Mrityunjay Kumar</td>
                                                <td>Secretary</td>
                                                <td>9987994617</td>
                                                <td><a href="mailto:mrityunjay.kumar@indiraisbs.ac.in">mrityunjay.kumar@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td width="368">Prof. Arvind Burande</td>
                                                <td>Member- Faculty</td>
                                                <td>9922802674</td>
                                                <td><a href="mailto:arvind.burande@indiraisbs.ac.in">arvind.burande@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Mr. Avinash rathod</td>
                                                <td>Admin Co-ordinator</td>
                                                <td>9604490485</td>
                                                <td><a href="mailto:avinash.rathod@indiraisbs.ac.in">avinash.rathod@indiraisbs.ac.in</a></td>
                                            </tr>
                                            {/*<tr>
                                                <td>5</td>
                                                <td>Mr. Madhukar Jadhav</td>
                                                <td>Admin coordinator</td>
                                                <td>9763617683</td>
                                                <td><a href="mailto:madhukar.jadhav@indiraisbs.ac.in">madhukar.jadhav@indiraisbs.ac.in</a></td>
                    </tr>*/}
                                        </tbody>

                                    </Table>
                                </div>
                            </div>
                        }


                        {SCST &&
                            <div className="paddingLeft">
                                <div className="col-md-12 padding15px whiteBackground">
                                    <p className="paddingTop1em">The Scheduled Caste (SC) and Scheduled Tribes (ST) Cell in an institute promotes the special interest of students in the reserved category and to provide special inputs in areas where the students experience difficult.</p>
                                    <p className="text-center"><b>SC/ST Committee (ISBS) A.Y. 2023-24</b></p>
                                    <div className="col-md-12">
                                        <Table class="table table-responsive table-bordered" responsive>

                                            <tbody>

                                                <tr>
                                                    <td><strong>Sr. No.</strong></td>
                                                    <td><strong>Name of the Committee Members</strong></td>
                                                    <td><strong>Designation in Committee</strong></td>
                                                    <td><strong>Mobile No.</strong></td>
                                                    <td><strong>E-mail-ID</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Dr. Komal Singh </td>
                                                    <td>Chairman </td>
                                                    <td>9624038181</td>
                                                    <td><a href="mailto:komal.singh@indiraisbs.ac.in">komal.singh@indiraisbs.ac.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Dr. Mrityunjay Kumar</td>
                                                    <td>Section/Liaison Officer </td>
                                                    <td>9987994617</td>
                                                    <td><a href="mailto:mrityunjay.Kumar@indiraisbs.ac.in">mrityunjay.Kumar@indiraisbs.ac.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Dr. Arvind Burande</td>
                                                    <td>Member </td>
                                                    <td>9922802674</td>
                                                    <td><a href="mailto:arvind.burande@indiraisbs.ac.in">arvind.burande@indiraisbs.ac.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Mr. Dayanand Kambale	</td>
                                                    <td>Member </td>
                                                    <td >9822337422</td>
                                                    <td><a href="mailto:dayanand.kamble@indiraisbs.ac.in">dayanand.kamble@indiraisbs.ac.in</a></td>
                                                </tr>
                                                {/*} <tr>
                                                <td>5</td>
                                                <td>Mr. Chintman Dhengale</td>
                                                <td>Member  (MBA Coordinator)</td>
                                                <td >9284073984</td>
                                                <td><a href="mailto:chintaman.dhengale@indiraisbs.ac.in">chintaman.dhengale@indiraisbs.ac.in</a></td>
                       </tr>*/}
                                                <tr>
                                                    <td>5</td>
                                                    <td>Mr. Manoj Singe</td>
                                                    <td>Member </td>
                                                    <td >7349170166</td>
                                                    <td><a href="mailto:manoj.singe@indiraisbs.edu.in">manoj.singe@indiraisbs.edu.in</a></td>
                                                </tr>

                                                <tr>
                                                    <td>6</td>
                                                    <td>Mr. Rushikesh Pawade</td>
                                                    <td>Member </td>
                                                    <td >7028062843</td>
                                                    <td><a href="mailto:rushikesh.pawade@indiraisbs.edu.in">rushikesh.pawade@indiraisbs.edu.in</a></td>
                                                </tr>


                                                <tr>
                                                    <td>7</td>
                                                    <td>Ms. Rashmi Patekar</td>
                                                    <td>Student (MBA 2022-2024)</td>
                                                    <td>9158192887</td>
                                                    <td><a href="mailto:rashmi.patekar@indiraisbs.edu.in">rashmi.patekar@indiraisbs.edu.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>Mr. Girish Mohite</td>
                                                    <td>Student (MBA 2022-2024)</td>
                                                    <td >9075771099</td>
                                                    <td><a href="mailto:girish.mohite@indiraisbs.edu.in">girish.mohite@indiraisbs.edu.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>Mr. Krutik Khandare</td>
                                                    <td>Student (MBA 2023-2025)</td>
                                                    <td >9665871381</td>
                                                    <td><a href="mailto:krutik.khandare@indiraisbs.edu.in">krutik.khandare@indiraisbs.edu.in</a></td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>Ms. Mayusha Tatale</td>
                                                    <td>Student (MBA 2023-2025)</td>
                                                    <td >9607497713</td>
                                                    <td><a href="mailto:mayusha.tatale@indiraisbs.edu.in">mayusha.tatale@indiraisbs.edu.in</a></td>
                                                </tr>


                                                {/*} <tr>
                                                <td>1</td>
                                                <td>Dr. Renu Bhargava</td>
                                                <td>Chairperson</td>
                                                <td>9890669774</td>
                                                <td><a href="mailto:renub@indiraedu.com">renub@indiraedu.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dr. Mrityunjay Kumar</td>
                                                <td>Section/Liaison Officer	</td>
                                                <td>9987994617</td>
                                                <td><a href="mailto:mrityunjay.Kumar@indiraisbs.ac.in">mrityunjay.Kumar@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Prof. Tanay Kurode</td>
                                                <td>Member</td>
                                                <td>7875202818</td>
                                                <td><a href="mailto:tanay.kurode@indiraisbs.ac.in">tanay.kurode@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Mr. Dayanand Kambale	</td>
                                                <td>Member</td>
                                                <td >9822337422</td>
                                                <td><a href="mailto:dayanand.kamble@indiraisbs.ac.in">dayanand.kamble@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Mr. Chintman Dhengale</td>
                                                <td>Member</td>
                                                <td >9284073984</td>
                                                <td><a href="mailto:chintaman.dhengale@indiraisbs.ac.in">chintaman.dhengale@indiraisbs.ac.in</a></td>
                                            </tr>

                                            <tr>
                                                <td>6</td>
                                                <td>Mr. Madhukar Jadhav</td>
                                                <td>Member</td>
                                                <td >9763617683</td>
                                                <td><a href="madhukar.jadhav@indiraisbs.ac.in">madhukar.jadhav@indiraisbs.ac.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Uday Dhananjay Khillare</td>
                                                <td>Student (MBA 2020-2022)	</td>
                                                <td>8999788825</td>
                                                <td><a href="mailto:uday.khillare@indiraisbsmba.edu.in">uday.khillare@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Ruchita Kundlik Wankhede</td>
                                                <td>Student (MBA 2020-2022)</td>
                                                <td >8554011570</td>
                                                <td><a href="mailto:ruchita.wankhede@indiraisbsmba.edu.in">ruchita.wankhede@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Shweta Ravikant Narwade</td>
                                                <td>Student (MBA 2021-2023)</td>
                                                <td >9075753633</td>
                                                <td><a href="mailto:shweta.narwade@indiraisbsmba.edu.in">shweta.narwade@indiraisbsmba.edu.in</a></td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Chetan Vijay Masram</td>
                                                <td>Student (MBA 2021-2023)</td>
                                                <td>9881864137</td>
                                                <td><a href="mailto:chetan.masram@indiraisbsmba.edu.in">chetan.masram@indiraisbsmba.edu.in</a></td>
                                            </tr>*/}
                                            </tbody>

                                        </Table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="container paddingTop3em paddingBottom3em">
                <div className="row padding15px">
                    <div className="col-md-2 positionAbsolute mobHide">
                        <img src={DrugImage} alt="" className="circleAboutIcon" />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                        <img src={DrugImage} alt="" className="circleAboutIconMob1 mobShow" />
                        <h4 className="paddingTop">Drug / Alcohol Policy</h4>
                        <p className="paddingTop">Students of Indira are expected to abstain from drugs and alcohol during their stay at the institute. Violation of this policy
                            will lead to cancellation of admission.</p>
                    </div>
                </div>
                <div className="row padding15px">
                    <div className="col-md-2 positionAbsolute mobHide">
                        <img src={DressImage} alt="" className="circleAboutIcon" />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                        <img src={DressImage} alt="" className="circleAboutIconMob1 mobShow" />
                        <h4 className="paddingTop">Dress Code</h4>
                        <p className="paddingTop">At Indira, we believe in inculcating a sense of discipline and belonging in the students by observing a strict dress code.
                            Students are expected to wear formal dress (shirt, trouser & tie for gents; western formals/salwar kameez for ladies)
                            throughout the week, except on Saturdays which will be observed as 'casual-day'. On the occasion of guest lectures, seminars,
                            etc. students are expected to be dressed in the Indira uniform (blazers, tie, shirt, trouser). A student failing to adhere to the
                            dress code will not be allowed to attend lectures and will face strict disciplinary action.
                        </p>
                    </div>
                </div>
                <div className="row padding15px">
                    <div className="col-md-2 positionAbsolute mobHide">
                        <img src={HostelImage} alt="" className="circleAboutIcon" />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                        <img src={HostelImage} alt="" className="circleAboutIconMob1 mobShow" />
                        <h4 className="paddingTop">Hostel Rules</h4>
                        <p className="paddingTop">The boys and girls Hostel at Indira have a set of rules which must be adhered to strictly by the students. Each hostel is headed
                            by a warden who acts as a facilitator and observer to maintain discipline in the hostels and ensure its smooth functioning.
                            Students opting to stay in the hostel will have to sign an undertaking in accepting the rules set by the management. Ragging
                            of any type will not be accepted and the concerned students' admissions would be cancelled immediately.
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ComplianceCommittee
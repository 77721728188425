import React, { Fragment } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/alumni/bannernetwork.jpg"
import Icon1Image from "../../assets/images/alumni/alumniweb.png"
import AlumniImage from "../../assets/images/alumni/alumni.jpg"

const AlumniNetwork = () => {
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#ffffff', textAlign: "left", paddingLeft: '6%' }}>Indira Alumni<br />
                    Network</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 somi">
                        <h3 className="blockTitle">ABOUT US &#62; <span>INDIRA ALUMNI NETWORK</span></h3>
                    </div>
                </div>
            </div>
            <div className="marginBottom3em">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row padding15px">
                                <div className="col-md-12">
                                    <h3 className="blockTitle">“Nobody is bothered about an institution more than its alumni” – Mr. Narayan Murthy.</h3>
                                    <p>The words have always rung aloud in our ears. To empower the alumni and give the necessary platform, we launched the muchawaited Alumni Portal – “Indira Alumni Network” on Saturday 22nd January 2022. The platform will help the alumni of the 3 colleges
                                        – ISBS PGDM, ICCS and ISBS to remain in touch, guide the junior batches, for lateral placements and also through other engagements
                                        of giving it back to their alma mater. The portal is live.</p>
                                    <p className="text-center"><img src={Icon1Image} style={{ width: '30px' }} /> Register Now at:<a href="https://www.indiraalumni.in/" target="_blank">www.indiraalumni.in</a> </p>
                                    <img src={AlumniImage} className="imgRes" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AlumniNetwork
import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/ResearchJournal.jpg"

const ResearchJournal = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Publications &#62; <span> Research Journal</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 paddingTop3em paddingBottom3em">
                        <p>Indira Management Review is a national annual peer reviewed journal published by Shree Chanakya Education Society’s Indira School of Business Studies, which is one of the top management colleges in Pune. It is a quality research journal and it endeavors to provide forum for academicians and industry participants who are interested in the discussion of current and future issues as well as challenges impacting the globe. IMR focuses on promoting and disseminating relevant, high quality research in the field of management.</p>
                        <p><b>IMR encourages new ideas and perspectives on existing research. Manuscripts that are suitable for publication in IMR cover domains such as:</b></p>
                        <ul>
                            <li>Business Strategy and Policy</li>
                            <li>IT Sourcing</li>
                            <li>Entrepreneurship</li>
                            <li>Human Resource Management</li>
                            <li>Financial Management</li>
                            <li>Organizational Behavior</li>
                            <li>Marketing Management and Research</li>
                        </ul>
                        <p>The aim of this journal is to percolate knowledge in various research fields and elevate high end research. Education without research and development becomes meaningless to the community. We feel highly motivated by the positive response from contributors and likeminded educational fraternity showing their deep interest in contributing towards our journal. The objective of promoting quality research is being pursued vigorously by providing blind reviews and plagiarism check.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ResearchJournal
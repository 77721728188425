import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/student-life/stud-eng.jpg"
import Rc1Image from "../../assets/images/student-life/rc1.jpg"
import sc1Image from "../../assets/images/student-life/sc1.png"
import sc2Image from "../../assets/images/student-life/sc2.png"
import sc3Image from "../../assets/images/student-life/sc3.png"
import sc4Image from "../../assets/images/student-life/sc4.png"
import sc5Image from "../../assets/images/student-life/sc5.png"
import sc6Image from "../../assets/images/student-life/sc6.png"
import sc7Image from "../../assets/images/student-life/sc7.png"
import sc8Image from "../../assets/images/student-life/sc8.png"
import sc9Image from "../../assets/images/student-life/sc9.png"
import sc10Image from "../../assets/images/student-life/sc10.png"
import sc11Image from "../../assets/images/student-life/sc11.png"
import sc12Image from "../../assets/images/student-life/sc12.png"
import sc13Image from "../../assets/images/student-life/sc13.png"
import sc14Image from "../../assets/images/student-life/sc14.png"
import sc15Image from "../../assets/images/student-life/sc15.png"
import sc16Image from "../../assets/images/student-life/sc16.png"
import sc17Image from "../../assets/images/student-life/sc17.png"
import sc18Image from "../../assets/images/student-life/sc18.png"
import sc19Image from "../../assets/images/student-life/sc19.png"
import sc20Image from "../../assets/images/student-life/sc20.png"
import sc21Image from "../../assets/images/student-life/sc21.png"
import Svg1Image from "../../assets/images/svg/1.svg"
import Svg2Image from "../../assets/images/svg/2.svg"
import Svg3Image from "../../assets/images/svg/3.svg"
import Svg4Image from "../../assets/images/svg/4.svg"
import SvgAImage from "../../assets/images/svg/A.svg"
import SvgBImage from "../../assets/images/svg/B.svg"
import Carousel from 'react-bootstrap/Carousel';
const StudentEngagement = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout rightAlignBannerTxt whiteText" style={{ color: '#fff' }}>Student<br />ENGAGEMENT</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Student Life &#62; <span> Student Engagement</span></h3>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container">
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc1Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc1Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Indira Employability Skills Enhancement Program (IESEP)</h4>
                            <p>The corona virus has transformed our lives. Schools and Colleges are closed and we are confined to our homes. With the
                                support and contribution of our Management, Students, Faculty and Mentors we could successfully deliver external IESEP
                                inputs to our students in the most efficient and seamless manner. There were many challenges as everybody was
                                learning and trying to adjust to the new normal of digital interaction. However, our passion to give the best to our students
                                regardless of the situation helped us sail through smoothly. The sessions were very interactive in the online platform and
                                students acquired knowledge across different specializations.</p>
                            <p>We have also tied up with external agencies (as mentioned below), to impart the best of knowledge and skill-set to our
                                students as a part of External CLDP</p>
                           {/* <Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6 mobHide">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6 mobHide">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6 mobHide">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                    </div>
                                </Carousel.Item>
    </Carousel>*/}
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc2Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc2Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Ozone Consultancy</h4>
                            <p>Ozone Education Consultants is a consortium of senior level professionals from industry who contribute their two cents
                                towards development of Management Education in India and bridge the gap between Industry & Institutes.
                                The mentors have developed two unique modules "One on One" and "One on Many" programs for B school and
                                engineering students. The objective of the program is to enhance the employability coefficient of the students.
                                The whole array of activities conducted by Ozone from Semester I to 111 includes - Discovery Interviews to focus on
                                attitude, skills and learn ability of students. Sector Overview, which aims at sharing insights about various sectors like
                                Retail, FMCG, IT, Financial services, Engineering and Manufacturing, Consultancy, New Age Digital Companies etc. During
                                these sessions, the Mentor addresses the students about key challenges faced in a particular sector and prepares them
                                for final placement. The Mentor guides the students towards making a right career choice and provides them better
                                understanding of a job role. Individual concerns and queries regarding career in a sector are taken care of. Specialization
                                inputs are provided to the students with the objective to introduce them to different areas of specialization. Students
                                participate in meaningful discussion during these sessions to gain better insights about different domains.
                                Group Discussions are held with the objective to create an effective managerial scenario for the students. Prelude to Pis
                                session helps the students understand Interview Do's and Don'ts. Pre-SIP inputs are provided to the students to assist
                                them in meeting the expectations of Summer Internship Company in a better way. Sector Deep Dive related to sectors like
                                FMCG, Retailing, Finance, Consultancy etc. helps students to prepare better for their preferred sector by sharing deeper
                                knowledge of headwinds and tailwinds, trends, recent news, main players and live examples of people working in the
                                sector. Alpha Batch is a novel concept started by Ozone to identify and develop the top 10% of the students. These
                                students are geared to be near "Job Ready" as opposed to be just "Interview Ready". The students are also groomed to
                                make them more capable of getting jobs that offer more challenges and are dream jobs in the campus. The Alpha Batch
                                students are put through Interview rounds like- Improvement Plan Interview and Company Specific Interview.</p>
                            <p>Personal Interviews are conducted with the objective to make students understand their abilities and to identify their
                                developmental areas. Placement Grading Pis are conducted with the purpose of boosting self-assurance and industry
                                mindfulness within students. Specialization wise inputs are also provided under Ozone Career Development Program that
                                covers:</p>
                            <p>Under these programs, mentors from different companies conduct sessions to train the students in the rigor of corporate
                                environment. These mentors are the specialists in providing training in the areas of 828 marketing, Finance for nonfinance, Placement grooming, Employability training and Institute Industry interactions. To have a mention of few of these
                                mentors</p>
                            <ul className="reg1">
                                <li>Mr. Shantanu Sen Sharma, Co-Founder and Leadership Coach-Ozone Consultants Private Limited</li>
                                <li>Mr. Kaivalya Kandalgaonkar, Lead-Strategic and Governance Projects-Vodafone</li>
                                <li>Mr. Srinivas Chunduru, Founder & Director-Vans Group </li>
                                <li>Mr. Ravi Srivastava, Business Relationship and Engagement SPOC of IT for various Business Units covering APAC
                                    and America regions-Capgemini</li>
                                <li>Mr. V. Srinivasan, CEO of an IT startup compan</li>
                                <li>Mr. Prasenjit Ghosh Roy, Senior Executive Vice President & CMO-NTT Netmagic</li>
                                <li>Ms. Meenu Bhatia, Co-Founder-Vans Skilling and Advisory</li>
                                <li>Mr. Atul Nevase, Senior Manager at Tech Mahindra</li>
                            </ul>
                            <br />
                            <div className="row">
                                <div className="col-md-3 positionRelative boxShadowLeft">
                                    <img src={Svg1Image} alt="" className="ImgIconSVG1" />
                                    <p className="paddingLeft30"><b>Marketing Specialization</b></p>
                                    <ul className="reg1">
                                        <li>Selling Models </li>
                                        <li>Spin Selling</li>
                                        <li>Key Account Management</li>
                                    </ul>
                                </div>
                                <div className="col-md-3 positionRelative boxShadowLeft">
                                    <img src={Svg2Image} alt="" className="ImgIconSVG1" />
                                    <p className="paddingLeft30"><b>Finance<br />
                                        Specialization</b></p>
                                    <ul className="reg1">
                                        <li>Fun with finance</li>
                                        <li>Selling Dynamics
                                            in BFSI sector</li>
                                    </ul>
                                </div>
                                <div className="col-md-3 positionRelative boxShadowLeft">
                                    <img src={Svg3Image} alt="" className="ImgIconSVG1" />
                                    <p className="paddingLeft30"><b>Human
                                        Resources
                                        Specialization</b></p>
                                    <ul className="reg1">
                                        <li>Team Building
                                            & Employee
                                            Engagement</li>
                                    </ul>
                                </div>
                                <div className="col-md-3 positionRelative boxShadowLeft">
                                    <img src={Svg4Image} alt="" className="ImgIconSVG1" />
                                    <p className="paddingLeft30"><b>Operations
                                        & SCM
                                        Specialization</b></p>
                                    <ul className="reg1">
                                        <li>Six Sigma
                                            Certification</li>
                                        <li>Agile
                                            Methodology</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc3Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc3Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Stratecent Consulting</h4>
                            <p>Stratecent Consulting is a strategy and human capital services company. Their services include building capability in Sales,
                                Business Performance Measurement, Balanced Scorecard, Strategy Mapping, Scenario Planning, Leadership Development,
                                Training and Advisory Services. They aim to develop and implement practical, result-oriented solutions.
                            </p>
                            <p><b>The Programs offered by Stratecent Consulting are</b></p>
                            <div className="row">
                                <div className="col-md-12 positionRelative boxShadowLeft">
                                    <img src={SvgAImage} alt="" className="ImgIconSVG1" />
                                    <p className="paddingLeft30-2"><b>CDP (Career Development Program)</b></p>
                                    <p>Under CDP, students are given 200 hours of inputs spread across a period of 20-25 days and trained on skills
                                        such as SPIN selling, Business Performance Measurement, Negotiation Skills, Strategy mapping etc.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12 positionRelative boxShadowLeft">
                                    <img src={SvgBImage} alt="" className="ImgIconSVG1" />

                                    <p className="paddingLeft30-2"><b>AMP (Advanced Marketing Program)</b></p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="paddingLeft30-2"><b>Under AMP, following skills are imparted
                                                to the students</b></p>
                                            <ul className="reg1">
                                                <li>Digital marketing </li>
                                                <li>Real time marketing</li>
                                                <li>Contextual marketing </li>
                                                <li>New ways of measuring customer
                                                    satisfaction and lifetime value </li>
                                                <li>Pricing and new methods</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 borderLeft12">
                                            <p><b>The pedagogy of the programme
                                                includes</b></p>
                                            <ul className="reg1">
                                                <li>E-learning</li>
                                                <li>Video presentation</li>
                                                <li>Debate</li>
                                                <li>Reading and class discussion</li>
                                                <li>Role plays</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-12">
                                            <p style={{ borderTop: "1px solid #c0c0c0", paddingTop: '10px' }}><b>The objectives of AMP are</b></p>
                                            <ul className="reg1">
                                                <li>To help the students have a deeper knowledge about sales and marketing theory and practice</li>
                                                <li>To develop knowledge and competence for effective market planning and execution and consultative selling </li>
                                                <li>Understanding interrelationship of various market mix elements (e.g. Product, Price, Place & Promotion) </li>
                                                <li>Groom the students for higher roles in sales and marketing function</li>
                                                <li>Improve students' ability to read, understand and apply concepts relevant to these topics</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc4Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc4Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Proton Training Solution</h4>
                            <p>Proton Training Solutions (PTS Education) is a reputed training academy in Pune for MBA Entrance, BBA Entrance, Law
                                entrance, Study Abroad, Aptitude Training, and Campus Recruitment Training. Proton's training programs have been
                                designed with a philosophy that any student if given the right kind of guidance, motivation, and training will certainly be able
                                to achieve greater heights.
                            </p>
                            <p>Modules imparted by Proton <br />
                                The modules imparted by Proton are different across semesters and include topics like- Calculations Technique & Decimal
                                Fractions, Basics of Verbal Ability, Divisibility, HCF, LCM, Family Tree /Relations, Directions, Coding & Decoding Arrangements,
                                Percentage Applications, Logical deductions, Logical deductions, Arithmetic Applications, Modern Maths, QA workshops, LR
                                workshops, VA workshops etc</p>
                           {/* <Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6 mobHide">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6 mobHide">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                        <div className="col-md-6 mobHide">
                                            <img src={Rc1Image} alt="" className="imgRes" />
                                        </div>
                                    </div>
                                </Carousel.Item>
</Carousel>*/}
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc5Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc5Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">English Language Lab </h4>
                            <p>From the beginning of the English Language Lab and communication classes, the significance of 4 skills of reading, writing,
                                listening and speaking are highlighted to the students. These skills not only help the students in better sustenance at the
                                workplace in the future but also assist them in placements in credible organisations.

                            </p>
                            <p><b>Broad topics covered during these sessions are</b></p>
                            <ul className="reg1">
                                <li>Basic spelling of words commonly used in the Business Environment</li>
                                <li>Significance of communication skills at the workplace</li>
                                <li>How to have better conversations?</li>
                                <li>"Parts of speech" etc</li>
                            </ul>
                            <p>Different types of tests are conducted from time to time. Students are assessed on various parameters revolving around the
                                four skills of reading, writing, speaking and listening. There is a dedicated English Communication Lab which leverages
                                technology in improving the communication skills of the students.
                            </p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc6Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc6Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">News Resources</h4>
                            <p>“Nothing can now be believed which is seen in a newspaper. Truth itself become suspicious by being put into that polluted
                                vehicle”
                                <b>- Thomas Jefferson</b></p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc7Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc7Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Business News Analysis
                            </h4>
                            <p>Business News Analysis (BNA) sessions help students identify and analyse the key characteristics of the three most
                                common types of business news articles-straight news, feature and opinion. These sessions help students develop their
                                news literacy and critical thinking skills.</p>
                            <p><b>BNA sessions broadly revolve around the following</b></p>
                            <ul className="reg1">
                                <li>Glossary of economics</li>
                                <li>Familiarizing with business terminology</li>
                                <li>Decoding business and economic jargons and how to use them</li>
                                <li>To understand and articulate opinions on contemporary issues </li>
                                <li>Review and recapitulation of important news of the week</li>
                            </ul>
                            <p>Examples are shared with students regarding each type of news article, followed by a class discussion. A discussion
                                normally happens around the topics like- latest budget, latest trends in macroeconomic indicators like inflation, GDP, latest
                                business mergers and acquisitions, new appointments of business heads etc. Tests are conducted on a regular basis to
                                assess students' understanding and awareness about business news.</p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc8Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc8Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Certification Courses from Havish Consultancy
                            </h4>
                            <p>The term 'Industry 4.0' stands for the fourth industrial revolution. Behind the scenes of the world's leading industrial
                                companies, a profound digital transformation is now underway. Industrial leaders are digitizing essential functions and
                                processes. They are enhancing their product portfolio with digital functional ities and are investing in data analytics as a
                                foundational capability to drive innovation and significant improvements in efficiency. In India as well, we see industrial
                                companies planning to dramatically increase their overall level of digitization. Either businesses are also strengthening their
                                offerings to customers by digitizing existing products, by offering them through digital channels, offering connected services
                                or using data analytics to find hidden correlations to identify new product ideas even before customers know they want
                                them. The opportunity is there not only to greatly increase the ability to respond flexibly and more rapidly to customer
                                demands but also to anticipate demands, thereby helping customers get ahead of themselves in a range of predictive ways.</p>
                            <p><b>Analytics is at the core of Industry 4.0 & digital transformation journey. Following 16 courses on Business Analytics are
                                provided to our students to sharpen their Business Analytic skills</b></p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="reg1">
                                        <li>Power Bl for data analytics and visualization</li>
                                        <li>Tableau data visualization for absolute beginners</li>
                                        <li>Excel lookup - the master guide</li>
                                        <li>Pivot table data analysis</li>
                                        <li>Getting started with excel VBA </li>
                                        <li>Excel financial formulas for beginners</li>
                                        <li>Excel charts - all the charts you will ever need </li>
                                        <li>Excel date functions for data analysis and modelling </li>
                                    </ul>
                                </div><div className="col-md-6">
                                    <ul className="reg1">
                                        <li>Excel data cleaning and preparation</li>
                                        <li>Power query data wrangling </li>
                                        <li>Dynamic arrays - supercharge your excel formulas</li>
                                        <li>PowerPoint - storytelling with data</li>
                                        <li>Python for absolute beginners </li>
                                        <li>Python - interact with excel</li>
                                        <li>Python data visualization with seaborn</li>
                                        <li>Python web scraping</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc9Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc9Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Placement Grading Aptitude and Domain Test</h4>
                            <p>The placement grading aptitude and domain test is essential for all students those who are getting placed through campus.
                                This activity is a part of training as well as grading in placement shortlisting process. In competitive world mostly company
                                ask aptitude test in preliminary round. Most of the students get eliminated in preliminary round on account of lack in aptitude
                                test. Indira school of Business Studies provides opportunities to the students to get prepare in aptitude and domain by
                                conducting 6 aptitude tests and 2 domain tests. The test is provided by the Xed Intellect, it is online test and conducted
                                continuously 6 months (i.e. two semester) after taking test, students get result in real time so, that they come to know where
                                they stand.</p>
                        </div>
                    </div>

                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc10Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc10Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Personality Development and Communication Workshop</h4>
                            <p>The workshop for students each year is conducted in 2 phases - one in the first semester and the second in the second
                                semester. Additional inputs for the weaker students ae arranged o ensure they too are at par in terms of communication
                                skills. These students are shortlisted based on their score in the first semester. Besides communication skills, ISBS aims to
                                nurture and empower its future managers with domain knowledge, by sharpening appropriate soft skills to meet the everchanging needs of modern globalized business procedures. Programs are process driven with content standardization,
                                validation of execution and with appropriate trainer selection as per the requisite skills.
                            </p>
                            <p><b>The Program includes inputs on</b>
                            </p>
                            <ul className="reg1">
                                <li>Goal Setting and Career Planning.</li>
                                <li>Positive Thinking and Motivation. </li>
                                <li>Time Management, People Skills, Business Etiquette. </li>
                                <li>Personal Grooming, Hygiene and many more.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc11Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc11Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Group Discussion and Personal Interview</h4>
                            <p>Group Discussion or GD as widely known in the corporate industries is an important part of understanding and learning the
                                individual's team bonding skills, leadership skills and inter - personal skills. To conduct GD, the topics spanning across
                                industries - with emphasis given to the current affairs, socially contextually relevant burning issues and domain topics are
                                brainstormed as part of GD. Industry experts & alumni evaluate the students on set parameters and give personalized
                                recommendations and steps to improve. Personal Interviews and Mock Interviews are conducted for the students by the
                                internal faculty as well as by alumni of ISBS, which helps them prepare well for their internship interviews and their final
                                placements.</p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc12Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc12Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Speakathon</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <p>Speakathon is a 7 - minute prepared speech given by
                                        the students on varied topics. The topics given are as
                                        varied as</p>
                                    <ul className="reg1">
                                        <li>My first day in ISBS.</li>
                                        <li>Narendra Modi-The Leader</li>
                                        <li>Frauds in India.</li>
                                        <li>My Family</li>
                                        <li>A feel-good incident</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <p>There are also some phrases are told to make stories
                                        and This helps the students in their them think. In this
                                        activity, the on five parameters
                                    </p>
                                    <ul className="reg1">
                                        <li>Content</li>
                                        <li>Story Telling ability</li>
                                        <li>Grammar</li>
                                        <li>Body Language </li>
                                        <li>Voice Modulation</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc13Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc13Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Jargon Mania</h4>
                            <p>Jargon Mania is explanation of various jargons that are used in functional specializations and General Management. The
                                students are given a list of jargons along with their meaning & are supposed to read, understand and make others
                                understand the meaning of that particular jargon. This activity ensured that the students are well abreast with knowledge
                                and meaning of different terms used in Business Management.</p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc14Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc14Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">DEAR Books</h4>
                            <p>DEAR is an acronym which stands for Drop Everything And Read. As an MBA student, we expect the students to be
                                aware of the current happenings that are taking place in the nation and across the globe. It is very important from the
                                perspective of an MBA student to know what political, technological & social activities are happening in the world and
                                how will this be affecting their corporate careers later in their life? This activity also ensures that the students prepare all
                                the notes about a particular industry or a company in which they would like to see themselves working 5 years down the
                                line, cut and paste that news in a book, which is named as DEAR book. This activity is also linked to academic grading
                                wherein the students are divided into groups and given an industry to be studied and analyzed. The DEAR books help the
                                students in getting some current facts and figures about the industry and ensure that the report prepared contains the
                                latest figures about the industry which helps them in their final placement.</p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc15Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc15Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Collaboration with Corporate Bodies and Associations</h4>
                            <p>ISBS has membership with Bodies representing the Industry and has successfully collaborated with them in various
                                initiatives.</p>
                            <p><b>Some of these successful collaborations are</b></p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="reg1">
                                        <li>Maratha Chamber of Commerce Industries & Agriculture
                                            (MCCIA), Pune</li>
                                        <li>Federation of Indian Chamber of Commerce & Industry
                                            (FICCI), New Delhi </li>
                                        <li>Confederation of Indian Industries Young Indians, (CII-Yi), Pune</li>
                                        <li>National Human Resource Development (NHRD), Pune
                                            Chapter</li>
                                        <li>Principles of Responsible Management Education
                                            (PRME) </li>
                                        <li>Principles of Responsible Management Education
                                            (PRME) </li>
                                        <li>National Entrepreneurship Network (NEN)</li>
                                        <li>Pune Management Association (PMA)</li>
                                        <li>Microsoft</li>
                                        <li>Abu Dhabi University</li>
                                        <li>ESDES University-France</li>
                                        <li>Mission Apollo</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="reg1">
                                        <li>International Business Simulations (iBizSim)</li>
                                        <li>Stratecent Consulting</li>
                                        <li>Ozone Education Consultants Pvt. Ltd.</li>
                                        <li>ICICI Securities Ltd</li>
                                        <li>Pragati Foundation (NGO) </li>
                                        <li>lncuCapital</li>
                                        <li>Enactus, Nottingham, UK</li>
                                        <li>Synechron Technologies Pvt. Ltd</li>
                                        <li>HDFC Bank</li>
                                        <li>ICICI Securities Ltd </li>
                                        <li>Deloitte </li>
                                        <li>Aditya Birla Memorial Hospital</li>
                                        <li>All India Management Association (AIMA)</li>
                                        <li>Association of Indian Management Schools (AIMS)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc16Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc16Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Industry Visits</h4>
                            <p>ISBS imparts training to students by organising visits to the industry. The value addition comes by way of observing the
                                processes within organizations and discussions with representatives of the organization. Such experiences have a lasting
                                impact in the memory of students. Following companies have been visited by our students in the foregone years.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="reg1">
                                        <li>Maratha Chamber of Commerce Industries & Agriculture</li>
                                        <li>Piaggio Vehicles Pvt. Ltd</li>
                                        <li>Universal Construction</li>
                                        <li>United Breweries Ltd</li>
                                        <li>Brintons Carpet Asia Pvt. Ltd.</li>
                                        <li>Praj Industries</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="reg1">
                                        <li>Kalyani Steel</li>
                                        <li>Honeywell Turbo Technologies</li>
                                        <li>General Motors India Pvt. Ltd</li>
                                        <li>Kalyani Maxion wheel</li>
                                        <li>Forbes Marshall</li>
                                        <li>Mecc Alte India</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc17Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc17Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Massive Open Online Courses (MOOCS)</h4>
                            <p>ISBS integrated MOOCs - Massive Online Open Courses in the curriculum. ISBS offer few MOOCs as a compulsory module
                                to students along with certain MOOCs of their choice. MOOC's are a new model for online courses that have quickly
                                gained interest and support in higher education worldwide. MOOCs allow students to complete full courses of study in a
                                non conventional format, a certificate for course completion integrated into programs ISBS already offers. MOOCs are
                                technologies involved courses, which includes video lectures, discussion forums, tests, and assignments. MOOCs are built
                                on efficiency of scale, giving access to the teaching of an excellent professor to thousands of students at once. The
                                lectures, assessments and activities for a course are all conducted online. A MOOG throws open the doors of the
                                professor's classroom, allowing them to teach more than just a few students at a time. </p>
                            <p>Considering the global demand for online learning and the rapid rise in the number of Indian students enrolling for
                                MOOCs, it is clear that the interest in MOOCs will continue to grow in India. The AICTE has also been advocating
                                incorporating MOOCs in technical courses through "Swayam" Portal. ISBS offers few MOOCs as a compulsory module to
                                students along with certain MOOCs of their choice.
                            </p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc18Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc18Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Students Mentoring</h4>
                            <p>ISBS firmly believes in moulding future managers by providing personalized guidance in their development. Towards this
                                end, a mentoring program has been institutionalized to counsel them on the key attributes to become effective managers
                                and future leaders. The program also covers issues connected with the recruitment and selection process of managers
                                and how the students can confidently face such processes.
                            </p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc19Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc19Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Simulation Exercises</h4>
                            <p>ISBS conducts Simulation Exercise in association with The Learning Curve Academy. The students learn about financial
                                planning by playing an Online simulation game and attending classroom sessions. The Finance GYM game is an online
                                interactive game, in which the students create and manage a virtual portfolio with real investment products to achieve the
                                financial goals they have been given. By playing this game, they learn the basics of financial planning, including basic
                                financial terminology, tax planning and insurance. Simulation Exercises are also conducted as part of the teaching
                                pedagogy of marketing subjects using Harvard Business Review.

                            </p>
                        </div>
                    </div>
                    <div className="row padding15px">
                        <div className="col-md-2 positionAbsolute mobHide">
                            <img src={sc20Image} alt="" className="circleAboutIcon" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 whiteBgWithShadow paddingCommonbox">
                            <img src={sc20Image} alt="" className="circleAboutIconMob1 mobShow" />
                            <h4 className="paddingTop">Incubation Cell</h4>
                            <p>Existing students with Business Plans identified with the potential to get converted to start-up companies find a
                                conducive platform at ISBS at pre-revenue stage to get incubated in the Institute and develop their product prototypes
                                and business models before they take it forward as a fully functional small enterprise. ISBS offers students the benefit of
                                available infrastructure, active mentoring and guidance, access to business networks and other resources from the
                                Institute.


                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default StudentEngagement
import React, { Fragment } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/alumni/association.jpg"
import MissionImage from "../../assets/images/about/mission.png"
import VisionImage from "../../assets/images/about/vision.png"
import FormedImage from "../../assets/images/alumni/formed.png"
import RegistredImage from "../../assets/images/alumni/registred.png"

const AlumniAssociation = () => {
    return (
        <Fragment>
            
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#ffffff', textAlign:"left", paddingLeft:'6%'}}>ALUMNI ASSOCIATION</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">ABOUT US &#62; <span> ALUMNI ASSOCIATION</span></h3>
                    </div>
                </div>
            </div>
            <div className="backgroundGrey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={MissionImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox lightPinkBackground">
                                <img src={MissionImage} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h3 className="paddingTop">VISION</h3>
                                    <ul>
                                        <li>A respectful, congenial, and equitable program that is welcoming and engaging for alumni of all ages.</li>
                                        <li>A dynamic community of active alumni who contribute to the goals of the program, feel ownership over its
                                            direction, and act as ambassadors for ISBS.</li>
                                        <li>A program with strong ties to industry and spin-out companies, and the ability of promote mentoring,
                                            internship and career opportunities for students and alumni in management and other fields.</li>
                                        <li>A commitment to transparency of goals and actions</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute  mobHide">
                                    <img src={VisionImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox lightBlueBackground">
                                <img src={VisionImage} alt="" className="circleAboutIconMob1 mobShow" />
                                    <h3 className="paddingTop">MISSION</h3>
                                    <p className="paddingTop">To develop and strengthen ties between our Alumni and ISBS by providing diverse tangible benefits including
                                        career services, networking opportunities, special events and lectures, and the opportunity to connect with and
                                        inspire MBA students and, to engage Alumni in future goals of ISBS, in order that each individual feels welcome
                                        and valued as a member of Indira School of Business Studies.</p>
                                </div>
                            </div>
                            <br />

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 somi">
                        <h3 className="blockTitle text-center">Alumni Association Inception and Registration Date</h3>
                    </div>
                    <div className="col-md-12 padding5px">
                        <div className="col-md-12 marginBottom2em">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-5 col-6 positionRelative">
                                    <div className="row">
                                        <div className="col-md-4 col-8 positionRelative">
                                            <div className="col-md-12 positionRelative">
                                                <img src={FormedImage} alt="" className="imgRes" />
                                            </div>
                                        </div>
                                        <div className="col-md-8 positionRelative">
                                            <h3 className="blockTitle"><b>Duly Formed: Yes</b></h3>
                                            <h3 className="blockTitle">Date: 05 /10/10</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-6 positionRelative">
                                    <div className="row">
                                        <div className="col-md-4 col-8 positionRelative">
                                            <div className="col-md-12 positionRelative">
                                                <img src={RegistredImage} alt="" className="imgRes" />
                                            </div>
                                        </div>
                                        <div className="col-md-8 positionRelative">
                                            <h3 className="blockTitle"><b>Duly Registered: Yes</b></h3>
                                            <h3 className="blockTitle">Date: 20/06/11</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AlumniAssociation
import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/placement/placement-cell.png"
import MadhuriImage from "../../assets/images/placement/madhuri.png"
import Placement1Image from "../../assets/images/placement/placement-1.png"

const PlacementCell = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout leftAlignBannerTxt whiteText">Placement Cell</h2>
            </div>
            <div className="container">
                <div className="row marginBottom3em">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Placement &#62; <span> Placement Cell</span></h3>
                    </div>
                    <div className="col-md-12">
                        <p>Do not let what you cannot do interfere with what you can do - the great words by John Wooden motivates us at Indira School of Business
                            Studies to improve our placement records year after year.</p>
                        <p>Indira Group of Institutes has a central Corporate Relations cell headed by an Executive Director - Corporate Relations. The cell is a hub which
                            aims to provide suitable career and training guidance to students according to their interests and capabilities. The institute aims in fully
                            assisting students for Summer Internship Program in the Industry as well as Final Placements by arranging campus interviews for recruitment
                            in various corporate houses in the public and private sectors and several other MNCs. Through our Corporate Relations Cell, Indira Aims to
                            build a bridge for smooth transition of students from academics to economically independent citizens.
                        </p>
                        <p>Placement cell at Indira School of Business Studies frequently interacts with the corporate world, thereby developing and maintaining
                            symbiotic relations. It undertakes various activities related to campus placement for final year students, summer internship training for the first
                            year students, live projects and interactive and training sessions for students.The emphasis is also laid on summer internships and final
                            placements overseas. On an average 500+ companies visit Indira Campus for placements.</p>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row paddingBottom3em">
                        <div className="col-md-12">
                            <h3 className="blockTitle">Director's Message</h3>
                        </div>
                        <div className="col-md-12 borderimg2" style={{ background: '#fff' }}>
                            <div className="row">
                                <div className="col-md-3">
                                    <img src={MadhuriImage} alt="" className="imgRes paddingTop" />
                                    <br/>
                                    <br/>
                                    <p><b>Ms. Madhuri Sathe</b></p>
                                    <p className="text-left">Executive Director Corporate Relations</p>
                                </div>
                                <div className="col-md-9 paddingTop mpaddingTop0">
                                    <p>Dear Corporate Partner,</p>
                                    <p>At the outset, I would like to thank you for your continuous support and belief in the Indira
                                        Group of Institutes' students over the years. I feel very happy that our students have justified
                                        their selection and are performing well in the companies that put faith in them. The pandemic
                                        has thrown different challenges at us. Not only do the young aspirants have to show resilience
                                        and develop technological skills, but they will need to show the willingness to develop the
                                        ability to adapt to the changing work environment. They will have to learn to cope with change
                                        and the unknown. They will have to prepare for the new environment. It will be important for
                                        students to adjust their own attitudes and expectations in accordance to the circumstances
                                        and business environment.</p>
                                    <p>Through regular interactions with the students, the awareness about the changed
                                        environment has been constantly ingrained in the students. Indiraites are fully aware that to
                                        succeed they will have to learn and relearn to suit the business demands. I would like to invite
                                        you for the placement season for our batch of 2019-21. I can assure you that you will get
                                        students who have the ability to face the turbulence, have the skills to perform and the
                                        adaptability to change.</p>
                                    <p>I would like to welcome you to our campus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 somi paddingTop">
                        <img src={Placement1Image} alt="" className="imgRes paddingTop" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PlacementCell
import React, { Fragment, useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Header from "../../components/Header";
import BannerImage from "../../assets/images/ivyHostelImg/ivyHostelbanner.png"
import Location from "../../assets/images/ivyHostelImg/location.png"
import Hostel1 from "../../assets/images/ivyHostelImg/1.png"
import Hostel2 from "../../assets/images/ivyHostelImg/2.png"
import Hostel3 from "../../assets/images/ivyHostelImg/3.png"
import Hostel4 from "../../assets/images/ivyHostelImg/4.png"
import Hostel5 from "../../assets/images/ivyHostelImg/5.png"
import Hostel6 from "../../assets/images/ivyHostelImg/6.png"
import Hostel7 from "../../assets/images/ivyHostelImg/7.png"
import Hostel8 from "../../assets/images/ivyHostelImg/8.png"
import Typeofroom from "../../assets/images/ivyHostelImg/typeofroom.png"
import Bed from "../../assets/images/ivyHostelImg/bed.png"
import Roomivy1 from "../../assets/images/ivyHostelImg/roomivy2.png"
import Roomivy2 from "../../assets/images/ivyHostelImg/roomivy3.png"
import FB from "../../assets/images/ivyHostelImg/ivyfb.png"
import Insta from "../../assets/images/ivyHostelImg/ivyinsta.png"
import LinkedIn from "../../assets/images/ivyHostelImg/ivylinkin.png"
import Twitter from "../../assets/images/ivyHostelImg/ivytw.png"
import "./style.css"
import { CarouselItem } from "react-bootstrap";
const IvyLeagueHouse = () => {
const [about, setAbout] = useState(false)
useEffect(() => {
window.scrollTo(0, 0)
}, [])
return (
<Fragment>
   <Header></Header>
   <div className="col-md-12  somi">
      <img src={BannerImage} className="img_res" />
      <div className="locationbox">
         <span>  <img src={Location} className="locatiomimg" /></span>
         <span className="textbox">
         <span className="fontbanner">Pune, Tathawade </span><span className="fontbanner1"> 706 Beds</span>
         </span>
      </div>
   </div>
   <div className="clearfix"></div>
   <div className="col-md-12 paddingclassHostel bg1 ">
      <div className="container">
         <div className="row">
            <div className="col-md-5 col-12">
               <p className="para">Ivy League House, Tathawade, is our first and flagship premium student 
                  accommodation property, conveniently located in the middle 
                  of Tathawade and surrounded by some of Pune’s leading educational institutions.
               </p>
               <p className="para">It is a 706 bed property with options of Standard and AC rooms, across twin, triple and quad rooms. It has separate blocks for boys and girls. This international student living experience also has the finest 
                  of amenities and spaces which include an open air Skydeck, outdoor play areas and services such as housekeeping, laundry and food.
               </p>
               <p className="para"><b>Institutes near Ivy League House:</b></p>
               <ul>
                  <li className="liclass">JSPM</li>
                  <li className="liclass">DY Patil Institute</li>
                  <li className="liclass">Indira College &amp; Balaji University</li>
               </ul>
            </div>
            <div className="col-md-7 col-12">
               <Carousel>
                  <CarouselItem>
                     <img src={Hostel1} />
                  </CarouselItem>
                  <CarouselItem>
                     <img src={Hostel2} />
                  </CarouselItem>
                  <CarouselItem>
                     <img src={Hostel3} />
                  </CarouselItem>
                  <CarouselItem>
                     <img src={Hostel4} />
                  </CarouselItem>
                  <CarouselItem>
                     <img src={Hostel5} />
                  </CarouselItem>
                  <CarouselItem>
                     <img src={Hostel6} />
                  </CarouselItem>
                  <CarouselItem>
                     <img src={Hostel7} />
                  </CarouselItem>
                  <CarouselItem>
                     <img src={Hostel8} />
                  </CarouselItem>
               </Carousel>
            </div>
         </div>
      </div>
   </div>
   <div className="clearfix"></div>
   <div className="col-md-12 paddingclassHostel bg2 ">
      <div className="row">
         <div className="col-md-6  somi">
            <img src={Typeofroom} className="img_typeofrrom" />
            <img src={Bed} className="img_res" />
         </div>
         <div className="col-md-6 roompaddingbox marginroombox">
            <h2 className="fontbig2">ILH DOS - TWIN ROOM</h2>
            <p className="para">Dos is our double-sharing room for bffs who are inseparable!</p>
            <ul className="ultopmargin">
               <li className="liclass">Vice-captain height dorm beds inspired by international dorms</li>
               <li className="liclass">Dedicated wardrobe &amp; study space for each resident</li>
               <li className="liclass">6-8 inch branded pocket-spring mattresses for extra comforty</li>
               <li className="liclass">En-suite bathrooms</li>
            </ul>
            <div className="row">
               <div className="col-md-6 ultopmargin">
                  <p className="para2ivy" style={{color:"rgb(71, 148, 112)"}}>ILH Dos - Standard</p>
                  <p className="para2ivy">Fee per bed: Rs. 13,500/-</p>
                  <p className="para2ivy">Early Admission Fee: Rs. 12,000/-</p>
               </div>
               <div className="col-md-6 ultopmargin">
                  <p className="para2ivy" style={{color:"rgb(71, 148, 112)" }} >ILH Dos - AC</p>
                  <p className="para2ivy">Fee per bed: Rs. 16,000/-</p>
                  <p className="para2ivy">Launching Soon</p>
               </div>
            </div>
         </div>
         <div className="clearfix"></div>
         <div  className="col-md-6 somi ivyshowmob">
            <img src={Roomivy1} className="img_res" />
         </div>
         <div className="col-md-6  roompaddingbox">
            <h2 className="fontbig2">ILH TRES - TRIPLE ROOM</h2>
            <p className="para">Tres is our triple-sharing room for those who hang out in threes.</p>
            <ul className="ultopmargin">
               <li className="liclass">Our cosiest rooms that will make you feel right at home</li>
               <li className="liclass">Dedicated wardrobe &amp; study space for each resident</li>
               <li className="liclass">6-8 inch Branded pocket-spring mattresses for extra comfort </li>
               <li className="liclass">En-suite bathrooms</li>
            </ul>
            <div className="row">
               <div className="col-md-6 ultopmargin">
                  <p className="para2ivy" style={{color:"rgb(71, 148, 112)"}}>ILH Tres - Standard</p>
                  <p className="para2ivy">Fee per bed: Rs. 11,000/-</p>
                  <p className="para2ivy">Early Admission Fee: Rs. 9,000/-</p>
               </div>
               <div className="col-md-6 ultopmargin">
                  <p className="para2ivy" style={{color:"rgb(71, 148, 112)"}}>ILH Tres - AC</p>
                  <p className="para2ivy">Fee per bed: Rs. 15,000/-</p>
                  <p className="para2ivy">Launching Soon</p>
               </div>
            </div>
         </div>
         <div className="col-md-6 col-12 ivyshowdesk somi">
            <img src={Roomivy1} className="img_res" />
         </div>
         <div className="clearfix"></div>
        
         <div className="col-md-6 col-12 somi">
            <img src={Roomivy2} className="img_res" />
         </div>
         <div className="col-md-6 col-12 roompaddingbox">
            <h2 className="fontbig2">ILH QUATRO - QUAD ROOMS</h2>
            <p className="para">Quatro is our quad sharing room for those who love staying with their squad.</p>
            <ul className="ultopmargin">
               <li className="liclass">Luxury bunkbeds for extra privacy</li>
               <li className="liclass">Dedicated wardrobe &amp; study space for each resident</li>
               <li className="liclass">6-8 inch Branded pocket-spring mattresses for extra comfort</li>
               <li className="liclass">En-suite bathrooms</li>
            </ul>
           
            <div className="col-md-6  col-12 ultopmargin">
               <p className="para2ivy" style={{color:"rgb(71, 148, 112) !important"}}>ILH Quad - AC</p>
               <p className="para2ivy">Fee per bed: Rs. 10,000/-</p>
               <p className="para2ivy">Yet to be launched</p>
            </div>
            </div>
         <div className="clearfix"></div>
      </div>
   </div>
   <div className="clearfix"></div>
   <div className="col-md-12 somi ivyshowdesk">
      <div>
         <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d60494.59917133257!2d73.75231387497213!3d18.623007311336625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d18.6019695!2d73.7595528!4m5!1s0x3bc2b9001189b647%3A0x1b4d17932be7f5b2!2sJRM9%2BPWX%20Pimpri-Chinchwad%2C%20Maharashtra%2C%20Ratan%20Residency%2C%20Nehru%20Rd%2C%20Sukhwani%2C%20Udhyamnagar%2C%20Pimpri%20Colony%2C%20Pimpri-Chinchwad%2C%20Maharashtra%20411018!3m2!1d18.634327199999998!2d73.81985379999999!5e0!3m2!1sen!2sin!4v1651299742508!5m2!1sen!2sin" width="100%" height="350" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
   </div>
   <div className="col-md-12 somi ivyshowmob">
      <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d60494.59917133257!2d73.75231387497213!3d18.623007311336625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d18.6019695!2d73.7595528!4m5!1s0x3bc2b9001189b647%3A0x1b4d17932be7f5b2!2sJRM9%2BPWX%20Pimpri-Chinchwad%2C%20Maharashtra%2C%20Ratan%20Residency%2C%20Nehru%20Rd%2C%20Sukhwani%2C%20Udhyamnagar%2C%20Pimpri%20Colony%2C%20Pimpri-Chinchwad%2C%20Maharashtra%20411018!3m2!1d18.634327199999998!2d73.81985379999999!5e0!3m2!1sen!2sin!4v1651299742508!5m2!1sen!2sin" width="100%" height="200" style={{ border: "0" }}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
   </div>
   <div className="col-md-12 paddingclassHostel  bg2 ">
      <div className="container">
         <div className="row">
            <div className="col-md-4 col-6">
               <p>
                  <span><a href="#" target="_blank"><img src={FB} className="imgSocialIcon" /></a></span>
                  <span><a href="#" target="_blank"><img src={Insta} className="imgSocialIcon" /></a></span>
                  <span><a href="#"><img src={LinkedIn} className="imgSocialIcon" /></a></span>
                  <span><a href="#"><img src={Twitter} className="imgSocialIcon" /></a></span>
               </p>
            </div>
            <div className="col-md-4 col-6">
               <p className="liclass">GRAVITAS ET LEVITAS</p>
               <p className="liclass">Happy about being Serious.</p>
               <p className="liclass">Serious about being Happy.</p>
            </div>
            <div className="col-md-4 col-xs-12">
               <a className="liclass" href="mailto:admissions@ivyleaguehouse.com" target="_blank" style={{color: "#fff"}}>admissions@ivyleaguehouse.com</a>
               <p className="para">© 2021 Curated Living Solutions</p>
            </div>
         </div>
      </div>
   </div>
</Fragment>
)
}
export default IvyLeagueHouse
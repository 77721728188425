import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/about/isbs-aboutus-igi-banner.jpg"
import Banner2Image from "../../assets/images/about/igi.jpg"
import Journey1Image from "../../assets/images/about/journey1.png"
import Journey2Image from "../../assets/images/about/journey2.png"
import Journey3Image from "../../assets/images/about/journey3.png"
import Journey4Image from "../../assets/images/about/journey4.png"
import Journey5Image from "../../assets/images/about/journey5.png"
import Journey6Image from "../../assets/images/about/journey6.png"
import Journey7Image from "../../assets/images/about/journey7.png"
import Journey8Image from "../../assets/images/about/journey8.png"
import Journey9Image from "../../assets/images/about/journey9.png"
import Journey10Image from "../../assets/images/about/journey10.png"
import ChetansirImage from "../../assets/images/about/chetansir.jpg"
import TaritamamImage from "../../assets/images/about/taritamam.png"

import College1Image from "../../assets/images/about/icacs.jpg"
import College2Image from "../../assets/images/about/icad.jpg"
import College3Image from "../../assets/images/about/iccs.jpg"
import College4Image from "../../assets/images/about/icem.jpg"
import College5Image from "../../assets/images/about/icp.jpg"
import College6Image from "../../assets/images/about/igbs.jpg"
import College7Image from "../../assets/images/about/igsc.jpg"
import College8Image from "../../assets/images/about/iimpmba.jpg"
import College9Image from "../../assets/images/about/iimppgdm.jpg"
import College10Image from "../../assets/images/about/isbsmba.jpg"
import College11Image from "../../assets/images/about/isbspgdm.jpg"
import College12Image from "../../assets/images/about/igbs.jpg"
import College13Image from "../../assets/images/about/iccs.jpg"
import College14Image from "../../assets/images/about/icem.jpg"
import College15Image from "../../assets/images/about/icad.jpg"
import College16Image from "../../assets/images/about/icacs.jpg"
import College17Image from "../../assets/images/about/isc.jpg"
import College18Image from "../../assets/images/about/igsc.jpg"
import College19Image from "../../assets/images/about/IK.jpg"

import MissionImage from "../../assets/images/about/mission.png"
import VisionImage from "../../assets/images/about/vision.png"


const IndiraGroupOfInstitutes = () => {
    const [about, setAbout] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#ffffff' }}>Indira Group of Institutes</h2>
            </div>
            <div className="container">
                <div className="row marginBottom3em">
                    <div className="col-md-12">
                        <h3 className="blockTitle">About Us &#62; <span> Indira Group of Institutes</span></h3>
                    </div>
                    <div className="mobPad1">
                        <div className="col-md-12 borderimg1">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>Shree Chanakya Education Society’s “Indira Group of Institutes”,
                                        Pune was established in 1994, with the explicit vision to provide
                                        sustainable impetus to the corporate and entrepreneurial abilities
                                        in the youth. The realization that a robust framework of
                                        education is indispensable for the growth of global leaders, the
                                        visionary, Dr. Tarita Shankar, Chairperson, Indira Group,
                                        integrated her pursuit of excellence and articulated educational
                                        institutions, known as Indira Group of Institutes (IGI), which
                                        imbibe human excellence and demonstrate strong social values,
                                        with a single minded pursuit of developing the youth to become
                                        business leaders.</p>
                                    <p className="mobHide">Unrelenting initiatives by the management team at Indira led to
                                        adding of multi-disciplines such as Management, Information
                                        Technology, Pharmacy, Commerce, Science, Engineering and
                                        Mass Communication to the group and growth has been as deep
                                        as it has been wide. With 12 institutions, IGI now offers
                                        education right from Kindergarten to Doctorate level studies. IGI
                                        believes in the economic independence of individuals. In this
                                        endeavor we aim to inspire our post graduate students to create
                                        a niche for themselves in the Indian Economic order first, and
                                        then compete globally. We owe our gratitude to the Indian
                                        Industry for consistently collaborating with us in this endeavor.
                                        Renowned industrialists have visited us and tremendously
                                        enhanced the morale of our students by sharing their vast
                                        knowledge and experience about entrepreneurial opportunities
                                        and foster a spirit of entrepreneurship amongst students.</p>
                                    <div className="mobShow">
                                        {about &&
                                            <p>Unrelenting initiatives by the management team at Indira led to
                                                adding of multi-disciplines such as Management, Information
                                                Technology, Pharmacy, Commerce, Science, Engineering and
                                                Mass Communication to the group and growth has been as deep
                                                as it has been wide. With 12 institutions, IGI now offers
                                                education right from Kindergarten to Doctorate level studies. IGI
                                                believes in the economic independence of individuals. In this
                                                endeavor we aim to inspire our post graduate students to create
                                                a niche for themselves in the Indian Economic order first, and
                                                then compete globally. We owe our gratitude to the Indian
                                                Industry for consistently collaborating with us in this endeavor.
                                                Renowned industrialists have visited us and tremendously
                                                enhanced the morale of our students by sharing their vast
                                                knowledge and experience about entrepreneurial opportunities
                                                and foster a spirit of entrepreneurship amongst students.</p>
                                        }
                                        <span className="readButton" onClick={() => setAbout(!about)}>{about ? "Read Less" : "Read More"}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img src={Banner2Image} alt="" className="imgRes" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row marginBottom3em">
            <div className="col-md-12 whiteBgWithShadow">
                        <div className="row padding15px">
                            <div className="col-md-2 positionAbsolute mobHide">
                                <img src={MissionImage} alt="" className="circleAboutIcon" />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-11 whiteBgWithShadow paddingCommonbox lightPinkBackground">
                                <img src={MissionImage} alt="" className="circleAboutIconMob1 mobShow" />
                                <h3 className="paddingTop">VISION</h3>
                                {/*<p className="paddingTop">To be a front runner in providing refined education system to create visionary professionals and entrepreneurs
                                    enriched with innovation and leadership through integration of teaching and learning, advancement of knowledge,
                                    education and research fostering an equitable and productive growth in the complex global society.</p>*/}
                                    <p className="paddingTop">“To create a centre of academic excellence in the field
                                            of Management and IT education, with the purpose
                                            of fulfilling the industry requirements through holistic
                                            development of future performers who are also good
                                            human beings and possess the right knowledge, skill sets
                                            and attitude towards their work and life.”</p>
                            </div>
                        </div>
                        <div className="row padding15px">
                            <div className="col-md-2 positionAbsolute mobHide">
                                <img src={VisionImage} alt="" className="circleAboutIcon" />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-11 whiteBgWithShadow paddingCommonbox lightBlueBackground">
                                <img src={VisionImage} alt="" className="circleAboutIconMob1 mobShow" />
                                <h3 className="paddingTop">MISSION</h3>
                               {/*} <p className="paddingTop">To be a front runner in providing refined education system to create visionary professionals and entrepreneurs
                                    enriched with innovation and leadership through integration of teaching and learning, advancement of knowledge,
                                    education and research fostering an equitable and productive growth in the complex global society.</p>*/}
                                    <p className="paddingTop">To promote a learning environment that welcomes
                                        and honors men and women from diverse cultures for
                                        involving themselves in intellectual inquisitiveness, and
                                        explore knowledge dimensions for future applications in
                                        industry, business and life.</p>
                                    <ul className="liVM">
                                <li>To develop managers and entrepreneurs in the field of
                                    management and IT, who can serve as engines of
                                    national and global economic growth and innovation.</li>
                                <li>To foster strategic alliances with industry for research and its application.</li>
                                <li>To inculcate ethical, social and moral values in all our
                                    stakeholders which is the basis of humane social order</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
            <div className="backgroundGrey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="blockTitle marginBottom2em">Having traversed the journey of twenty-five years we have ourselves made certain significant achievements which,
                                we are aware, are but the building blocks for achieving further heights.</h3>
                        </div>
                        <div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey1Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-9 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">Indira is ranked amongst the top 2 B-schools
                                                in Pune, by the prestigious AIMA - IMJ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey2Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">The same journal gave us a top 30, all India
                                                ranking on the parameters of Industry
                                                interface.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey3Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-9 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">Our students have shown good academic
                                                results and have been at the level of
                                                university ranking</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey4Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">The achievements of our students have
                                                enabled them to attract some of the best
                                                corporate recruiters.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey5Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-9 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">Indira Group of Institutes and our goodwill
                                                has enabled us to establish campus
                                                agreement with Microsoft for using different
                                                software</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey6Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">Our goodwill with Microsoft was further
                                                accentuated with Microsoft conducting
                                                workshops at Indira, for train the trainer
                                                modules.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey7Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-9 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">Business India has also ranked us amongst
                                                the top 3 educational institute in Pune from
                                                2 consecutive years</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey8Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">Our Institutes have received accreditation
                                                from various certifying bodies</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey9Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-9 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">Indira Institute of Management was the
                                                first management institute in Maharashtra
                                                to receive the accreditation from the NBA,
                                                New Delhi.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 marginBottom2em">
                            <div className="mobPad1">
                                <div className="positionRelative">
                                    <div className="row">
                                        <div className="col-md-2 positionAbsolute positionInheritMob">
                                            <img src={Journey10Image} alt="" className="circleJourneySmall" />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10 whiteBgWithShadow box1MinHeight paddingLeft15px paddingLeft70pxImp">
                                            <p className="paddingTop">We also have our audiovisual centers
                                                supported by Harvard Business School ,
                                                Stanford, Pyramid Media and Tom Peters
                                                Company and we have vast collection of titles.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Chairperson's Message</h3>
                    </div>
                    <div className="col-md-12 boxShadow">
                        <div className="row">
                            <div className="col-md-3">
                                <img src={TaritamamImage} alt="" className="imgRes paddingTop" />
                                <p><b>Dr. Tarita Shankar</b></p>
                                <p className="Textleft">Founder Secretary & Chief Managing
                                    Trustee</p>
                            </div>
                            <div className="col-md-9 paddingTop">
                                <p>Dear Students</p>
                                <p>Pursuit of Management Education at Indira Group of Institutes has been an inspiring journey.
                                    With over two decades of existence, and shortly closing on twenty five years of celebrating
                                    education, Indira strongly believes in the power of education to transform the youth. The
                                    philosophy, governance and values of business are witnessing a sea change and plenty of its
                                    repercussions are already being witnessed across the globe. Indira embraces these changes
                                    and epitomizes matching innovation in curriculum. Such transformational efforts lead our
                                    aspiring managers to become professionals who develop capabilities to lead in dynamic
                                    environment.</p>
                                <p>Quality being the bottom line for every industry, Indira has never lost sight of the fact that for
                                    India to forge ahead in its drive towards economic supremacy in the world, it is the quality of
                                    our people who drive it that matters most. And quality, as the cliché goes, is never an accident
                                    but an article of faith, and the entire faculty team at Indira embodies such dedication to quality.
                                    The Indira Group has proven itself on this count and has no intention of resting on its laurels.</p>
                                <p>Indira offers holistic and transformative learning experience, designed and delivered by experts
                                    from industry and academia. While the curriculum keeps the programs contextually abreast,
                                    innovative pedagogies inspire application oriented learning.</p>
                                <p>Indira strives to create good human beings and not just professionals. With the confidence that
                                    our intentions have matched our efforts, I wish you the very best in the future.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <div className="row marginBottom3em">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Group Director's Message</h3>
                    </div>
                    <div className="col-md-12 boxShadow" style={{ background: '#e7e9e9' }}>
                        <div className="row">
                            <div className="col-md-3">
                                <img src={ChetansirImage} alt="" className="imgRes paddingTop" />
                                <p><b>Chetan Wakalkar</b></p>
                                <p>Group Director & Managing Trustee
                                    Indira Group of Institutes, Pune</p>
                            </div>
                            <div className="col-md-9 paddingTop">
                                <p>Dear Aspiring Leaders,</p>
                                <p>These are exciting times for us as a nation, for we march confidently ahead to make an impact
                                    on the world as a global leader. In order to achieve this, our government has taken
                                    evolutionary reforms in the form of demonetization, recapitalization of public sector banks and
                                    the implementation of the much awaited GST bill. This along with better governance and a
                                    more transparent decision making system is making India an attractive destination for many
                                    global MNC s. The World Banks ‘Doing Business Report’ 2018 ranked India at 100, which is a
                                    jump of nearly 30 places from its earlier 130 ranking. This would surely give an impetus to our
                                    governments ‘Make in India’ and ‘Start-up India ‘initiatives, spurring greater entrepreneurship
                                    and hence employability. At the same time the pace of change is tremendous and technology
                                    is driving this in a big way. Industry 4.0 , the block chain economy , crypto currency are just
                                    some of the disruptions that are challenging the way business is conducted today</p>
                                <p>We at ISBS will prepare you to be the leaders that India needs to emerge as a world economic
                                    power.</p>
                                <p>Each one of you has the potential to be a champion and a winner. ISBS with its unique and
                                    contemporary curriculum, innovative teaching methodology and experienced academic team,
                                    provide you the best launch pad for your dream careers. Extensive industry exposure through
                                    our various platforms and a highly dedicated corporate relations team help to attract some of
                                    the best recruiters for you. Come be a part of the future – as responsible citizens of a great
                                    Nation.</p>
                                <p>Let ISBS show you the way!</p>
                                <p>Best always</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="backgroundGrey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 somi">
                            <h3 className="blockTitle marginBottom2em">Institutes under <span>Indira Group (IGI)</span></h3>
                        </div>

                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College8Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira Institute of
                                                    Management
                                                    Pune (IIMP)</b></p>
                                                <p className="font12 Textleft">www.indiraiimp.edu.in</p>
                                                <button className="buttonStyle1"><a href="http://indiraiimp.edu.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College9Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira Institute of Management PGDM (IIMP)</b></p>
                                                <p className="font12 Textleft">www.indiraiimppgdm.
                                                    edu.in/</p>
                                                <button className="buttonStyle1"><a href="https://indiraiimppgdm.edu.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College5Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira College of
                                                    Pharmacy
                                                    (ICP)</b></p>
                                                <p className="font12 Textleft">www.indiraicp.edu.in</p>
                                                <button className="buttonStyle1"><a href="https://indiraicp.edu.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College10Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira School of Business Studies PGDM</b></p>
                                                <p className="font12 Textleft">www.indiraisbs.ac.in</p>
                                                <button className="buttonStyle1"><a href="https://indiraisbs.ac.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College11Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira School of Business studies (ISBS)</b></p>
                                                <p className="font12 Textleft">www.indiraisbs.edu.in</p>
                                                <button className="buttonStyle1"><a href="https://indiraisbs.edu.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College12Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira Global Business School (IGBS)</b></p>
                                                <p className="font12 Textleft">www.indiragbs.edu.in</p>
                                                <button className="buttonStyle1"><a href="https://indiragbs.edu.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College13Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira College of Commerce & Science (ICCS)</b></p>
                                                <p className="font12 Textleft">www.iccs.ac.in</p>
                                                <button className="buttonStyle1"><a href="https://iccs.ac.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College14Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira College of Engineering & Management (ICEM)</b></p>
                                                <p className="font12 Textleft">www.indiraicem.ac.in</p>
                                                <button className="buttonStyle1"><a href="https://indiraicem.ac.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College15Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira College of Architecture & Design (ICAD)</b></p>
                                                <p className="font12 Textleft">www.indiraicad.ac.in</p>
                                                <button className="buttonStyle1"><a href="http://indiraicad.ac.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College16Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira Collage of Arts, Commerce and Science (ICACS)</b></p>
                                                <p className="font12 Textleft">www.icacs.indiraedu.com</p>
                                                <button className="buttonStyle1"><a href="https://icacs.indiraedu.com/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College17Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira School of Communication (ISC)</b></p>
                                                <p className="font12 Textleft">www.indiraisc.edu.in</p>
                                                <button className="buttonStyle1"><a href="https://indiraisc.edu.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 padding5px">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College18Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira Global Study Centre(IGSC)</b></p>
                                                <p className="font12 Textleft">www.indiraigsc.in</p>
                                                <button className="buttonStyle1"><a href="https://www.indiraigsc.in/" target="_blank" style={{color:"#fff"}}>Read More</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                            
                        </div>
                        <div className="col-md-4 padding5px ">
                            <div className="col-md-12 marginBottom2em whiteBgWithShadow borderimg3">
                                <div className="row">
                                    <div className="positionRelative">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={College19Image} alt="" className="imgRes paddingTop paddingBottom" />
                                            </div>
                                            <div className="col-md-6 mobTextCenter">
                                                <p className="font14 Textleft"><b>Indira National School (Pre-Primary) (Tathawade)</b></p>
                                                <p className="font12 Textleft">www.indiranationalschool.ac.in/pre-primary/</p>
                                                <button className="buttonStyle1"><a href="https://indiranationalschool.ac.in/pre-primary/" target="_blank" style={{color:"#fff"}}>Read More</a></button> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 padding5px">
                           
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default IndiraGroupOfInstitutes
import React, { Fragment } from "react";
import Header from "../../components/Header";
import Carousel from 'react-bootstrap/Carousel'
import BannerImage from "../../assets/images/alumni/alumnicommittee.jpg"

import Bonding from "../../assets/videos/Bonding-at-ISBS.mp4"
import Campuslife from "../../assets/videos/Campus-life-at-ISBS.mp4"
import CulturalActivities from "../../assets/videos/Cultural-activities-at-ISBS.mp4"
import FitnessFreakIndiraites from "../../assets/videos/Fitness-Freak-Indiraites.mp4"
import HolisticDevelopment from "../../assets/videos/Holistic-Development-at-ISBS-1.mp4"
import Placement from "../../assets/videos/Placement-readiness.mp4"
import WeatISBS from "../../assets/videos/We-at-ISBS.mp4"
import Sport from "../../assets/videos/Sport.mp4"

const LifeAtISBS = () => {
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#fff', textAlign: "right", paddingRight: '5%' }}>Video Gallery</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Student Life At ISBS &#62; <span>Video Gallery </span></h3>
                    </div>
                </div>
            </div>
            <div className="">

         
                <div className="col-md-12" style={{marginTop:"2em"}}>
                   <div className="container">
                     <div className="row">
                  
                    <div className="row">
                    <div className="col-md-6">
                               
                                <video width="100%" height="315" controls>
                                <source src={Bonding} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Bonding at ISBS</h4>
                           </div>  
                           <div className="col-md-6">
                           <video width="100%" height="315" controls>
                                <source src={Campuslife} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Campus life at ISBS </h4> 
                           </div>
                        </div> 
                        <hr/>
                    <div className="row">
                    <div className="col-md-6">
                    <video width="100%" height="315" controls>
                                <source src={CulturalActivities} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Cultural Activities at ISBS</h4>
                           </div>  
                           <div className="col-md-6">
                           <video width="100%" height="315" controls>
                                <source src={FitnessFreakIndiraites} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Fitness Freak Indiraites</h4>
                           </div> 
                        </div>
                        <hr/>
                    <div className="row">
                    <div className="col-md-6">
                    <video width="100%" height="315" controls>
                                <source src={HolisticDevelopment} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Holistic Development at ISBS</h4>
                           </div>  
                           <div className="col-md-6">
                                
                                <video width="100%" height="315" controls>
                                <source src={Placement} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">Placement Readiness</h4>
                           </div>
                           </div>
                           <hr/>
                    <div className="row">
                    <div className="col-md-6">
                              
                                <video width="100%" height="315" controls>
                                <source src={WeatISBS} type="video/mp4" />
                                
                                </video>
                                <h4 className="blockTitle">We at ISBS </h4>
                           </div>
                           <div className="col-md-6">
                               
                                <video width="100%" height="315" controls>
                                <source src={Sport} type="video/mp4" />
                                
                                </video> 
                                <h4 className="blockTitle">Sport</h4>
                           </div> 
                           </div>
                  <hr/>
                        </div>
                   </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LifeAtISBS
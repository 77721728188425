import React, { Fragment, useState, useRef, useEffect } from "react";
import LogoMainImage from "../assets/images/logo-main.png"
import Banner6Image from "../assets/images/home-banner6.jpg"
import NIRF from './../assets/pdf/Filled survey - NIRF.pdf'
import NBA from '../assets/pdf/NBA-Accreditation-ISBS.pdf'
import Bonding from "../assets/videos/MBA-PLACEMENT.mp4"

import FooterLogo from "../assets/images/footer-logo.png"
import FacebookLogo from "../assets/images/home-ico/fb.png"
import TwitterLogo from "../assets/images/home-ico/twitter.png"
import InstaLogo from "../assets/images/home-ico/insta.png"
import BlogLogo from "../assets/images/home-ico/blog.png"
import Event1 from "../assets/images/event/mdp-img.png"
import Event2 from "../assets/images/event/Alumni-talk.png"
import Event3 from "../assets/images/event/isbs-news-5.jpg"
import Event4 from "../assets/images/event/yoga_day.png"
import Event5 from "../assets/images/event/event1-2022.jpg"
import Event6 from "../assets/images/event/boardRoom.jpg"
import YoutubeLive from "../assets/images/youTubenew.png"
import FacebookLive from "../assets/images/facebooklive.png"


import News1 from "../assets/images/news/NBA_final.png"
import News2 from "../assets/images/news/newsBanner.png"
import News3 from "../assets/images/news/news2022.jpg"
import News4 from "../assets/images/news/news1.jpg"
import News5 from "../assets/images/news/news2.jpg"
import News6 from "../assets/images/news/Data Analytics Series.png"
import News7 from "../assets/images/news/Flyer.jpg"
import Newsprarabha from "../assets/images/news/prarambha.jpg"
import Inductionprogram from "../assets/images/news/10th-induction-program.jpg"
import News6Banner from "../assets/images/news/Data Analytics SeriesBanner.jpeg"
import NISMImg from '../assets/images/news/NISM-Patalganga.jpg'
import AwardImg1 from '../assets/images/news/Best-Business-School-Award-1.jpeg'
import AwardImg2 from '../assets/images/news/Best-Business-School-Award-2.jpeg'
import MdpImg1 from '../assets/images/news/mdp1-2023.jpg'
import MdpImg2 from '../assets/images/news/mdp2-2023.jpg'
import MdpImg3 from '../assets/images/news/mdp3-2023.jpg'
import MdpImg4 from '../assets/images/news/mdp4-2023.jpg'
import ISBSAutonomouspostImg from '../assets/images/news/ISBS-Autonomous-post.png'

import PrarambhImg from '../assets/images/event/ISBS-prarambh-11.0.jpeg'
import PrarambhVideo from '../assets/videos/Prarambh-11.O.mp4'

import BrandSlamImg1 from '../assets/images/news/IBS-1.jpeg'
import BrandSlamImg2 from '../assets/images/news/IBS-2.jpeg'
import BrandSlamImg3 from '../assets/images/news/IBS-3.jpeg'
import BrandSlamImg4 from '../assets/images/news/IBS-4.jpeg'
import BrandSlamImg5 from '../assets/images/news/IBS-5.jpeg'



import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import "./HomePageMenu.css"
import { NavLink } from "react-router-dom";
const HomePageMenu = (props) => {
    const [height, setHeight] = useState(0)
    const [parentHeight, setParentHeight] = useState(0)
    const ref = useRef(null)
    const parentContainer = useRef(null)
    const [aboutSubMenu, setAboutSubMenu] = useState(false)
    const [coursesSubMenu, setCoursesSubMenu] = useState(false)
    const [coursesSubMbaMenu, setCoursesSubMbaMenu] = useState(false)
    const [coursesSubPhdMenu, setCoursesSubPhdMenu] = useState(false)
    const [placementSubMenu, setPlacementSubMenu] = useState(false)
    const [studentLifeSubMenu, setStudentLifeSubMenu] = useState(false)
    const [newsSubMenu, setNewsSubMenu] = useState(false)
    const [alumniSubMenu, setAlumniSubMenu] = useState(false)
    const [researchSubMenu, setResearchSubMenu] = useState(false)
    const [mdpSubMenu, setMdpSubMenu] = useState(false)
    const [contactSubMenu, setContactSubMenu] = useState(false)
    const [PublicationsSubMenu, setPublicationsSubMenu] = useState(false)

    const [displayMenu, setDisplayMenu] = useState(true)


    const AboutHandler = () => {
        setAboutSubMenu(true)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const CoursesHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(true)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const MbaMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(true)
        setCoursesSubMbaMenu(true)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const PhdMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(true)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(true)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const PlacementMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(true)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const StudentLifeMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(true)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const AlumniMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(true)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const ResearchMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(true)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const MdpMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(true)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const NewsMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(true)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const ContactMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(true)
        setPublicationsSubMenu(false)
    }
    const PublicationsMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(true)
    }
    const closeAllMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setNewsSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setContactSubMenu(false)
        setPublicationsSubMenu(false)
    }
    window.addEventListener("scroll", () => {

        if (window.scrollY <= props.divHeight) {
            setDisplayMenu(true)
        }
        else {
            setDisplayMenu(false)
        }
    });

    useEffect(() => {
        setHeight(ref.current.clientHeight)
        setParentHeight(parentContainer.current.clientHeight)
        props.menuHeight(parentContainer.current.clientHeight)
    }, [])
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popupShow, setPopupShow] = useState(false);
    const handleClosePopup = () => setPopupShow(false);
    const handleShowPopup = () => setPopupShow(true);

    const [lgShow, setLgShow] = useState(false);
    const [lgShow1, setLgShow1] = useState(false);
    const [lgShow2, setLgShow2] = useState(false);
    const [lgShow3, setLgShow3] = useState(false);
    const [lgShow4, setLgShow4] = useState(false);
    const [lgShow5, setLgShow5] = useState(false);

    const [lgShowNews, setLgShowNews] = useState(false);
    const [lgShowNews1, setLgShowNews1] = useState(false);
    const [lgShowNews2, setLgShowNews2] = useState(false);
    const [lgShowNews3, setLgShowNews3] = useState(false);
    const [lgShowNews4, setLgShowNews4] = useState(false);
    const [lgShowNews5, setLgShowNews5] = useState(false);
    const [lgShowNews6, setLgShowNews6] = useState(false);
    const [lgShowNewsparabh, setLgShowNewsparabh] = useState(false);
    const [lgShowNewsinduction, setLgShowNewsinduction] = useState(false);
    const [lgShowNewsNISM, setLgShowNewsNISM] = useState(false);
    const [lgShowNewsAward, setLgShowNewsAward] = useState(false);
    const [lgShowNewsMDP, setLgShowNewsMDP] = useState(false);
    const [lgShowPrarambh, setLgShowPrarambh] = useState(false);
    const [lgShowPrarambhVid, setLgShowPrarambhVid] = useState(false);
    const [lgShowISBSAutonomous, setLgShowISBSAutonomous] = useState(false);
    const [lgShowBrandSlam, setLgShowBrandSlam] = useState(false);
    const [lgShowMBAPLACEMENT, setLgMBAPLACEMENT] = useState(false);

    {/*const [lgShowBrandslam, setLgShowBrandslam] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setLgShowBrandslam(true)
        }, 100)
      }, [])*/}


      useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://widgets.in8.nopaperforms.com/emwgts.js";
        script.async = true;
      
        document.body.appendChild(script)
      
      }, []);

    return (
        <Fragment>
            
            <div className="enquireBtn" onClick={handleShowPopup}>Enquire Now</div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Business Leader of the Year 2022</Modal.Title>
                </Modal.Header>
                <Modal.Body>Congratulations to our Chairperson Dr. Tarita Ma'am and Group Director Prof. Chetan Sir for the awards at the Business Leader of the Year 2022 Ceremony.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

{/*-------------news modal-----------------
<Modal
        size="lg"
        show={lgShowBrandslam}
        onHide={() => setLgShowBrandslam(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Indira Brand Slam- Summit & Awards

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News7} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><b>Indira Brand Slam- Summit & Awards</b></p>
        
       <p>The Indira School of Business Studies, will be hosting its flagship event of the year, Indira Brand Slam summit and awards on 10 & 11 February 2023. It will bring together notable business figures from all sectors of the industry to share ideas, perspectives, and insights.</p>
		<p><b>Stay tuned for updates!!</b></p>
        <div className="row">
        <div className="col-md-6 text-left  col-6">
        <a href="https://youtube.com/live/l7wCbzZYgSI?feature=share" target="blank"><img src={YoutubeLive} className="popupImg uTubemarginT"  /></a>
        </div>
        <div className="col-md-6 text-right col-6">
        <a href="https://www.facebook.com/profile.php?id=100064369072860" target="blank"> <img src={FacebookLive} className="popupImg" /></a>
        </div>
        </div>
        </div>
        </div>
        </Modal.Body>
    </Modal>*/}

<Modal
        size="lg"
        show={lgShowMBAPLACEMENT}
        onHide={() => setLgMBAPLACEMENT(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
         <Modal.Header closeButton>
         
        </Modal.Header>
       
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
        <video width="100%" height="315" controls>
                                <source src={Bonding} type="video/mp4" />
                                
                                </video>
      
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p> Are you prepared to mould your success tale? Your launchpad is the Indira School of Business Studies.
</p>
            <p>Join us to realise your full potential and take control of your future. Our students are shining examples of desire and perseverance; they land dream positions and create waves in the business.

At ISBS, we give you the cutting-edge abilities and strong network you need to succeed in the cut throat world of today.</p>
		</div>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShowBrandSlam}
        onHide={() => setLgShowBrandSlam(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
         <Modal.Header closeButton>
         
        </Modal.Header>
       
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
        <Carousel className="marginBottom2em studentC">
          <Carousel.Item>
        <img src={BrandSlamImg1} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={BrandSlamImg2} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={BrandSlamImg3} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={BrandSlamImg4} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={BrandSlamImg5} className="imgRes" /> 
        </Carousel.Item>

          </Carousel>
      
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p>Indira Brand Slam Summit & Awards, 11th Edition: A Makeover of Marketing and Branding in the New Era
</p>
            <p>The Indira Brand Slam Summit & Awards, 11th Edition, explored the latest trends and best practices in marketing and branding in the new era. The event brought together industry stalwarts from a diverse range of industries to share their insights and experiences on how to build and manage strong brands in the digital age.
</p>
<p>The event was a resounding success and it provided attendees with valuable insights and actionable advice on how to do marketing in the new era.
</p>
		</div>
        </div>
        </Modal.Body>
      </Modal>
    <Modal
        size="lg"
        show={lgShowISBSAutonomous}
        onHide={() => setLgShowISBSAutonomous(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
         <Modal.Header closeButton>
         
        </Modal.Header>
       
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
       <img src={ISBSAutonomouspostImg} alt="" className="imgRes" /> 
      
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p>One Big Family, One Incredible Achievement!</p>
            <p>Indira School of Business Studies is thrilled to announce a major achievement that's truly a win for all of us! Our move towards gaining Autonomy from UGC, Ministry of Education, Govt of India for Shree Chanakya Education Society's Indira School of Business Studies is a significant leap toward academic independence and innovation.</p>
            <p>Every voice, every idea, every initiative within the Indira Group has played a vital role in reaching this stage. This journey wouldn't be possible without the support, enthusiasm, and hard work of each member of our incredible community, also this milestone is a testament to the combined efforts and dedication of our students, faculty, alumni, staff members and all other stakeholders. It's the result of our collective vision for excellence and commitment to providing top-notch education and opportunities.</p>
            <p>As we continue this inspiring journey, let's maintain our unity and we'll embrace this newfound autonomy to shape a brighter future for our students and elevate our academic standards.
</p>
		</div>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShowPrarambh}
        onHide={() => setLgShowPrarambh(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Prarambh 11.0
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
       <img src={PrarambhImg} alt="" className="imgRes" /> 
      
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p>Indira School of Business Studies welcomes a new MBA Batch (2023-25). </p>
            <p>We are excited to have our students join the team and embark on a journey of growth, collaboration, and success. </p>
            <p>Our induction program starts on 17th August 2023 and has been meticulously designed to help students, seamlessly transition into their new role, and understand our institute culture and values. </p>
		</div>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShowPrarambhVid}
        onHide={() => setLgShowPrarambhVid(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Prarambh 11.0
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
        <video width="100%" height="315" controls>
                                <source src={PrarambhVideo} type="video/mp4" />
                                
                                </video> 
      
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
              <p>What an incredibly enlightening day it has been, a perfect blend of Education and Corporate Excellence!</p>
              <p>Indira School of Business Studies feels truly honoured to host Shri. Chandrakant Dada Patil sir, the esteemed Minister of Higher and Technical Education of Maharashtra, as Chief Guest at Prarambh 11. O, induction program of the 2023-25 batch. His visionary insights into the future of education in our great state left us inspired and hopeful for what's to come in academia.</p>
              <p>Additionally, we were privileged to welcome Ms. Saswati Panigrahi, ma'am, the Global External Communication Lead at Castrol, as our Guest of Honor. Her expertise sheds brilliant light on the vital bridge between academics and the corporate world, paving the way for our students' successful journeys.</p>
              <p>Thank you both for gracing us with your presence and knowledge. We at Indira are shaping the future of education and empowering our students for a bright tomorrow!</p>
		</div>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShowNewsMDP}
        onHide={() => setLgShowNewsMDP(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
        <Carousel className="marginBottom2em studentC">
          <Carousel.Item>
        <img src={MdpImg1} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={MdpImg2} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={MdpImg3} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={MdpImg4} className="imgRes" /> 
        </Carousel.Item>

          </Carousel>
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><strong>Enhancing Analytical Thinking &amp; Problem-Solving: A Transformative Outdoor
            Learning Program by ISBS for Hi-Tech Radiators, Mumbai at Majestic Court Sarovar
            Portico, Navi Mumbai on 09/08/2023</strong></p>
            <p>Indira School of Business Studies, in collaboration with Hi-Tech Radiators, Mumbai,
            orchestrated a dynamic Outdoor Experiential Learning Training to fortify analytical thinking
            and problem-solving skills. Led by the esteemed Dr. Abhijit Bobde and supported by Dr.
            Sushil Shende, this program seamlessly merged theoretical models with practical
            applications to empower participants.</p>
            <p>The training journey encompassed a spectrum of activities, from exhilarating outdoor
            simulations to indoor strategic games. Participants navigated data extraction challenges,
            culminating in the formulation of meticulous Standard Operating Procedures (SOPs). This
            process fostered the art of analysis and decision-making, vital in modern business landscapes.</p>
            <p>Dr. Bobde&#39;s adept integration of theoretical frameworks served as guiding beacons, enriching
            analytical thinking and honing decision-making prowess. Participants found themselves
            adeptly applying these models to diverse scenarios, enhancing their cognitive toolkits.</p>
            <p>Testimonials from participants resoundingly lauded the program&#39;s engagement and workplace
            relevance. The fusion of theory and practice not only amplified analytical acumen but also
            empowered confident and informed decision-making. This program stands as a testament to
            ISBS&#39;s commitment to nurturing adaptable, analytical leaders ready to excel in the Hi-Tech
            Radiators&#39; dynamic landscape.</p>
              
		</div>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShowNewsAward}
        onHide={() => setLgShowNewsAward(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
        <Carousel className="marginBottom2em studentC">
          <Carousel.Item>
        <img src={AwardImg1} className="imgRes" /> 
        </Carousel.Item>
        <Carousel.Item>
        <img src={AwardImg2} className="imgRes" /> 
        </Carousel.Item>
          </Carousel>
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><strong>One more feather in the cap!!</strong></p>
            <p>“Hard work increases the probability of serendipity.”-  Ken Poirot</p>
            <p>We at Indira School of Business Studies humbly announce that we have received the "Best Business School" Award at the 12th World Education Congress Global Awards under the institutional category on 13th July 2023.</p>

            <p>We are very thankful to our stakeholders for believing in us. ISBS wishes to express its heartfelt gratitude to our Chairperson, Dr . Tarita Shankar Ma'am, for always believing in us and assisting us in reaching new heights.
</p>
              
		</div>
        </div>
        </Modal.Body>
      </Modal>

<Modal
        size="lg"
        show={lgShowNewsNISM}
        onHide={() => setLgShowNewsNISM(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
       
        <img src={NISMImg} className="imgRes" /> 
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><strong>ISBS has organized One day Industry visit to NISM Patalganga on 9th May 2023.</strong></p>
            <p>Indira School of Business Studies organized One day Industry visit to NISM Patalganga on 9th May 2023 for MBA Batch 2022-24.</p>
           
            <p>It was not just an Industry visit rather was an educational visit where every student has got the chance to be a part of virtual trading environment by attending the Market Metaverse Program.</p>

            <p>It was great pleasure for the Finance specialization students to be a part of this visit and experiencing a fascinating glimpse into the future of finance and technology!</p>
              
		</div>
        </div>
        </Modal.Body>
      </Modal>

<Modal
        size="lg"
        show={lgShowNewsinduction}
        onHide={() => setLgShowNewsinduction(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
       
        <img src={Inductionprogram} className="imgRes" /> 
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><b>Date: </b>- Friday, 16th Dec 2022</p>
            <p><b>Venue:</b> - Dhruv Auditorium </p>

            <p>On 16th Dec 2022, the Cultural Team – UDAAN, as part of our 10th Induction program, organised a Talent Hunt for the incoming batch of ‘22-‘24. The much-awaited event aimed to flaunt their unique talent before the audience. The event sent waves of exhilaration, frenzy, excitement, and exuberance. It gave students a platform to showcase their talent in the field of their choice.</p>
			<p>The performances were extremely diverse and artistic, covering not just established art forms like painting, music, and dance forms, and fashion shows but also unconventional ones like stand-up comedy, photography, and poetry (Shayari).</p>
              
		</div>
        </div>
        </Modal.Body>
      </Modal>

<Modal
        size="lg"
        show={lgShowNewsparabh}
        onHide={() => setLgShowNewsparabh(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
        <video controls width="100%">
      
      <source src="../assets/images/news/parambhavideo.mp4" type="video/mp4"
      />
      Sorry, your browser doesn't support videos.
    </video>
           
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><b>ISBS is extremely active in industry academia interfaces & over the past 9 
                years have become one of the most sought-after management institutes.</b></p>
			
              
		</div>
        </div>
        </Modal.Body>
      </Modal>
















<Modal
        size="lg"
        show={lgShowNews6}
        onHide={() => setLgShowNews6(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News7} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><b>Indira Brand Slam- Summit & Awards</b></p>
        
       <p>We at Indira School of Business Studies are delighted to inform you that we will be hosting the 10th Edition of Indira Brand Slam- Summit & Awards on 10th  & 11th Feb 2023. </p>
       <p><b>Theme:- “Changing Paradigms for Building Brands”</b></p>
		</div>
        </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={lgShowNews5}
        onHide={() => setLgShowNews5(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News6Banner} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
        <p><b>For FDP 13 October following link should be attached there:</b></p>
                                      <a href="https://forms.gle/cW269tXocGGdvLVr5" target="_blank"><p>https://forms.gle/cW269tXocGGdvLVr5</p></a>
		</div>
        </div>
        </Modal.Body>
      </Modal>

<Modal
        size="lg"
        show={lgShowNews}
        onHide={() => setLgShowNews(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News1} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
        <h4>ISBS has been accredited once again by National Board of Accreditation (NBA) New Delhi.</h4>
						<p>Indira School of Business Studies has been accredited once again by National Board of Accreditation (NBA) New Delhi. </p>   
                        <p>On attaining this benchmark for excellence, we express our gratitude to all of our stakeholders
						for their ongoing support and reposing their trust in this institution.</p>           
		</div>
        </div>
        </Modal.Body>
      </Modal>
      
<Modal
        size="lg"
        show={lgShowNews1}
        onHide={() => setLgShowNews1(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News2} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
        <h4>ISBS Students selected for PRME Global Students (PGS) Taskforce</h4>
						<p>3 Students of ISBS batch 21 – 23 were selected as part of PGS taskforce. Each project on the PGS Portfolio is led by Global Taskforces, composed of one </p>
                        <p>coordinator and 3 to 5 worldwide members responsible for conceptualizing, planning, and implementing these seven projects by 2022/23. 
                        </p>
                        <p>The students selected are:</p>
                        <ol class="text-left">
                        <li>PGS Career Taskforce : Mr. Palash Khobaragade</li>
                        <li>PGS Social Media Taskforce : Ms Aditi Deotkar</li>
                        <li>PGS Talks Taskforce : Ms Ankita Ahire</li>
                        </ol>
                        <p>These Students will be responsible to work at International Level with PRME activity and exposure.</p>


		</div>
        </div>
        </Modal.Body>
      </Modal>
      
<Modal
        size="lg"
        show={lgShowNews2}
        onHide={() => setLgShowNews2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News3} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
        <h4>Higher Education and Edtech Conclave & Awards 2022</h4>
						<p>ArdorComm Media Group hosted the  *'HIGHER EDUCATION and EDTECH CONCLAVE & AWARDS 2022’* as the 1st & Biggest *‘Physical Event* in *Pune, </p>
                        <p>Maharashtra on 6th May 2022 (Friday)  Prof. Chetan Wakalkar was invited as one of the eminent speakers in a panel for the discussion on the topic "Reimagining higher education through the PHYGITAL mode of learning" Sir was felicitated on this knowledge platform as a renowned Educator  to have played a key role in transforming the teaching, learning, and employability outcomes of students.
                         Prof. Chetan along with the other panel members deliberated on best & next practices in higher, skill & technical education
                        </p>
        </div>
        </div>
        </Modal.Body>
      </Modal>
      
<Modal
        size="lg"
        show={lgShowNews3}
        onHide={() => setLgShowNews3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News4} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
        <h4> Indira School of Business Studies (ISBS) Successfully Completes the X Culture project</h4>
						<p>Along with students from other nations, 45 students from the Indira School of Business Studies (ISBS) successfully completes the X Culture project </p>
                        <p> X-Culture is a platform for students where they work in international teams with peers from around the world. They solve real-life problems presented by real-life companies. 
						They learn about other countries and cultures and learn how to collaborate online and work in international teams. ISBS students completed this project in two months.</p>


		</div>
        </div>
        </Modal.Body>
      </Modal>
      
<Modal
        size="lg"
        show={lgShowNews4}
        onHide={() => setLgShowNews4(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={News5} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
        <h4>Indira School of Business Studies (ISBS) Successfully Completes the X Culture project</h4>
        
						<p>Along with students from other nations, 45 students from the Indira School of Business Studies (ISBS) successfully completes the X Culture project </p>
						
						<p> X-Culture is a platform for students where they work in international teams with peers from around the world. They solve real-life problems presented by real-life companies. 
						They learn about other countries and cultures and learn how to collaborate online and work in international teams. ISBS students completed this project in two months.</p>
                        
		</div>
        </div>
        </Modal.Body>
      </Modal>

{/*------------------------------eventmodal------------*/}

            <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event1} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
			<p>ISBS will be conducting a program on “Applied Statistics using SPSS” dated 26th & 27th August 2022 (Offline Mode). This will help academicians researchers to get

             a basic, intermediate, and advance level knowledge of Applied Statistics using SPSS for research paper writing, projects development, advance level of PhD thesis development, and for Marketing Research & Intelligence. The case method of teaching and learning will be emphasised during the Program. Hands-on experience will be provided to the Participants on the SPSS application to get insights into research and data analysis.
              </p>

              <p>Registration Link:<a href="https://forms.gle/n8fgjsoKtH1SRTsW6" target="_blank"> https://forms.gle/n8fgjsoKtH1SRTsW6</a></p>
              
		</div>
        </div>
        </Modal.Body>
      </Modal>


            <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event1} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
			<p>ISBS will be conducting a program on “Applied Statistics using SPSS” dated 26th & 27th August 2022 (Offline Mode). This will help academicians researchers to get

             a basic, intermediate, and advance level knowledge of Applied Statistics using SPSS for research paper writing, projects development, advance level of PhD thesis development, and for Marketing Research & Intelligence. The case method of teaching and learning will be emphasised during the Program. Hands-on experience will be provided to the Participants on the SPSS application to get insights into research and data analysis.
              </p>

              <p>Registration Link:<a href="https://forms.gle/n8fgjsoKtH1SRTsW6" target="_blank"> https://forms.gle/n8fgjsoKtH1SRTsW6</a></p>
              
		</div>
        </div>
        </Modal.Body>
      </Modal>


      <Modal
        size="lg"
        show={lgShow1}
        onHide={() => setLgShow1(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event1} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p>ISBS will be conducting a program on “Applied Statistics using SPSS” dated 26th & 27th August 2022 (Offline Mode). This will help academicians researchers to get</p>
            <p> a basic, intermediate, and advance level knowledge of Applied Statistics using SPSS for research paper writing, projects development, 
                        							advance level of PhD thesis development, and for Marketing Research & Intelligence. The case method of teaching and learning will be emphasised during the Program. 
                        							Hands-on experience will be provided to the Participants on the SPSS application to get insights into research and data analysis.
                                                   <br/> Registration Link: <a href="https://forms.gle/n8fgjsoKtH1SRTsW6"  target="_blank">https://forms.gle/n8fgjsoKtH1SRTsW6</a> </p>
              
		</div>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShow1}
        onHide={() => setLgShow1(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event2} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
            <p><b>Alumni Preparatory talks</b></p>
			<p>Alumni Preparatory talks provide an opportunity to collect valuable information about various career tracks and understand the skillset needed for the same.
              </p>
            <p>We at ISBS believe in providing many such opportunities where they listen and learn from the rich alumni who are working across sectors in the global Market place.</p>

              
		</div>
        </div>
        </Modal.Body>
      </Modal>


















      <Modal
        size="lg"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event3} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
              <p> UDAAN - The Cultural Team organized the event “Traditional Day” On 9th July 2022 for the batch of 2021-2023</p>

              <p>which was an effort to celebrate the diverse traditions & cultures of our beautiful country- India; with harmony!
                             The students, faculty & staff of the institute came dressed beautifully portraying the dresses of different regions of India. </p>              
		</div>
        </div>
        </Modal.Body>
      </Modal>


      <Modal
        size="lg"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event4} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
        <p>Yoga is about awakening. Yoga is about creating a life that brings more beauty and more love into the world!!</p>
          <p>Make your life Beautiful on this International Yoga Day!!<br/>
                        							The potential of Yoga and how it is refined through the ages is well known to all of us. On the occasion of International Yoga Day, ISBS and ISBS PDGM celebrated Yoga Day on 21st June 2022.
                        							All teaching and non-teaching members from both the departments at the ISBS campus. Ms. Shobha from PDGM a trained coach for Yoga directed everyone for doing the mindful Yoga. 
                        							After the refreshing Yoga practice, everyone enjoyed fresh lassi. </p>              
		</div>
        </div>
        </Modal.Body>
      </Modal>


      <Modal
        size="lg"
        show={lgShow4}
        onHide={() => setLgShow4(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event5} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
			<p>Believing in the philosophy of holistic development of students,, Indira School of Business Studies hosted  </p>

            <p>a workshop entitled "Unleash The Unstoppable-A Leadership Development Program" for it's MBA Batch 2021-23 on 18th of May 2022 by motivational speaker 
                        							Mr. Nadeem Kazi. During the workshop the students were ecstatic and had valuable takeaways such as out of the box thinking, never giving up attitude, 
                        							failure is the first step to success, team building, one's environment affects one's aura, and having a never stop learning school of thought makes great leaders.</p>              
		</div>
        </div>
        </Modal.Body>
      </Modal>


      <Modal
        size="lg"
        show={lgShow5}
        onHide={() => setLgShow5(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            

        <div className="row">
        <div className="col-md-3">
            </div>
        <div className="col-md-6">
            <img src={Event6} className="imgRes" />
        </div>
        <div className="col-md-3">
            </div>
        <div className="col-md-12">
			<p>The future belongs to them who learn more skills and combine them in creative ways” – Robert Greene.</p>

            <p>The pandemic has changed the way businesses are run. Business models have seen a paradigm shift; hence it becomes necessary 
                        							to understand what skillset one should acquire to be relevant post pandemic. As part of Board Room Talks – today we will be in conversation with our 
                        							Alumni who are working in MNC’s to have their viewpoint on “Skills to Acquire to be relevant in 2022” – at Indira School of Business Studies..</p>              
		</div>
        </div>
        </Modal.Body>
      </Modal>



            <div className={popupShow?"enquireNowFormCont enableEnquire":"enquireNowFormCont"}>
                <div className="closeBtn" onClick={handleClosePopup}>X</div>
                <div className="formtitle">
                    <h4 className="titleTxt">Enquire Now</h4>
                </div>
                <div class="npf_wgts" data-height="500px" data-w="ad6d9c5fe9fce98f41e97e97434181b3"></div>

                {/* <div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="username" name="username" placeholder="Name" required="" />
                    </div>
                    <div className="form-group">
                        <input type="Email" className="form-control" id="email" name="email" placeholder="Email Address" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="contact" name="contact" placeholder="Phone Number" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name="city" id="city" placeholder="City" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="state" name="state" placeholder="State" required="" />
                    </div>
                    <div className="checkbox">
                        <label><input name="checkbox" type="checkbox" id="checkbox" value="" required="" />I agree to receive information regarding my registration by signing up on Indira Group of Institutes, Pune *</label>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="registerBtn" id="register" onClick={handleClosePopup}>Register</button>
                    </div>
                </div>
                <div id="success_msg" className="alert alert-success" style={{ display: 'none' }}>
                    <strong>Thank you!</strong>
                </div>
                <div id="error_msg" className="alert alert-warning" style={{ display: 'none' }}>
                    <strong>Warning!</strong>
                </div> */}

            </div>

            {displayMenu && <div id='nav'>
                <div className="mainMenuContainer" ref={parentContainer}>
                    <div className="logoContainerMain" ref={ref}>
                        <NavLink to="/" exact onClick={closeAllMenuHandler}><img src={LogoMainImage} alt="" className="logoMain" /></NavLink>
                    </div>
                    <div className="menuListMain">
                        <ul>
                            <li className={aboutSubMenu ? "activeMnu" : ""} onClick={AboutHandler}>About Us</li>
                            <li className={coursesSubMenu ? "activeMnu" : ""} onClick={CoursesHandler}>Courses</li>
                            <li className={placementSubMenu ? "activeMnu" : ""} onClick={PlacementMenuHandler}>Placement</li>
                            <li className={studentLifeSubMenu ? "activeMnu" : ""} onClick={StudentLifeMenuHandler}>Student Life</li>
                            <li className={alumniSubMenu ? "activeMnu" : ""} onClick={AlumniMenuHandler}>Alumni</li>
                            <li className={newsSubMenu ? "activeMnu" : ""} onClick={NewsMenuHandler}>News & Events</li>
                            <li className={researchSubMenu ? "activeMnu" : ""} onClick={ResearchMenuHandler}>Research</li>
                            <li className={mdpSubMenu ? "activeMnu" : ""} onClick={MdpMenuHandler}>MDP</li>
                            {/* <a href="https://blog.indiraedu.com/" target="_blank" rel="noreferrer"><li>Blog</li></a> */}
                            <li className={PublicationsSubMenu ? "activeMnu" : ""} onClick={PublicationsMenuHandler}>Publications</li>
                            {/* <a href="https://indiraisbs.ac.in/ISBS-360-degree-virtual-tour/" target="_blank" rel="noreferrer"><li>Virtual Tour</li></a> */}
                            <li className={contactSubMenu ? "activeMnu" : ""} onClick={ContactMenuHandler}>Contact Us</li>
                            <li className='MnuSocial'>
                                <ul className="socialMediaLogo">
                                    <a href="https://www.facebook.com/isbs.indira" target="_blank" rel="noreferrer"><li><img src={FacebookLogo} /></li></a>
                                    <a href="https://www.youtube.com/c/indiracollege/videos" target="_blank" rel="noreferrer"><li><img src={TwitterLogo} /></li></a>
                                    <a href="https://www.linkedin.com/school/indira-school-of-business-studies/" target="_blank" rel="noreferrer"><li><img src={InstaLogo} /></li></a>
                                    <a href="https://blog.indiraedu.com/" target="_blank" rel="noreferrer"><li><img src={BlogLogo} /></li></a>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={aboutSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="/shree-chanakya-education-society"><li>Shree Chanakya Education Society</li></NavLink>
                            <NavLink to="/indira-group-of-institutes"><li>Indira Group of Institutes</li></NavLink>
                            <NavLink to="/indira-school-of-business-studies"><li>Indira School of Business Studies</li></NavLink>
                            <NavLink to="/recognition"><li>Recognition</li></NavLink>
                            {/*<a href={NIRF} target="_blank" rel="noreferrer"><li>NIRF 2022</li></a>*/}
                            <a href={NBA} target="_blank" rel="noreferrer"><li>NBA</li></a>
                            <h6 style={{ fontWeight: '900', marginTop: '25px', marginLeft: '10%' }}>Hostel</h6>
                            {/*<a href="https://indiraisbsmba.edu.in/Hostel" target="_blank" rel="noreferrer"><li>Tribe Hostel Wakad</li></a>*/}
                            <NavLink to="/hostel"><li>Tribe Hostel Wakad</li></NavLink>
                            <NavLink to="/ivy-league-house"><li>Ivy League House</li></NavLink>
                            {/*<a href="https://indiraisbsmba.edu.in/Ivy_League_house" target="_blank" rel="noreferrer"><li>Ivy League House</li></a>*/}
                        </ul>
                    </div>
                </div>
                <div className={coursesSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub menuListSublist" style={{ marginTop: height }}>
                        <ul>
                            <li className={coursesSubMbaMenu ? "activeMnuSub" : ""} onClick={MbaMenuHandler}>MBA</li>
                            <li className={coursesSubPhdMenu ? "activeMnuSub" : ""} onClick={PhdMenuHandler}>Ph.D</li>
                            <NavLink to="mba-for-working-professionals" onClick={closeAllMenuHandler}><li>Supernumerary Mba(For Working Professionals )</li></NavLink>

                        </ul>
                    </div>
                </div>
                <div className={coursesSubMbaMenu ? "HomeSubmenuThirdContainer activeThirdMenu" : "HomeSubmenuThirdContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="Mba"><li>Overview</li></NavLink>
                            <NavLink to="Mba"><li>Admission Process</li></NavLink>
                            <NavLink to="Mba"><li>Course Structure</li></NavLink>
                            <NavLink to="Mba"><li>Fee Structure</li></NavLink>
                        </ul>
                    </div>
                </div>

                <div className={coursesSubPhdMenu ? "HomeSubmenuThirdContainer activeThirdMenu" : "HomeSubmenuThirdContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="phd"><li>About PGRC</li></NavLink>
                            <NavLink to="phd"><li>Admission Process</li></NavLink>
                            <NavLink to="phd"><li>Documents Required</li></NavLink>
                            <NavLink to="phd"><li>Contacts</li></NavLink>
                            <NavLink to="phd"><li>Latest Update</li></NavLink>
                        </ul>
                    </div>
                </div>
                <div className={placementSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub menuListSublist" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="/placement-cell"><li>Placement Cell</li></NavLink>
                            <NavLink to="/placement-at-isbs"><li>Placement at ISBS</li></NavLink>
                            <NavLink to="/recruiters"><li>Our Recruiters</li></NavLink>
                            <NavLink to="/placement-report"><li>Last 3 years Placement Report</li></NavLink>
                        </ul>
                    </div>
                </div>
                <div className={studentLifeSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub menuListSublist" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="life-at-isbs"><li>Life At ISBS</li></NavLink>
                            <NavLink to="/student-committee"><li>Student Committees</li></NavLink>
                            <NavLink to="/student-engagement"><li>Student Engagement</li></NavLink>
                            <NavLink to="/compliance-committee"><li>Compliance Committee</li></NavLink>
                            <NavLink to="/student-club"><li>Student Clubs</li></NavLink>
                            <NavLink to="/sports-activity"><li>Sports Activity</li></NavLink>
                            <NavLink to="/video-gallery" ><li>Video Gallery</li></NavLink>

                        </ul>
                    </div>
                </div>
                <div className={newsSubMenu ? "HomeSubmenuFullContainer activeSubMenu" : "HomeSubmenuFullContainer"} style={{ minHeight:"474px", padding: '15px' }}>
                    <div className="row">
                        <div className="col-md-6 news">
                            <h5 className="pinkTextColor">News</h5>

                            {/*<Carousel className="marginBottom2em studentC">
                                <Carousel.Item>
                                    <img src={Banner6Image} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p>Business Leader of the Year 2022:</p>
                                    <p>Congratulations to our Chairperson Dr. Tarita
                                        Ma'am and Group Director Prof. Chetan Sir for the
                                        awards at the Business Leader of the Year 2022
                                        Ceremony.</p>
                                    <p onClick={handleShow}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={Banner6Image} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p>Business Leader of the Year 2022:</p>
                                    <p>Congratulations to our Chairperson Dr. Tarita
                                        Ma'am and Group Director Prof. Chetan Sir for the
                                        awards at the Business Leader of the Year 2022
                                        Ceremony.</p>
                                    <p onClick={handleShow}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={Banner6Image} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p>Business Leader of the Year 2022:</p>
                                    <p>Congratulations to our Chairperson Dr. Tarita
                                        Ma'am and Group Director Prof. Chetan Sir for the
                                        awards at the Business Leader of the Year 2022
                                        Ceremony.</p>
                                    <p onClick={handleShow}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                            </Carousel>*/}

                               <Carousel className="marginBottom2em studentC">

                               <Carousel.Item>
                               <video width="100%" height="315" controls>
                                <source src={Bonding} type="video/mp4" />
                                
                                </video>
                                    <br />
                                    <br />
                                   <p><b> Are you prepared to mould your success tale? Your launchpad is the Indira School of Business Studies.
</b></p>
                                    <p onClick={() => setLgMBAPLACEMENT(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>
                               <Carousel.Item>
                                    <img src={BrandSlamImg1} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                   <p><b>Indira Brand Slam Summit & Awards, 11th Edition: A Makeover of Marketing and Branding in the New Era
</b></p>
                                    <p onClick={() => setLgShowBrandSlam(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>

                               <Carousel.Item>
                                    <img src={ISBSAutonomouspostImg} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                   <p><b>One Big Family, One Incredible Achievement! </b></p>
                                    <p onClick={() => setLgShowISBSAutonomous(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>
                               <Carousel.Item>
                               <video width="100%" height="250" controls>
                                <source src={PrarambhVideo} type="video/mp4" />
                                
                                </video> 
                                    <br />
                                    <br />
                                   <p><b>What an incredibly enlightening day it has been, a perfect blend of Education and Corporate Excellence! </b></p>
                                    <p onClick={() => setLgShowPrarambhVid(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                

                                <Carousel.Item>
                                    <img src={PrarambhImg} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                   <p><b>Indira School of Business Studies welcomes a new MBA Batch (2023-25) <br/>We are excited to have our students join the team and embark on a journey of growth, collaboration, and success. </b></p>
                                    <p onClick={() => setLgShowPrarambh(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>

                               <Carousel.Item>
                                    <img src={MdpImg1} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                   <p><b>Enhancing Analytical Thinking & Problem-Solving: A Transformative Outdoor Learning Program by ISBS for Hi-Tech Radiators, Mumbai at Majestic Court Sarovar Portico, Navi Mumbai on 09/08/2023</b></p>
                                    <p onClick={() => setLgShowNewsMDP(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>

                               <Carousel.Item>
                                    <img src={AwardImg1} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                   <p><b>We at Indira School of Business Studies humbly announce that we have received the "Best Business School" Award</b></p>
                                    <p onClick={() => setLgShowNewsAward(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>

                               <Carousel.Item>
                                    <img src={NISMImg} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                   <p><b>ISBS has organized One day Industry visit to NISM Patalganga on 9th May 2023.</b></p>
                                    <p onClick={() => setLgShowNewsNISM(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                
                               <Carousel.Item>
                                    <img src={Inductionprogram} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                   <p><b>On 16th Dec 2022, the Cultural Team – UDAAN, as part of our 10th Induction program, organised a Talent Hunt for the incoming batch of ‘22-‘24.</b></p>
                                    <p onClick={() => setLgShowNewsinduction(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={Newsprarabha} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>
                                Indira School of Business Studies concluded 20 days long Prarambh 10.O - </b></p>
                                                                    <p>AThe Induction program for MBA Batch 2022-24 with more than 100 hours of input and 25 plus stalwarts
                                from industry across Finance, Marketing, HR and Operations in attendance.</p>
                                    <p onClick={() => setLgShowNewsparabh(true)}><b className="pinkTextColor 
                                    cursorPointer">Read More</b></p>
                                </Carousel.Item>
                               <Carousel.Item>
                                    <img src={News7} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>Indira Brand Slam- Summit & Awards</b></p>
                                      
                                    <p onClick={() => setLgShowNews6(true)}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                               <Carousel.Item>
                                    <img src={News6} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>IFor FDP 13 October following link should be attached there:</b></p>
                                      <a href="https://forms.gle/cW269tXocGGdvLVr5" target="_blank"><p>https://forms.gle/cW269tXocGGdvLVr5</p></a>
                                    <p onClick={() => setLgShowNews5(true)}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={News1} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>ISBS has been accredited once again by National Board of Accreditation (NBA) New Delhi.</b></p>

                                    <p onClick={() => setLgShowNews(true)}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={News2} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>ISBS Students selected for PRME Global Students (PGS) Taskforce</b></p>
                                    <p>3 Students of ISBS batch 21 – 23 were selected as part of PGS taskforce.</p>
                                    <p onClick={() => setLgShowNews1(true)}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={News3} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>Prof. Chetan Wakalkar on “The Dee Show”.</b></p>
                                    {/*<p>Prof. Chetan was invited on “The Dee Show” to speak on the topic "Indian Business Management Models". To watch the entire episode Click Here.</p>*/}
                                    <p onClick={() => setLgShowNews2(true)}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={News4} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>Higher Education and Edtech Conclave & Awards 2022</b></p>
                                    {/*<p>ArdorComm Media Group hosted the  *'HIGHER EDUCATION and EDTECH CONCLAVE & AWARDS 2022’* as the 1st & Biggest *‘Physical Event* in *Pune,</p>*/}
                                    <p onClick={() => setLgShowNews3(true)}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={News5} alt="" className="imgRes" />
                                    <br />
                                    <br />
                                    <p><b>Indira School of Business Studies (ISBS) Successfully Completes the X Culture project</b></p>
                                    {/*<p>Along with students from other nations, 45 students from the Indira School of Business Studies (ISBS) successfully completes the X Culture project</p>*/}
                                    <p onClick={() => setLgShowNews4(true)}><b className="pinkTextColor cursorPointer">Read More</b></p>
                                </Carousel.Item>
                               

                              



                            </Carousel>
                        </div>
                        <div className="col-md-6">
                            <h5 className="pinkTextColor" style={{ paddingLeft: '30px' }}>Events</h5>
                            <div className="marquee">
                                       <div className="marquee--inner">
                               {/*} <div className="eventsList padding30px">
                                    <ul>
                                        <li onClick={handleShow} className="cursorPointer">
                                            <p>10th Jan 2022</p>
                                            <p><b>Prarambh 9.0</b></p>
                                            <p><b>Induction Program Batch 2021-23</b></p>
                                        </li>
                                        <li onClick={handleShow} className="cursorPointer">
                                            <p>22th Jan 2022</p>
                                            <p><b>Indira Brand Slam 9.0 - Summit &</b></p>
                                            <p><b>Awards 2022</b></p>
                                        </li>
                                        <li onClick={handleShow} className="cursorPointer">
                                            <p>22th Jan 2022</p>
                                            <p><b>IGI presents Indira Global</b></p>
                                            <p><b>Leadership Lecture Series 2022</b></p>
                                        </li>
                                        <li style={{ border: 'none' }} onClick={handleShow} className="cursorPointer">
                                            <p>o9th Jan 2022</p>
                                            <p><b>Certification Workshop "Facebook</b></p>
                                            <p><b>& Linkedin Marketing Masterclass"</b></p>
                                        </li>




                                        
                                    </ul>
                                </div>*/}

                                 <div className="eventsList padding30px">
                                    <ul>
                                    <li className="cursorPointer" onClick={() => setLgShowBrandSlam(true)} >
                                    <p><b>Indira Brand Slam Summit & Awards, 11th Edition: A Makeover of Marketing and Branding in the New Era</b></p>
                                        </li>

                                    <li className="cursorPointer" onClick={() => setLgShowPrarambh(true)} >
                                    <p>17th August 2023</p>
                                            <p><b>Indira School of Business Studies welcomes a new MBA Batch (2023-25). </b></p>
                                        </li>

                                    <li className="cursorPointer" onClick={() => setLgShowNewsMDP(true)} >
                                    <p>09th August 2023</p>
                                            <p><b>Enhancing Analytical Thinking &amp; Problem-Solving: A Transformative Outdoor
                                        Learning Program by ISBS for Hi-Tech Radiators, Mumbai at Majestic Court Sarovar
                                        Portico, Navi Mumbai on 09/08/2023</b></p>
                                        </li>
                                    <li className="cursorPointer" onClick={() => setLgShowNewsAward(true)} >
                                            <p>13th July 2023</p>
                                            <p><b>We at Indira School of Business Studies <br/>humbly announce that we have received the 
                                            <br/>"Best Business School" Award</b></p>
                                        </li>

                                    <li className="cursorPointer" onClick={() => setLgShowNewsNISM(true)} >
                                            <p>9th May 2023</p>
                                            <p><b>ISBS has organized One day Industry visit <br/>to NISM Patalganga on 9th May 2023.</b></p>
                                        </li>
                                    <NavLink to="/brandSlam" style={{color:"#000"}}><li className="cursorPointer">
                                            <p> 10 & 11 February 2023</p>
                                            <p><b>Indira Brand Slam Summit and <br/>Awards, 2023</b></p>
                                        </li></NavLink>
                                        <li className="cursorPointer">
                                            <p> 16th Dec 2022</p>
                                            <p><b>Cultural Team – UDAAN, as part of our 10th  <br/>Induction program, organised a Talent Hunt for the incoming batch of ‘22-‘24</b></p>
                                        </li>
                                        <li onClick={() => setLgShow(true)} className="cursorPointer">
                                            <p>26th Aug 2022</p>
                                            <p><b>Applied Statistics using SPSS</b></p>
                                            <p><b>ISBS will be conducting a program</b></p>
                                        </li>
                                        <li onClick={() => setLgShow1(true)} className="cursorPointer">
                                            <p>23rd July 2022</p>
                                            <p><b>Alumni Preparatory talks</b></p>
                                            <p><b>Alumni Preparatory talks provide an opportunity to collect valuable</b></p>
                                        </li>
                                        <li onClick={() => setLgShow2(true)} className="cursorPointer">
                                            <p>09th July 2022</p>
                                            <p><b>Traditional Day</b></p>
                                            <p><b>UDAAN - The Cultural Team organized the event “Traditional Day”</b></p>
                                        </li>
                                        <li onClick={() => setLgShow3(true)} className="cursorPointer">
                                            <p>21th June 2022</p>
                                            <p><b>International Yoga Day</b></p>
                                            <p><b>Yoga is about awakening.</b></p>
                                        </li>
                                        <li onClick={() => setLgShow4(true)} className="cursorPointer">
                                            <p>18th May 2022</p>
                                            <p><b>"Unleash The Unstoppable-A Leadership Development Program"</b></p>
                                            <p><b>Believing in the philosophy</b></p>
                                        </li>
                                        <li onClick={() => setLgShow5(true)} className="cursorPointer">
                                            <p>14th May 2022</p>
                                            <p><b>Board Room Talks</b></p>
                                            <p><b>The future belongs to them who </b></p>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                                </div>
                         

                        </div>
                    </div>
                </div>
                <div className={alumniSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub menuListSublist" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="alumni-association"><li>Alumni Association</li></NavLink>
                            <NavLink to="alumni-committee"><li>Alumni Committee</li></NavLink>
                            <NavLink to="alumni-network"><li>Indira Alumni Network</li></NavLink>
                        </ul>
                    </div>
                </div>
                <div className={researchSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub menuListSublist" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="/conference"><li>Conference</li></NavLink>
                            {/*<NavLink to="/indira-management-review"><li>Indira Management Review</li></NavLink>*/}
                            <NavLink to="/research-faculty-development"><li>Research & Faculty Development Program</li></NavLink>
                        </ul>
                    </div>
                </div>
                <div className={mdpSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub menuListSublist" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="/mdp-about"><li>About Us</li></NavLink>
                            <NavLink to="/mdp-programs"><li>Our Programs</li></NavLink>
                            <NavLink to="/mdp-client"><li>Our Clients</li></NavLink>
                            <NavLink to="/mdp-trainers"><li>Our Trainers</li></NavLink>
                            <NavLink to="/mdp-clients"><li>Clients Testimonials</li></NavLink> 
                            <NavLink to="/mdp-gallery"><li>Gallery</li></NavLink>
                            <NavLink to="/mdp-contacts"><li>Contact Us</li></NavLink>
                        </ul>
                    </div>
                </div>
                <div className={PublicationsSubMenu ? "HomeSubmenuContainer activeSubMenu" : "HomeSubmenuContainer"} style={{ height: parentHeight }}>
                    <div className="menuListSub menuListSublist" style={{ marginTop: height }}>
                        <ul>
                            <NavLink to="/editorial"><li>Editorial</li></NavLink>
                            <NavLink to="/conference-proceedings"><li>Conference Proceedings</li></NavLink>
                            <NavLink to="/research-journal"><li>Research Journal</li></NavLink>
                        </ul>
                    </div>
                </div>
                <div className={contactSubMenu ? "HomeSubmenuFullContainer activeSubMenu" : "HomeSubmenuFullContainer"} style={{ height: parentHeight, padding: '15px' }}>
                    <div className="row">
                        <div className="col-md-12 news contactDetails">
                            <p><b>Address</b></p>
                            <p>Shree Chanakya Education Society's, INDIRA SCHOOL OF BUSINESS STUDIES ‘Abhinavan’,89/2A, New Pune-Mumbai Highway, Tathawade, Pune – 411033, Maharashtra, India</p>
                            <p><b>Important Contact details</b></p>
                            <div className="row">
                                <div className="col-md-3">
                                    <p>Admission Enquiry</p>
                                </div>
                                <div className="col-md-4">
                                    <p>+91-20-66759500 / 415 / 407</p>
                                </div>
                                <div className="col-md-5">
                                    {/* <p>admissions@indiraisbsmba.edu.in</p> */}
                                    <p>admissions@indiraisbs.edu.in</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <p>Campus Hiring</p>
                                </div>
                                <div className="col-md-4">
                                    <p>+91-20-66759500 / 415 / 440</p>
                                </div>
                                <div className="col-md-5">
                                    {/* <p>placements_isbs@indiraisbs.ac.in</p> */}
                                    <p>placement_mba@indiraisbs.edu.in</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <p>Examination & Student Verification</p>
                                </div>
                                <div className="col-md-4">
                                    <p>+91-20-66759500 / 415 / 407</p>
                                </div>
                                <div className="col-md-5">
                                    {/* <p>examination@indiraisbsmba.edu.in</p> */}
                                    <p>examination@indiraisbs.edu.in</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <p>General Enquiry</p>
                                </div>
                                <div className="col-md-4">
                                    <p>+91-20-66759500 / 415 / 492</p>
                                </div>
                                <div className="col-md-5">
                                    {/* <p>info@indiraisbsmba.edu.in</p> */}
                                    <p>info@indiraisbs.edu.in</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <p>Careers at ISBS</p>
                                </div>
                                <div className="col-md-4">
                                    <p>+91-20-66759500 / 415 / 463</p>
                                </div>
                                <div className="col-md-5">
                                    {/* <p>careers@indiraisbsmba.edu.in</p> */}
                                    <p>careers@indiraisbs.edu.in</p>
                                </div>
                            </div>
                            <br />
                            <p><b>For More Information</b></p>
                            <NavLink to="contact"><button className="submitBtn">Click Here</button></NavLink>
                        </div>
                        {/* <div className="col-md-6 news">
                            <div className="formContact">
                                <form>
                                    <div className="formGroup">
                                        <input type="text" placeholder="Full Name*" />
                                    </div>
                                    <div className="formGroup">
                                        <input type="text" placeholder="Course Interested*" />
                                    </div>
                                    <div className="formGroup">
                                        <input type="text" placeholder="Email*" />
                                    </div>
                                    <div className="formGroup">
                                        <input type="text" placeholder="Contact*" />
                                    </div>
                                    <div className="formGroup">
                                        <input type="text" placeholder="Qualification*" />
                                    </div>
                                    <button className="submitBtn">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="map-responsive">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.179307716637!2d73.74667141489418!3d18.611003087356398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b97fc236722d%3A0x83578b8fa5ba266d!2sIndira%20School%20of%20Business%20Studies%20PGDM%2C%20Pune%20%7C%20ISBS%20PGDM%20(Top%20Ranked%20PGDM%20College%20in%20India%20%7C%20Best%20PGDM%20College%20in%20Pune-India%20for%20PGDM%20Course)!5e0!3m2!1sen!2sin!4v1655043845132!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            }
        </Fragment>
    )
}
export default HomePageMenu;
import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/DevelopmentCell.jpg"
import Res1Image from "../../assets/images/research/res1.jpg"
import Res2Image from "../../assets/images/research/res2.jpg"
import Res3Image from "../../assets/images/research/res3.jpg"
import Res4Image from "../../assets/images/research/res4.jpg"
import Res5Image from "../../assets/images/research/res5.jpg"
import Res6Image from "../../assets/images/research/res6.jpg"
import Res7Image from "../../assets/images/research/res7.jpg"
import Res8Image from "../../assets/images/research/res8.jpg"
import FDP1 from "../../assets/images/FDP1.jpeg"
import FDP2 from "../../assets/images/FDP2.jpeg"
import FDP3 from "../../assets/images/FDP3.jpeg"

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import { Table } from "react-bootstrap";
const ResearchFacultyDevelopment = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<Fragment>
			<Header></Header>
			<div className="col bannerCont">
				<img src={BannerImage} alt="" className="imgRes" />
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="blockTitle">Research &#62; <span>Research & Faculty Development Cell</span></h3>
					</div>
				</div>
			</div>
			<div className="backgroundGreyPlain">
				<div className="container">
					<div className="row paddingTop3em paddingBottom3em">
						<div className="col-md-12">
							<Tabs
								defaultActiveKey="About"
								id="uncontrolled-tab-example"
								className="barColor col-4t transparentBackground"
							>
								<Tab eventKey="About" title="About" className="whiteBackground paddingBottom3em">
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												{/* <h3 className="blockTitle" style={{ color: '#dfa726' }}>About Research and FDP Cell</h3>
                                                <p>Faculty Development & Research cell at ISBS aims at building an academic research-oriented culture within the institute to encourage faculty members to engage in meaningful research and enhance their capabilities to generate and extend their knowledge to students and other important stakeholders. It is expected to contribute to building faculty capacity as teachers, consultants and researchers which would further contribute to the student community, industry and society at large by way of knowledge generation and extension. Faculty Development Policy at ISBS aims to assist faculty members in improving performance in teaching, scholarly activity, and service. The R&D cell implements the Faculty Development Policy at ISBS which aims to assist faculty members in improving performance in teaching, scholarly activity, and consultancy service.</p> */}
												<p>The Faculty Development & Research (R&D) Cell at ISBS plays a crucial role in fostering a research-oriented culture among faculty members. The primary objectives of the R&D Cell are to encourage and support meaningful research, enhance the capabilities of faculty members, and extend knowledge to students and other stakeholders. This initiative is designed to contribute significantly to building faculty capacity in teaching, consulting, and research, thereby benefiting the student community, industry, and society at large through knowledge generation and extension.</p>
												<h3 className="blockTitle" style={{ color: '#dfa726' }}>Key Functions and Responsibilities</h3>
												<ul>
													<li><b>Cultivating Research Culture:</b>The R&D Cell is responsible for creating an environment that fosters a culture of research within the institute. This involves organizing workshops, seminars, and conferences to promote research activities among faculty members. </li>
													<li><b>Supporting Meaningful Research: </b>The cell provides support and resources to faculty members to facilitate meaningful research. This includes access to databases, research grants, and collaboration opportunities with other institutions and industry partners. </li>
													<li><b>Enhancing Faculty Capabilities:</b> The R&D Cell focuses on improving the capabilities of faculty members in various dimensions such as teaching, research, and consultancy. This involves organizing training programs and professional development activities. </li>
													<li><b>Implementing Faculty Development Policy:</b> The cell is responsible for implementing the Faculty Development Policy at ISBS. This policy is designed to assist faculty members in enhancing their performance in teaching, scholarly activities, and consultancy services. </li>
													<li><b>Performance Improvement:</b> The Faculty Development Policy aims to support faculty members in improving their overall performance. This includes evaluating and providing constructive feedback on teaching methodologies, research output, and service contributions. </li>
													<li><b>Knowledge Dissemination:</b>The R&D Cell plays a pivotal role in disseminating the knowledge generated by faculty members to students and other stakeholders. This involves organizing conferences, publishing research findings, and incorporating research outcomes into the curriculum. </li>
													<li><b>Contributing to Society:</b> The ultimate goal of the R&D Cell is to contribute to society at large. This is achieved through the knowledge generated and extended by faculty members, impacting not only students but also the industry and the broader community. </li>


												</ul>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="Objectives" title="Objectives" className="whiteBackground paddingBottom3em">
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<h3 className="blockTitle" style={{ color: '#dfa726' }}>Objectives of the Cell</h3>
												<div className="row">
													<div className="col-md-6 marginBottom2em">
														<img src={Res1Image} alt="" className="imgRes" />
													</div>
													<div className="col-md-6 marginBottom2em">
														<img src={Res5Image} alt="" className="imgRes" />
													</div>
													<div className="col-md-6 marginBottom2em">
														<img src={Res2Image} alt="" className="imgRes" />
													</div>
													<div className="col-md-6 marginBottom2em">
														<img src={Res6Image} alt="" className="imgRes" />
													</div>
													<div className="col-md-6 marginBottom2em">
														<img src={Res3Image} alt="" className="imgRes" />
													</div>
													<div className="col-md-6 marginBottom2em">
														<img src={Res7Image} alt="" className="imgRes" />
													</div>
													<div className="col-md-6 marginBottom2em">
														<img src={Res4Image} alt="" className="imgRes" />
													</div>
													<div className="col-md-6 marginBottom2em">
														<img src={Res8Image} alt="" className="imgRes" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="Faculty" title="Faculty Publication" className="whiteBackground paddingBottom3em">
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<h3 className="blockTitle paddingBottom1em paddingTop1em" style={{ color: '#dfa726' }}>Faculty Publications</h3>
												<Accordion defaultActiveKey="0">
													<Accordion.Item eventKey="0">
														<Accordion.Header>Research Publication 2018-19</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr><td><b>S.No.</b></td><td><b>Name of Faculty</b></td><td><b>Month</b></td><td><b>Title of Paper</b></td><td><b>Journal</b></td><td><b>ISSN/ISBN</b></td><td><b>Details</b></td><td><b>Indexing</b></td></tr>
																	<tr><td>1</td><td>Dr. Renu Bhargava and Dr. Kalpana Deshmukh</td><td>Apr-22</td><td>Case-Based Research on Enhancing Student Awareness of Sustainable Development Goals in B-Schools</td><td>International Journal of Early Childhood Special education</td><td>1308-5581</td><td>Vol.14, Issue 03 2022</td><td>Scopus</td></tr>
																	<tr><td>2</td><td>Dr. Renu Bhargava and Dr. Komal Singh</td><td>Jan-22</td><td>“A STUDY ON EMPLOYEE EXPERIENCE PLATFORMS FOR THE DIGITAL NATIVE ORGANIZATIONS”</td><td>Anvesak</td><td>ISSN : 0378 – 4568</td><td>Vol. 52, No. 5 (I) </td><td>UGC</td></tr>
																	<tr><td>3</td><td>Dr. Komal Singh and Dr. Manmohan Vyas</td><td>20-22</td><td>Critical Review Of Corporate Governance Impact On Company's Performance (A Comparative Study Of It Companies)</td><td>Korea Review Of International Studies</td><td>1226-4741</td><td>Volume 15 , Issue 01</td><td>ABDC</td></tr>
																	<tr><td>4</td><td>Dr. Komal Singh</td><td>Mar-22</td><td>Technical And Fundamental Analysis Of Selected Pharmaceutical Companies</td><td>Journal Of Education: Rabindra Bharati University</td><td>0972-7175</td><td>Vol.: XXV, No. :3(III), 2022</td><td>UGC</td></tr>
																	<tr><td>5</td><td>Dr.Manmohan Vyas</td><td>Jul-21</td><td>Corporate Governance &amp; Company Performance: A Study On Relevance. </td><td>Anvesak </td><td>0378-4568 </td><td>Vol 51: No 2</td><td>UGC</td></tr>
																	<tr><td>6</td><td>Dr.Manmohan Vyas</td><td>Oct-21</td><td>Infosys - A journey of values, evolving business models and corporate governance</td><td>NIU International journal</td><td>2394-00298</td><td>vol 8 - 2021</td><td>UGC</td></tr>
																	<tr><td>7</td><td>Dr.Manmohan Vyas</td><td>Feb-21</td><td>A Study On Multiskilling Of Employees In Manufacturing Sector</td><td>Journal Of Education: Rabindra Bharati University</td><td>0972-7175</td><td>XXX, No. 2(II)</td><td>UGC</td></tr>
																	<tr><td>8</td><td>Dr.Manmohan Vyas</td><td>Feb-22</td><td>Impact of social media marketing on performance of micro and small businesses</td><td>Anvesak</td><td>0378-4568</td><td>52, no 2 (III)</td><td>UGC</td></tr>
																	<tr><td>9</td><td>Dr. Yogesh Mahajan</td><td>Sep-21</td><td>To study the impact of coronavirus pandemic on manufacturing sector in India</td><td>Design Engineering</td><td>0011-9342</td><td>Vol 2021: Issue 07 / </td><td>Scopus</td></tr>
																	<tr><td>10</td><td>Dr. Tausif Mistry</td><td>Apr-22</td><td>Corporate Ethics - Misuse of Institutional Sales</td><td>Third Concept</td><td>0970 7247</td><td>Volume 36, No 422</td><td>UGC</td></tr>
																	<tr><td>11</td><td>Dr. Tausif Mistry</td><td>Apr-22</td><td>Learning In Times of Crisis: A Comprehensive Literature Review</td><td>Journal of Management and Entrepreneureship</td><td>2229-5348</td><td>Vol.16, No.2 (II)</td><td>UGC</td></tr>
																	<tr><td>12</td><td>Prof. Amrita Karnawat</td><td>Mar-22</td><td>A Financial Performance Analysis Of Itc Ltd By Using Ratios And Altman Z Score Model</td><td>Wesleyan Journal of Research</td><td>0975-1386</td><td>Vol.15 No.3 </td><td>UGC</td></tr>
																	<tr><td>13</td><td>Prof. Amrita Karnawat</td><td>Mar-22</td><td>A Financial Performance Analysis Of Itc Ltd By Using Ratios And Altman Z Score Model</td><td>Wesleyan Journal of Research</td><td>0975-1386</td><td>Vol.15 No.3 </td><td>UGC</td></tr>
																	<tr><td>14</td><td>Dr Kalpana Deshmukh</td><td>Dec-21</td><td>A Study on Continuous Process of R and S with Reference to Manufacturing Industries in Maharashtra State</td><td>Design Engineering (Toronto)</td><td>0011-9342</td><td>Vol 2021: Issue 09</td><td>Scopus</td></tr>
																	<tr><td>15</td><td>Dr Kalpana Deshmukh</td><td>Mar-22</td><td>A Study Of Campus Recruitment Process At Acura Solutions, Mulund</td><td>ANVESAK </td><td>0378 – 4568</td><td>Vol. 52, No. 3 (III) January – June 2022</td><td>UGC</td></tr>
																	<tr><td>16</td><td>Dr. Arvind Burande</td><td>Mar-22</td><td>Study Of Buying Behaviour Of The Investors Towards Mutual Funds</td><td>ANVESAK _x000D_</td><td>0378 – 4568</td><td>Vol. 52, No. 3 (III)</td><td>Scopus</td></tr>
																	<tr><td>17</td><td>Dr. Arvind Burande</td><td>Feb-22</td><td>A Comparative Study of Consumer Perception toward Chines and Indian Electronic Items</td><td>Journal of the Maharaja Sayajirao University of Baroda</td><td>0025-0422 </td><td>Vol. 56 No.2 (II) 2022</td><td>UGC</td></tr>
																	<tr><td>18</td><td>Prof. Trupti Joshi</td><td>Jan-22</td><td>Awareness and Utilization of Digital Wallets among residents of Navi Mumbai </td><td>Shod Samhita </td><td>2277-7067 </td><td>Volume - IX, Issue -I (I), 2022 2. </td><td>UGC</td></tr>
																	<tr><td>19</td><td>Prof. Trupti Joshi</td><td>Mar-22</td><td>Study of New Normal and Financial Stability of faculty In Higher Education Institutions during COVID-19 Pandemic Situation: Challenges and Opportunities</td><td>Asian Journal of Organic &amp; Medical Chemistry </td><td>2456-8937</td><td>Vol. 7, No. 1, (January- March Special Issue 2022)</td><td>UGC</td></tr>
																	<tr><td>20</td><td>Prof. Trupti Joshi</td><td>Mar-22</td><td>Study Of Risk Management In Various Mutual Funds Schemes</td><td>Journal Of Education: Rabindra Bharati University</td><td>0972-7175</td><td>Vol. XXV , No. :3 (III), 2022</td><td>UGC</td></tr>
																	<tr><td>21</td><td>Prof. Tanay Kurode</td><td>Dec-21</td><td>Recharging Economy</td><td>Yojana</td><td>0971-8400</td><td>December 2021 Issue</td><td>UGC</td></tr>
																	<tr><td>22</td><td>Prof. Purva Agarwal</td><td>Dec-21</td><td>Women Entrepreneurship</td><td>Yojana</td><td>971-8400</td><td>December 2021 Issue</td><td>UGC</td></tr>
																	<tr><td>23</td><td>Prof. Purva Agarwal</td><td>Mar-22</td><td>To Study The Factors Affecting B2B Buying Decision of Sales Force CRM</td><td>Wesleyan Journal of Research, </td><td>0975-1386</td><td>Vol.15 No.3 </td><td>UGC</td></tr>
																	<tr><td>24</td><td>Prof. Anuja Limbad</td><td>Mar-22</td><td>The Study Of Consumer Perception Towards Insurance Products &amp; Sector In Nashik</td><td>ANVESAK _x000D_</td><td>0378 – 4568</td><td>Vol. 52, No. 3 (III) January – June 2022</td><td>UGC</td></tr>
																	<tr><td>25</td><td>Prof. Anuja Limbad</td><td>Aug-21</td><td>Barriers To Innovation As Impediments To The Innovation Process In The Food Processing Businesses In Maharashtra</td><td>International Journal of Education, Modern Management, Applied Science &amp; Social Science </td><td>2581-9925</td><td>Volume 03, No. 03(I)</td><td>UGC</td></tr>
																	<tr><td>26</td><td>Prof. Umesh Gramopadhyay</td><td>Mar-22</td><td>A Study Of Key Trends And Challenges Faced By It Companies In Recruitment And Selection Process</td><td>Wesleyan Journal of Research</td><td>0975-1386</td><td>Vol.15 No.3 </td><td>UGC</td></tr>
																	<tr><td>27</td><td>Dr. Mrityunjay Kumar</td><td>Mar-22</td><td>A Study On Best Practices Of Digital Marketing In Pune Region</td><td>Journal Of Education: Rabindra Bharati University</td><td> 0972-7175</td><td>Vol.: XXV, No. :3(III), 2022</td><td>UGC</td></tr>
																</tbody></Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="1">
														<Accordion.Header>Research Publication 2019-20</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr role="row"><td rowspan="1" colspan="1"><b>S.No.</b></td><td rowspan="1" colspan="1"><b>Name of Faculty</b></td><td rowspan="1" colspan="1"><b>Month/<b></b></b></td><td rowspan="1" colspan="1"><b>Title of Paper</b></td><td rowspan="1" colspan="1"><b>Journal</b></td><td rowspan="1" colspan="1"><b>ISSN/ISBN</b></td><td rowspan="1" colspan="1"><b>Details</b></td><td rowspan="1" colspan="1"><b>Indexing</b></td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">1</td><td rowspan="1" colspan="1">Dr. Renu&nbsp; Bhargava </td><td rowspan="1" colspan="1">Jan-20</td><td rowspan="1" colspan="1">Role of Management Education in Supporting Sustainable Development Goals: Case Based Research</td><td rowspan="1" colspan="1">Our Heritage</td><td rowspan="1" colspan="1">0474-9030</td><td rowspan="1" colspan="1">Vol 68, Issue 1.</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">2</td><td rowspan="1" colspan="1">Dr. Renu&nbsp; Bhargava </td><td rowspan="1" colspan="1">Dec-19</td><td rowspan="1" colspan="1">A study and streamline the process of training need identification in consulting sector, Pune region </td><td rowspan="1" colspan="1">IJRAR</td><td rowspan="1" colspan="1">2349-5138</td><td rowspan="1" colspan="1">Volume 6, Issue 4 </td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">3</td><td rowspan="1" colspan="1">Dr. Renu&nbsp; Bhargava </td><td rowspan="1" colspan="1">May-19</td><td rowspan="1" colspan="1">CORPORATE SOCIAL RESPONSIBILITY &amp; EMPLOYEE ENGAGEMENT: EXPLORING THE CONNECTION</td><td rowspan="1" colspan="1">IJRAR</td><td rowspan="1" colspan="1">2349-5139</td><td rowspan="1" colspan="1">Volume 6, Issue 2</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">4</td><td rowspan="1" colspan="1">Prof. Manmohan Vyas</td><td rowspan="1" colspan="1">Nov-19</td><td rowspan="1" colspan="1">Sustainable Development - Relevance of Gandhian Thoughts</td><td rowspan="1" colspan="1">Think India Journal</td><td rowspan="1" colspan="1">0971-1260</td><td rowspan="1" colspan="1">Vol-22, Issue-10-November-2019</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">5</td><td rowspan="1" colspan="1">Prof. Manmohan Vyas</td><td rowspan="1" colspan="1">Jan-20</td><td rowspan="1" colspan="1">Corporate Governance &amp; Business Ethics - A Strategic Perspective</td><td rowspan="1" colspan="1">Sodh Sanchar Bulletin</td><td rowspan="1" colspan="1">2229-3620</td><td rowspan="1" colspan="1">Vol-10, Issue 37, Jan-March, 2020</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">6</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Dec-19</td><td rowspan="1" colspan="1">Women as Change Agents &amp; Pillar to Sustainable Development</td><td rowspan="1" colspan="1">Think India Journal</td><td rowspan="1" colspan="1">0971-1260</td><td rowspan="1" colspan="1">Vol-22, Issue-14-December-2019</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">7</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Mar-20</td><td rowspan="1" colspan="1">Financial Transactions in the Banking Industry- Various Digital Initiatives at Axis Bank</td><td rowspan="1" colspan="1">International Journal of Recent Technology and Engineering (IJRTE)</td><td rowspan="1" colspan="1">2277-3878</td><td rowspan="1" colspan="1">Volume-8 Issue-6, </td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">8</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Jun-20</td><td rowspan="1" colspan="1">The Effect of Change on Managers on account of Acquisition</td><td rowspan="1" colspan="1">KALA Journal of Indian Art History Congress</td><td rowspan="1" colspan="1"> 0975-7945</td><td rowspan="1" colspan="1">Vol-26, Issue-1, June 20</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">9</td><td rowspan="1" colspan="1">Dr. Komal Singh and Prof. Manmohan Vyas</td><td rowspan="1" colspan="1">Jun-20</td><td rowspan="1" colspan="1">Corporate Governance: - Need For Transition And Developing Economies </td><td rowspan="1" colspan="1">KALA Journal of Indian Art History Congress</td><td rowspan="1" colspan="1"> 0975-7945</td><td rowspan="1" colspan="1">Vol-26, Issue-1, June 20</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">10</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Oct-19</td><td rowspan="1" colspan="1">An in-depth study of the period end closing process of Schlumberger Limited in order to redesign and streamline the activities therein</td><td rowspan="1" colspan="1">JETIR</td><td rowspan="1" colspan="1">2349-5162</td><td rowspan="1" colspan="1">Volume 6, Issue 10</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">11</td><td rowspan="1" colspan="1">Dr. Yogesh Mahajan</td><td rowspan="1" colspan="1">May-20</td><td rowspan="1" colspan="1">Review of buy orders and returns from Amazon.in in India: Implications for Amazon and its vendors</td><td rowspan="1" colspan="1">Journal of Xi'an University of Architecture &amp; Technology</td><td rowspan="1" colspan="1">1006-7930</td><td rowspan="1" colspan="1">Volume-12 Issue-6, May 20</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">12</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Jan-20</td><td rowspan="1" colspan="1">Education marketing: A Bibliometric study</td><td rowspan="1" colspan="1">Sodh Sarita</td><td rowspan="1" colspan="1">2348-2397</td><td rowspan="1" colspan="1">Volume - 7 Issue-25, Jan - Mar 20</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">13</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Feb-20</td><td rowspan="1" colspan="1">A Study on Effectiveness of Content Marketing in Brand Promotions</td><td rowspan="1" colspan="1">International Journal of Management</td><td rowspan="1" colspan="1">0976-6510</td><td rowspan="1" colspan="1">Volume 11 issue 2</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">14</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Jan-20</td><td rowspan="1" colspan="1">A Study on Effectiveness of Advertisement's With Reference to Print Advertisements</td><td rowspan="1" colspan="1">International Journal of Management</td><td rowspan="1" colspan="1">0976-6510</td><td rowspan="1" colspan="1">Volume 11 issue 1</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">15</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">2020</td><td rowspan="1" colspan="1">A Study of The Effect Of Financial Inclusion And Digitization on Retail Banking Sales</td><td rowspan="1" colspan="1">Webology</td><td rowspan="1" colspan="1">1735-188X</td><td rowspan="1" colspan="1">Volume 17, Number 1</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">16</td><td rowspan="1" colspan="1">Prof. Amrita Karnawat</td><td rowspan="1" colspan="1">Jan-20</td><td rowspan="1" colspan="1">A Study on Relationship of Financial health &amp; corporate performance of Indian listed IT companies</td><td rowspan="1" colspan="1">Our Heritage UGC listed</td><td rowspan="1" colspan="1">0474-9030</td><td rowspan="1" colspan="1">Vol-68-Issue-1-January-2020</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">17</td><td rowspan="1" colspan="1">Prof. Amrita Karnawat</td><td rowspan="1" colspan="1">Jan-20</td><td rowspan="1" colspan="1">A study on understanding the mindset of MBA students in Wakad area of Pune towards Entrepreneurship</td><td rowspan="1" colspan="1">Sodh Sanchar Bulletin</td><td rowspan="1" colspan="1">2229-3620</td><td rowspan="1" colspan="1">vol 7, issue 25, Jan- March 2020</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">18</td><td rowspan="1" colspan="1">Dr Kalpana Deshmukh</td><td rowspan="1" colspan="1">Mar-20</td><td rowspan="1" colspan="1">Due Diligence Deployment Model for HR transformation </td><td rowspan="1" colspan="1">International Journal of Recent Technology and Engineering (IJRTE)</td><td rowspan="1" colspan="1">2277-3878&nbsp;</td><td rowspan="1" colspan="1">Volume-8 Issue-6</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">19</td><td rowspan="1" colspan="1">Prof.Tanay Kurode</td><td rowspan="1" colspan="1">Jan-20</td><td rowspan="1" colspan="1">Bibliometric Review of Sustainability of Social Enterprises</td><td rowspan="1" colspan="1">Our Heritage</td><td rowspan="1" colspan="1">0474-9030</td><td rowspan="1" colspan="1">Vol 68, Issue 1-January 2020</td><td rowspan="1" colspan="1">UGC</td></tr>



																</tbody></Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="2">
														<Accordion.Header>Research Publication 2020-21</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr role="row"><td rowspan="1" colspan="1"><b>S.No.</b></td><td rowspan="1" colspan="1"><b>Name of Faculty</b></td><td rowspan="1" colspan="1"><b>Month</b></td><td rowspan="1" colspan="1"><b>Title of Paper</b></td><td rowspan="1" colspan="1"><b>Journal</b></td><td rowspan="1" colspan="1"><b>ISSN/ISBN</b></td><td rowspan="1" colspan="1"><b>Details</b></td><td rowspan="1" colspan="1"><b>Indexing</b></td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">1</td><td rowspan="1" colspan="1">Dr. Manmohan Vyas</td><td rowspan="1" colspan="1">Apr-21</td><td rowspan="1" colspan="1">Is Service Innovation Through Digitization &amp; E-Commerce Creating Challenges: Taxation Persp</td><td rowspan="1" colspan="1">George Washington International Law Review</td><td rowspan="1" colspan="1">1534-9977</td><td rowspan="1" colspan="1">Vol 7, Issue 1, April 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">2</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Mar-21</td><td rowspan="1" colspan="1">“E-Banking: Risks &amp; Strategies-A Comparative study of PSBs, PSIBs &amp; PSFBs” </td><td rowspan="1" colspan="1">Sambodhi</td><td rowspan="1" colspan="1">2249-6661</td><td rowspan="1" colspan="1">Vol 44, Issue 1(III), March 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">3</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Mar-21</td><td rowspan="1" colspan="1">Progress of Banking in India: Customer’s Perspectives </td><td rowspan="1" colspan="1">Wesleyan Journal of Research</td><td rowspan="1" colspan="1">0975-1386</td><td rowspan="1" colspan="1">Vol 134 Issue 1, Jan-March 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">4</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Jul-05</td><td rowspan="1" colspan="1">Various Facets of New Tax Reforms in India </td><td rowspan="1" colspan="1">Journal of Seybold Report</td><td rowspan="1" colspan="1">1533-9211</td><td rowspan="1" colspan="1">Vol-15, Issue-8, 2020</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">5</td><td rowspan="1" colspan="1">Dr. Yogesh Mahajan</td><td rowspan="1" colspan="1">Dec-21</td><td rowspan="1" colspan="1">Impact of Coronavirus Pandemic on Fast Moving Consumer Goods (FMCG) Sector in India</td><td rowspan="1" colspan="1">Shodh Sanchar Bulletin</td><td rowspan="1" colspan="1">2229-3620</td><td rowspan="1" colspan="1">Vol 10, Issue 40, Dec 2020</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">6</td><td rowspan="1" colspan="1">Dr. Yogesh Mahajan</td><td rowspan="1" colspan="1">Feb-21</td><td rowspan="1" colspan="1">Buzz Marketing: Bibliometric Analysis</td><td rowspan="1" colspan="1">Indian Journal of Marketing</td><td rowspan="1" colspan="1">0973-8703</td><td rowspan="1" colspan="1">Volume 51, Issue 2, February 2021</td><td rowspan="1" colspan="1">ABDC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">7</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Jan-21</td><td rowspan="1" colspan="1">Review of Literature Landscape of AI and Marketing- A bibliometric Study</td><td rowspan="1" colspan="1">Wesleyan Journal of Research</td><td rowspan="1" colspan="1">0975-1386</td><td rowspan="1" colspan="1">Vol.14 No.01 January 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">8</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Jul-05</td><td rowspan="1" colspan="1">A study on tourist loyalty towards medical services in India</td><td rowspan="1" colspan="1">Design Engineering</td><td rowspan="1" colspan="1">0011-9342</td><td rowspan="1" colspan="1">Issue 6, 2021</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">9</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">2021</td><td rowspan="1" colspan="1">A study on factors and their prioritization affecting customer attraction towards medical tourism</td><td rowspan="1" colspan="1">Design Engineering</td><td rowspan="1" colspan="1">0011-9342</td><td rowspan="1" colspan="1">Issue 6, 2021</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">10</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Jun-21</td><td rowspan="1" colspan="1">A study on effectiveness of content marketing in Brand Promotions </td><td rowspan="1" colspan="1">George Washington International Law Review</td><td rowspan="1" colspan="1">1534-9977</td><td rowspan="1" colspan="1">Vol 7, Issue 1</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">11</td><td rowspan="1" colspan="1">Prof. Amrita Karnawat</td><td rowspan="1" colspan="1">Jan-21</td><td rowspan="1" colspan="1">An analytical study on identifying value-pick companies from bse_fmcg index </td><td rowspan="1" colspan="1">Wesleyan Journal of Research</td><td rowspan="1" colspan="1">0975-1386</td><td rowspan="1" colspan="1">Vol.14 No.01(V), January 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">12</td><td rowspan="1" colspan="1">Prof. Amrita Karnawat</td><td rowspan="1" colspan="1">Apr-21</td><td rowspan="1" colspan="1">Impact Analysis of financial ratios on Financial Distress of Selected Merged Indian Pharmaceutical listed Companies: A ratio lead for capitalism</td><td rowspan="1" colspan="1">George Washington International Review</td><td rowspan="1" colspan="1">1534-9977</td><td rowspan="1" colspan="1">Vol 7, Issue 1, April 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">13</td><td rowspan="1" colspan="1">Dr. Kalpana Deshmukh</td><td rowspan="1" colspan="1">May-21</td><td rowspan="1" colspan="1">An effective communication skill is required for greater employability during PG course</td><td rowspan="1" colspan="1">The Journal of Oriental Research</td><td rowspan="1" colspan="1">0022-3301</td><td rowspan="1" colspan="1">Vol MMXXI, Issue 1, May 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">14</td><td rowspan="1" colspan="1">Dr. Kalpana Deshmukh</td><td rowspan="1" colspan="1">Aug-21</td><td rowspan="1" colspan="1">“Cloud Sharing System: CLOUD COMPUTING INFORMATION SHARING SYSTEM IN REAL-TIME”. </td><td rowspan="1" colspan="1">Controller General of&nbsp;Patents Design and Trade and Marks Govt. of India.&nbsp;</td><td rowspan="1" colspan="1">Patent number : 202041030793</td><td rowspan="1" colspan="1">21-8-2021, issue no. 34/2020</td><td rowspan="1" colspan="1"> Govt. of India</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">15</td><td rowspan="1" colspan="1">Dr. Kalpana Deshmukh</td><td rowspan="1" colspan="1">Jan-21</td><td rowspan="1" colspan="1"> An exploration into Harrison assessment tools and their use in talent management and development</td><td rowspan="1" colspan="1">National Research Journal of Business Economics</td><td rowspan="1" colspan="1">2349-2015</td><td rowspan="1" colspan="1">Vol. 8, Issue. 1</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">16</td><td rowspan="1" colspan="1">Dr. Kalpana Deshmukh</td><td rowspan="1" colspan="1">Jan-21</td><td rowspan="1" colspan="1">Sustaining Active Engagement in Personal Life, The Study Focused on Working Women and WLB</td><td rowspan="1" colspan="1">Shodh Sarita</td><td rowspan="1" colspan="1">2348-2397</td><td rowspan="1" colspan="1">Vol.8, Issue 29</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">17</td><td rowspan="1" colspan="1">Dr. Abhijit Bobde</td><td rowspan="1" colspan="1">Jul-05</td><td rowspan="1" colspan="1">Influence of Behavioural Biases on Decision Making process while making investment in Insurance in Indian Context.</td><td rowspan="1" colspan="1">Elementary Education Online</td><td rowspan="1" colspan="1">1305-3515</td><td rowspan="1" colspan="1">Vol.20 Issue 1, 2021</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">18</td><td rowspan="1" colspan="1">Dr. Abhijit Bobde</td><td rowspan="1" colspan="1">Jul-05</td><td rowspan="1" colspan="1">Information and Communication Technology (ICT) Enabled Higher Education: Current Trends and Challenges</td><td rowspan="1" colspan="1">Elementary Education Online</td><td rowspan="1" colspan="1">1305-3515</td><td rowspan="1" colspan="1">Vol.20 Issue 1, 2021</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">19</td><td rowspan="1" colspan="1">Dr. Abhijit Bobde</td><td rowspan="1" colspan="1">Apr-21</td><td rowspan="1" colspan="1">A Simplified Perspective of Business Analytics- The Contemporary Business Solution Model</td><td rowspan="1" colspan="1">The Journal Of Oriental Research Madras</td><td rowspan="1" colspan="1">0022-3301</td><td rowspan="1" colspan="1">Vol.XCII-IV, April 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">20</td><td rowspan="1" colspan="1">Dr. Abhijit Bobde</td><td rowspan="1" colspan="1">Nov-20</td><td rowspan="1" colspan="1">A Study on Consumer Behaviour, Awareness and Perception in working Professionals towards term Life insurance</td><td rowspan="1" colspan="1">Journal of Emerging Technologies and Innovative Research </td><td rowspan="1" colspan="1">2349-5162</td><td rowspan="1" colspan="1">Vol 7, Issue 11</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">21</td><td rowspan="1" colspan="1">Prof. Varsha Bihade</td><td rowspan="1" colspan="1">Apr-21</td><td rowspan="1" colspan="1">A Study of Digital Banking Services Amid Covid-19- A Consumer Perspective</td><td rowspan="1" colspan="1">Sambodhi</td><td rowspan="1" colspan="1">2249-6661</td><td rowspan="1" colspan="1">Vol.44 Issue 1, April 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">22</td><td rowspan="1" colspan="1">Prof. Varsha Bihade</td><td rowspan="1" colspan="1">Apr-21</td><td rowspan="1" colspan="1">Customer Satisfaction Towards Online Food Delivery Apps (FDAS) In Pune Amid Covid-19-A New Service Dimension</td><td rowspan="1" colspan="1">Journal of the Maharaj Sayajirao University of Baroda</td><td rowspan="1" colspan="1">0025-0422</td><td rowspan="1" colspan="1">Vol.55 Issue 01 April 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">23</td><td rowspan="1" colspan="1">Prof. Varsha Bihade</td><td rowspan="1" colspan="1">Aug-20</td><td rowspan="1" colspan="1">Customer Perception towards Green Banking services with special reference towards Green Banking Service in Pune Region</td><td rowspan="1" colspan="1">International Journal for research in Engineering Application and Management</td><td rowspan="1" colspan="1">2452-9150</td><td rowspan="1" colspan="1">Vol.6 Issue:5 August 2020</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">24</td><td rowspan="1" colspan="1">Prof. Sakshi Parihar</td><td rowspan="1" colspan="1">Nov-20</td><td rowspan="1" colspan="1">To study inside sales and email marketing at IT </td><td rowspan="1" colspan="1">International Journal of Applied Research</td><td rowspan="1" colspan="1">2394-7500</td><td rowspan="1" colspan="1">Vol 6, Issue 11</td><td rowspan="1" colspan="1">Peer Reviewed Journal</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">25</td><td rowspan="1" colspan="1">Prof. Tanay Kurode</td><td rowspan="1" colspan="1">Jan-21</td><td rowspan="1" colspan="1">Accessing the awareness levels of goal based financial planning among individuals in Nagpur city</td><td rowspan="1" colspan="1">National Research Journal of Banking and Finance Management</td><td rowspan="1" colspan="1">2349-6762</td><td rowspan="1" colspan="1">Vol. 8, Issue. 1</td><td rowspan="1" colspan="1">Peer Reviewed Journal</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">26</td><td rowspan="1" colspan="1">Prof. Arvind Burande</td><td rowspan="1" colspan="1">Jun-21</td><td rowspan="1" colspan="1"> To study the current and future potential in Indian online education market</td><td rowspan="1" colspan="1">National Research Journal of Human Resource Management</td><td rowspan="1" colspan="1">2349-2015</td><td rowspan="1" colspan="1">Vol. 8, Issue. 2</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">27</td><td rowspan="1" colspan="1">Dr. Mrityunjay Kumar</td><td rowspan="1" colspan="1">Jun-21</td><td rowspan="1" colspan="1"> Best practices of digital marketing: a study at thedigitalfellow.com</td><td rowspan="1" colspan="1">National Research Journal of Sales &amp; Marketing Management</td><td rowspan="1" colspan="1">2349-2015</td><td rowspan="1" colspan="1">Vol. 8, Issue. 1</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">28</td><td rowspan="1" colspan="1">Dr. Mrityunjay Kumar</td><td rowspan="1" colspan="1">Mar-21</td><td rowspan="1" colspan="1">The Role of Entrepreneurial Self Efficacy in Business Management Students' Entrepreneurial Venture Motive</td><td rowspan="1" colspan="1">Sodh Sarita</td><td rowspan="1" colspan="1">2348-2397</td><td rowspan="1" colspan="1">Vol.8, Issue 29</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">29</td><td rowspan="1" colspan="1">Prof. Purva Agarwal</td><td rowspan="1" colspan="1">Oct-20</td><td rowspan="1" colspan="1">Measure the Effectiveness of Infomercial vs. Commercial Influence Consumer Buying</td><td rowspan="1" colspan="1">Journal of Emerging Technologies and Innovative Research </td><td rowspan="1" colspan="1">2349-5162</td><td rowspan="1" colspan="1">Vol 7, Issue 10</td><td rowspan="1" colspan="1">UGC</td></tr>



																</tbody></Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="3">
														<Accordion.Header>Research Publication 2021-22</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr role="row"><td rowspan="1" colspan="1"><b>S.No.</b></td><td rowspan="1" colspan="1"><b>Name of Faculty</b></td><td rowspan="1" colspan="1"><b>Month</b></td><td rowspan="1" colspan="1"><b>Title of Paper</b></td><td rowspan="1" colspan="1"><b>Journal</b></td><td rowspan="1" colspan="1"><b>ISSN/ISBN</b></td><td rowspan="1" colspan="1"><b>Details</b></td><td rowspan="1" colspan="1"><b>Indexing</b></td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">1</td><td rowspan="1" colspan="1">Dr. Renu Bhargava and Dr. Kalpana Deshmukh</td><td rowspan="1" colspan="1">Apr-22</td><td rowspan="1" colspan="1">Case-Based Research on Enhancing Student Awareness of Sustainable Development Goals in B-Schools</td><td rowspan="1" colspan="1">International Journal of Early Childhood Special education</td><td rowspan="1" colspan="1">1308-5581</td><td rowspan="1" colspan="1">Vol.14, Issue 03 2022</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">2</td><td rowspan="1" colspan="1">Dr. Renu Bhargava and Dr. Komal Singh</td><td rowspan="1" colspan="1">Jan-22</td><td rowspan="1" colspan="1">“A STUDY ON EMPLOYEE EXPERIENCE PLATFORMS FOR THE DIGITAL NATIVE ORGANIZATIONS”</td><td rowspan="1" colspan="1">Anvesak</td><td rowspan="1" colspan="1">ISSN : 0378 – 4568</td><td rowspan="1" colspan="1">Vol. 52, No. 5 (I) </td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">3</td><td rowspan="1" colspan="1">Dr. Komal Singh and Dr. Manmohan Vyas</td><td rowspan="1" colspan="1">20-22</td><td rowspan="1" colspan="1">Critical Review Of Corporate Governance Impact On Company's Performance (A Comparative Study Of It Companies)</td><td rowspan="1" colspan="1">Korea Review Of International Studies</td><td rowspan="1" colspan="1">1226-4741</td><td rowspan="1" colspan="1">Volume 15 , Issue 01</td><td rowspan="1" colspan="1">ABDC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">4</td><td rowspan="1" colspan="1">Dr. Komal Singh</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">Technical And Fundamental Analysis Of Selected Pharmaceutical Companies</td><td rowspan="1" colspan="1">Journal Of Education: Rabindra Bharati University</td><td rowspan="1" colspan="1">0972-7175</td><td rowspan="1" colspan="1">Vol.: XXV, No. :3(III), 2022</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">5</td><td rowspan="1" colspan="1">Dr.Manmohan Vyas</td><td rowspan="1" colspan="1">Jul-21</td><td rowspan="1" colspan="1">Corporate Governance &amp; Company Performance: A Study On Relevance. </td><td rowspan="1" colspan="1">Anvesak </td><td rowspan="1" colspan="1">0378-4568 </td><td rowspan="1" colspan="1">Vol 51: No 2</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">6</td><td rowspan="1" colspan="1">Dr.Manmohan Vyas</td><td rowspan="1" colspan="1">Oct-21</td><td rowspan="1" colspan="1">Infosys - A journey of values, evolving business models and corporate governance</td><td rowspan="1" colspan="1">NIU International journal</td><td rowspan="1" colspan="1">2394-00298</td><td rowspan="1" colspan="1">vol 8 - 2021</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">7</td><td rowspan="1" colspan="1">Dr.Manmohan Vyas</td><td rowspan="1" colspan="1">Feb-21</td><td rowspan="1" colspan="1">A Study On Multiskilling Of Employees In Manufacturing Sector</td><td rowspan="1" colspan="1">Journal Of Education: Rabindra Bharati University</td><td rowspan="1" colspan="1">0972-7175</td><td rowspan="1" colspan="1">XXX, No. 2(II)</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">8</td><td rowspan="1" colspan="1">Dr.Manmohan Vyas</td><td rowspan="1" colspan="1">Feb-22</td><td rowspan="1" colspan="1">Impact of social media marketing on performance of micro and small businesses</td><td rowspan="1" colspan="1">Anvesak</td><td rowspan="1" colspan="1">0378-4568</td><td rowspan="1" colspan="1">52, no 2 (III)</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">9</td><td rowspan="1" colspan="1">Dr. Yogesh Mahajan</td><td rowspan="1" colspan="1">Sep-21</td><td rowspan="1" colspan="1">To study the impact of coronavirus pandemic on manufacturing sector in India</td><td rowspan="1" colspan="1">Design Engineering</td><td rowspan="1" colspan="1">0011-9342</td><td rowspan="1" colspan="1">Vol 2021: Issue 07 / </td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">10</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Apr-22</td><td rowspan="1" colspan="1">Corporate Ethics - Misuse of Institutional Sales</td><td rowspan="1" colspan="1">Third Concept</td><td rowspan="1" colspan="1">0970 7247</td><td rowspan="1" colspan="1">Volume 36, No 422</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">11</td><td rowspan="1" colspan="1">Dr. Tausif Mistry</td><td rowspan="1" colspan="1">Apr-22</td><td rowspan="1" colspan="1">Learning In Times of Crisis: A Comprehensive Literature Review</td><td rowspan="1" colspan="1">Journal of Management and Entrepreneureship</td><td rowspan="1" colspan="1">2229-5348</td><td rowspan="1" colspan="1">Vol.16, No.2 (II)</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">12</td><td rowspan="1" colspan="1">Prof. Amrita Karnawat</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">A Financial Performance Analysis Of Itc Ltd By Using Ratios And Altman Z Score Model</td><td rowspan="1" colspan="1">Wesleyan Journal of Research</td><td rowspan="1" colspan="1">0975-1386</td><td rowspan="1" colspan="1">Vol.15 No.3 </td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">13</td><td rowspan="1" colspan="1">Prof. Amrita Karnawat</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">A Financial Performance Analysis Of Itc Ltd By Using Ratios And Altman Z Score Model</td><td rowspan="1" colspan="1">Wesleyan Journal of Research</td><td rowspan="1" colspan="1">0975-1386</td><td rowspan="1" colspan="1">Vol.15 No.3 </td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">14</td><td rowspan="1" colspan="1">Dr Kalpana Deshmukh</td><td rowspan="1" colspan="1">Dec-21</td><td rowspan="1" colspan="1">A Study on Continuous Process of R and S with Reference to Manufacturing Industries in Maharashtra State</td><td rowspan="1" colspan="1">Design Engineering (Toronto)</td><td rowspan="1" colspan="1">0011-9342</td><td rowspan="1" colspan="1">Vol 2021: Issue 09</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">15</td><td rowspan="1" colspan="1">Dr Kalpana Deshmukh</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">A Study Of Campus Recruitment Process At Acura Solutions, Mulund</td><td rowspan="1" colspan="1">ANVESAK </td><td rowspan="1" colspan="1">0378 – 4568</td><td rowspan="1" colspan="1">Vol. 52, No. 3 (III) January – June 2022</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">16</td><td rowspan="1" colspan="1">Dr. Arvind Burande</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">Study Of Buying Behaviour Of The Investors Towards Mutual Funds</td><td rowspan="1" colspan="1">ANVESAK _x000D_</td><td rowspan="1" colspan="1">0378 – 4568</td><td rowspan="1" colspan="1">Vol. 52, No. 3 (III)</td><td rowspan="1" colspan="1">Scopus</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">17</td><td rowspan="1" colspan="1">Dr. Arvind Burande</td><td rowspan="1" colspan="1">Feb-22</td><td rowspan="1" colspan="1">A Comparative Study of Consumer Perception toward Chines and Indian Electronic Items</td><td rowspan="1" colspan="1">Journal of the Maharaja Sayajirao University of Baroda</td><td rowspan="1" colspan="1">0025-0422 </td><td rowspan="1" colspan="1">Vol. 56 No.2 (II) 2022</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">18</td><td rowspan="1" colspan="1">Prof. Trupti Joshi</td><td rowspan="1" colspan="1">Jan-22</td><td rowspan="1" colspan="1">Awareness and Utilization of Digital Wallets among residents of Navi Mumbai </td><td rowspan="1" colspan="1">Shod Samhita </td><td rowspan="1" colspan="1">2277-7067 </td><td rowspan="1" colspan="1">Volume - IX, Issue -I (I), 2022 2. </td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">19</td><td rowspan="1" colspan="1">Prof. Trupti Joshi</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">Study of New Normal and Financial Stability of faculty In Higher Education Institutions during COVID-19 Pandemic Situation: Challenges and Opportunities</td><td rowspan="1" colspan="1">Asian Journal of Organic &amp; Medical Chemistry </td><td rowspan="1" colspan="1">2456-8937</td><td rowspan="1" colspan="1">Vol. 7, No. 1, (January- March Special Issue 2022)</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">20</td><td rowspan="1" colspan="1">Prof. Trupti Joshi</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">Study Of Risk Management In Various Mutual Funds Schemes</td><td rowspan="1" colspan="1">Journal Of Education: Rabindra Bharati University</td><td rowspan="1" colspan="1">0972-7175</td><td rowspan="1" colspan="1">Vol. XXV , No. :3 (III), 2022</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">21</td><td rowspan="1" colspan="1">Prof. Tanay Kurode</td><td rowspan="1" colspan="1">Dec-21</td><td rowspan="1" colspan="1">Recharging Economy</td><td rowspan="1" colspan="1">Yojana</td><td rowspan="1" colspan="1">0971-8400</td><td rowspan="1" colspan="1">December 2021 Issue</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">22</td><td rowspan="1" colspan="1">Prof. Purva Agarwal</td><td rowspan="1" colspan="1">Dec-21</td><td rowspan="1" colspan="1">Women Entrepreneurship</td><td rowspan="1" colspan="1">Yojana</td><td rowspan="1" colspan="1">971-8400</td><td rowspan="1" colspan="1">December 2021 Issue</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">23</td><td rowspan="1" colspan="1">Prof. Purva Agarwal</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">To Study The Factors Affecting B2B Buying Decision of Sales Force CRM</td><td rowspan="1" colspan="1">Wesleyan Journal of Research, </td><td rowspan="1" colspan="1">0975-1386</td><td rowspan="1" colspan="1">Vol.15 No.3 </td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">24</td><td rowspan="1" colspan="1">Prof. Anuja Limbad</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">The Study Of Consumer Perception Towards Insurance Products &amp; Sector In Nashik</td><td rowspan="1" colspan="1">ANVESAK _x000D_</td><td rowspan="1" colspan="1">0378 – 4568</td><td rowspan="1" colspan="1">Vol. 52, No. 3 (III) January – June 2022</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">25</td><td rowspan="1" colspan="1">Prof. Anuja Limbad</td><td rowspan="1" colspan="1">Aug-21</td><td rowspan="1" colspan="1">Barriers To Innovation As Impediments To The Innovation Process In The Food Processing Businesses In Maharashtra</td><td rowspan="1" colspan="1">International Journal of Education, Modern Management, Applied Science &amp; Social Science </td><td rowspan="1" colspan="1">2581-9925</td><td rowspan="1" colspan="1">Volume 03, No. 03(I)</td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">26</td><td rowspan="1" colspan="1">Prof. Umesh Gramopadhyay</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">A Study Of Key Trends And Challenges Faced By It Companies In Recruitment And Selection Process</td><td rowspan="1" colspan="1">Wesleyan Journal of Research</td><td rowspan="1" colspan="1">0975-1386</td><td rowspan="1" colspan="1">Vol.15 No.3 </td><td rowspan="1" colspan="1">UGC</td></tr>
																	<tr role="row"><td rowspan="1" colspan="1">27</td><td rowspan="1" colspan="1">Dr. Mrityunjay Kumar</td><td rowspan="1" colspan="1">Mar-22</td><td rowspan="1" colspan="1">A Study On Best Practices Of Digital Marketing In Pune Region</td><td rowspan="1" colspan="1">Journal Of Education: Rabindra Bharati University</td><td rowspan="1" colspan="1"> 0972-7175</td><td rowspan="1" colspan="1">Vol.: XXV, No. :3(III), 2022</td><td rowspan="1" colspan="1">UGC</td></tr>
																</tbody></Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="4">
														<Accordion.Header>Research Publication 2022-23</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr role="row"><td rowspan="1" colspan="1"><b>S.No.</b></td><td rowspan="1" colspan="1"><b>Name of Faculty</b></td><td rowspan="1" colspan="1"><b>Month</b></td><td rowspan="1" colspan="1"><b>Title of Paper</b></td><td rowspan="1" colspan="1"><b>Journal</b></td><td rowspan="1" colspan="1"><b>ISSN/ISBN</b></td><td rowspan="1" colspan="1"><b>Details</b></td><td rowspan="1" colspan="1"><b>Indexing</b></td></tr>
																	<tr>
																		<td >
																			1
																		</td>
																		<td >
																			Dr. Komal Singh
																		</td>
																		<td >
																			October&nbsp;
																		</td>
																		<td >
																			Tata versus Mistry: A Boardroom Battle of Governance&nbsp;
																		</td>
																		<td >
																			Journal of Positive School Psychology
																		</td>
																		<td >
																			2717-7564
																		</td>
																		<td >
																			Volume 6, Issue 10&nbsp;
																		</td>
																		<td >
																			SCOPUS
																		</td>
																	</tr>
																	<tr>
																		<td >
																			2
																		</td>
																		<td >
																			Dr. Manmohan Vyas
																		</td>
																		<td >
																			October&nbsp;
																		</td>
																		<td >
																			Tata versus Mistry: A Boardroom Battle of Governance&nbsp;
																		</td>
																		<td >
																			Journal of Positive School Psychology
																		</td>
																		<td >
																			2717-7564
																		</td>
																		<td >
																			Volume 6, Issue 10&nbsp;
																		</td>
																		<td >
																			SCOPUS
																		</td>
																	</tr>
																	<tr>
																		<td >
																			3
																		</td>
																		<td >
																			Dr. Kalpana Deshmukh
																		</td>
																		<td >
																			December
																		</td>
																		<td >
																			Effect of employee work motivation on performance and business productivity The study focused on Electricity and Gas organization
																		</td>
																		<td >
																			YMER
																		</td>
																		<td >
																			0044-0477
																		</td>
																		<td >
																			volume 21, issue 12
																		</td>
																		<td >
																			SCOPUS
																		</td>
																	</tr>
																	<tr>
																		<td >
																			4
																		</td>
																		<td >
																			Prof. Purva Agarwal
																		</td>
																		<td >
																			April
																		</td>
																		<td >
																			Impact of Aesthetics environment in malls on Consumer Psycology
																		</td>
																		<td >
																			The Online Journal of Distance Education and e-Learning
																		</td>
																		<td >
																			2147-6454
																		</td>
																		<td >
																			Volume 11 Issue 2b<br />April 2023
																		</td>
																		<td >
																			UGC Care Group 1 Journal
																		</td>
																	</tr>
																	<tr>
																		<td >
																			5
																		</td>
																		<td >
																			Prof. Trupti Joshi
																		</td>
																		<td >
																			May&nbsp;
																		</td>
																		<td >
																			PRE AND POST EFFECT OF MERGER ON FINANCIAL PERFORMANCE: THE CASE OF MEGA MERGER BANKS
																		</td>
																		<td >
																			European Chemical Bulletin
																		</td>
																		<td >
																			2063-5346
																		</td>
																		<td >
																			Volume 12, Special issue 1, Part B
																		</td>
																		<td >
																			SCOPUS
																		</td>
																	</tr>
																	<tr>
																		<td >
																			6
																		</td>
																		<td >
																			Prof. Anuja Limbad
																		</td>
																		<td >
																			May&nbsp;
																		</td>
																		<td >
																			PRE AND POST EFFECT OF MERGER ON FINANCIAL PERFORMANCE: THE CASE OF MEGA MERGER BANKS
																		</td>
																		<td >
																			European Chemical Bulletin
																		</td>
																		<td >
																			2063-5346
																		</td>
																		<td >
																			Volume 12, Special issue 1, Part B
																		</td>
																		<td >
																			SCOPUS
																		</td>
																	</tr>
																	<tr>
																		<td >
																			7
																		</td>
																		<td >
																			Dr. Manisha Maddel
																		</td>
																		<td >
																			October&nbsp;
																		</td>
																		<td >
																			Analytical Decision making in Business and the Role of Machine Learning and Artificial Intelligence
																		</td>
																		<td >
																			Seybold
																		</td>
																		<td >
																			1533-9211
																		</td>
																		<td >
																			Vol 17, No 10 ( 2022 )
																		</td>
																		<td >
																			zenodo.7157418
																		</td>
																	</tr>
																	<tr>
																		<td >
																			8
																		</td>
																		<td >
																			Dr. Manisha Maddel
																		</td>
																		<td >
																			March
																		</td>
																		<td >
																			Smart Model for Career Guidance using hybrid deep Learning &nbsp;Techniques
																		</td>
																		<td >
																			IEEE
																		</td>
																		<td >
																			ISBN:979-8-3503-4595-7
																		</td>
																		<td >
																			INSPEC Accession Number: 23163370
																		</td>
																		<td >
																			IEEE Explore
																		</td>
																	</tr>
																	<tr>
																		<td >
																			9
																		</td>
																		<td >
																			Dr. Arvind Burande
																		</td>
																		<td >
																			January-June
																		</td>
																		<td >
																			&ldquo;Consumers&apos; knowledge, attitude and its impact on consumption pattern of functional foods.&rdquo;
																		</td>
																		<td >
																			ANVESAK
																		</td>
																		<td >
																			0378-4568
																		</td>
																		<td >
																			Vol. 53, No. 01 (II) January-June 2023
																		</td>
																		<td >
																			UGC Care
																		</td>
																	</tr>
																	<tr>
																		<td >
																			10
																		</td>
																		<td >
																			Dr. Hetal Bhinde
																		</td>
																		<td >
																			January-June
																		</td>
																		<td >
																			A study on the impact of demographic factors on attributes that influence consumers buying behaviour towards food aggregator apps.
																		</td>
																		<td >
																			ANVESAK
																		</td>
																		<td >
																			0378 &ndash; 4568
																		</td>
																		<td >
																			Vol. 53, No. 01 (II) January-June 2023
																		</td>
																		<td >
																			UGC Care Group 1 Journal
																		</td>
																	</tr>
																	<tr>
																		<td >
																			11
																		</td>
																		<td >
																			Dr. Hetal Bhinde
																		</td>
																		<td >
																			January-June
																		</td>
																		<td >
																			A Study On Financial Planning And Approaches For Tax Savingintended For Salaried Employee Post COVID
																		</td>
																		<td >
																			ANVESAK
																		</td>
																		<td >
																			0378 &ndash; 4568
																		</td>
																		<td >
																			Vol. 53, No. 01 (II) January-June 2023
																		</td>
																		<td >
																			UGC Care Group 1 Journal
																		</td>
																	</tr>
																	<tr>
																		<td >
																			12
																		</td>
																		<td >
																			Dr. Hetal Bhinde
																		</td>
																		<td >
																			Feb-July&nbsp;
																		</td>
																		<td >
																			An overview of Fundamental Research Methods Applied to Market Prices of Chosen Indian IT Stocks
																		</td>
																		<td >
																			GENIUS
																		</td>
																		<td >
																			22790489
																		</td>
																		<td >
																			Vol. XI, Issue II
																		</td>
																		<td >
																			ugc Care. 47100
																		</td>
																	</tr>
																	<tr>
																		<td >
																			13
																		</td>
																		<td >
																			Dr. Hetal Bhinde
																		</td>
																		<td >
																			January
																		</td>
																		<td >
																			A trend analysis of imports and exports of cut flowers from India
																		</td>
																		<td >
																			The Online Journal of Distance Education and e-Learning
																		</td>
																		<td >
																			2147-6454
																		</td>
																		<td >
																			Volume 11 - Issue 1 - Chapter 1 - January 2023
																		</td>
																		<td >
																			UGC Care
																		</td>
																	</tr>


																</tbody>
															</Table>
														</Accordion.Body>
													</Accordion.Item>

												</Accordion>

												<h3 className="blockTitle paddingBottom1em paddingTop1em" style={{ color: '#dfa726' }}>Student Faculty Publications</h3>
												<Accordion defaultActiveKey="0">
													<Accordion.Item eventKey="0">
														<Accordion.Header>Research Publication 2018-19</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr>

																		<td><b>Sr. No.</b></td>
																		<td><b>Name of Faculty</b></td>
																		<td><b>Name of Student</b></td>
																		<td><b>Month</b></td>
																		<td><b>Title of Paper</b></td>
																		<td><b>Journal</b></td>
																		<td><b>ISSN/ISBN</b></td>
																		<td><b>Details</b></td>
																		<td><b>Indexing</b></td>
																	</tr>
																	<tr>

																		<td>1</td>
																		<td>Prof. Manmohan Vyas </td>
																		<td>Rishika Parihar </td>
																		<td>Aug-18</td>
																		<td>A study on performance evaluation of various mutual fund schemes in India </td>
																		<td>JETIR</td>
																		<td>2349-5162</td>
																		<td>Volume 5, Issue 8</td>
																		<td>Scopus</td>
																	</tr>
																	<tr>

																		<td>2</td>
																		<td>Prof. Tausif Mistry</td>
																		<td>Mrunalini Das</td>
																		<td>Feb-19</td>
																		<td>A Study of the Effect of Financial Inclusion and Digitization on Retail Banking Sales</td>
																		<td>INCUBATION - IV</td>
																		<td>978-93-88441-759</td>
																		<td>Volume 5, Issue 8</td>
																		<td>Conference proceedings</td>
																	</tr>
																	<tr>

																		<td>3</td>
																		<td>Prof. Tausif Mistry</td>
																		<td>Akshay Deokar</td>
																		<td>Jan-19</td>
																		<td>A Study on Potential Analysis of Compressed Air application in Pharmaceutical and Food</td>
																		<td>INCON XIV</td>
																		<td>978-93-88441-438</td>
																		<td>Conference Proceeding, Jan 19</td>
																		<td>Conference proceedings</td>
																	</tr>
																</tbody>
															</Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="1">
														<Accordion.Header>Research Publication 2019-20</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr>

																		<td><b>Sr. No.</b></td>
																		<td><b>Name of Faculty</b></td>
																		<td><b>Name of Student</b></td>
																		<td><b>Month</b></td>
																		<td><b>Title of Paper</b></td>
																		<td><b>Journal</b></td>
																		<td><b>ISSN</b></td>
																		<td><b>Details</b></td>
																		<td><b>Indexing</b></td>
																	</tr>
																	<tr>

																		<td>1</td>
																		<td>Dr. Renu Bhargava </td>
																		<td>Pooja Navrange </td>
																		<td>Dec-19</td>
																		<td>A stuudy and streamline the process of training need identification in consulting sector, Pune region </td>
																		<td>IJRAR</td>
																		<td>2349-5138</td>
																		<td>Volume 6, Issue 4 </td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>2</td>
																		<td>Dr. Komal Singh</td>
																		<td>Manali Joshi </td>
																		<td>Oct-19</td>
																		<td>An in-depth study of the period end closing process of Schlumberger Limited in order to redesign and streamline the activities therein</td>
																		<td>JETIR</td>
																		<td>2349-5162</td>
																		<td>Volume 6, Issue 10</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>3</td>
																		<td>Dr. Kalpana Deshmukh </td>
																		<td>Kavita Chaudhari </td>
																		<td>Mar-20</td>
																		<td>Due Deligence Deployment Model for HR Transformation </td>
																		<td>International Journal of Recent Technology and Engineering (IJRTE)</td>
																		<td>2277-3878</td>
																		<td>Volume 8, Issue 6, March 2020</td>
																		<td>Scopus</td>
																	</tr>
																	<tr>

																		<td>4</td>
																		<td>Dr. Tausif Mistry</td>
																		<td>Megha Rungta</td>
																		<td>Feb-20</td>
																		<td>A Study on Effectiveness of Content Marketing in Brand Promotions</td>
																		<td>International Journal of Management</td>
																		<td>0976-6510</td>
																		<td>Volume 11 issue 2</td>
																		<td>Scopus</td>
																	</tr>
																	<tr>

																		<td>5</td>
																		<td>Mr. Tanay Kurode</td>
																		<td>Sourabh Rathi </td>
																		<td>Dec-19</td>
																		<td>Compliance process of Insurance Broking License &amp; Analysis of Investment Pattern in Life Insurance </td>
																		<td>IJRAR</td>
																		<td>2349-5138</td>
																		<td>Volume 6, Issue 4</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>6</td>
																		<td>Prof. Amrita Karnawat </td>
																		<td>Krishna Tawari </td>
																		<td>Jan-20</td>
																		<td>A study on understanding the mindset of MBA students in wakad area of Pune towards Entrepreneurship</td>
																		<td>Sodh Sanchar Bulletin</td>
																		<td>2229-3620</td>
																		<td>Vol 7, Issue 25, Jan- March 2020</td>
																		<td>UGC</td>
																	</tr>
																</tbody>
															</Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="2">
														<Accordion.Header>Research Publication 2020-21</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr>
																		<td><b>S. No.</b></td>
																		<td><b>Name of Faculty</b></td>
																		<td><b>Name of Student</b></td>
																		<td><b>Month</b></td>
																		<td><b>Title of Paper</b></td>
																		<td><b>Journal</b></td>
																		<td><b>ISSN/ISBN</b></td>
																		<td><b>Details</b></td>
																		<td><b>Indexing</b></td>

																	</tr>
																	<tr>
																		<td>1</td>
																		<td>Prof. Tanay Kurode</td>
																		<td>Monica Bhagat</td>
																		<td>Jan-21</td>
																		<td>Accessing the awareness levels of goal based financial planning among individuals in Nagpur city</td>
																		<td>National Research Journal of Banking and Finance Management</td>
																		<td>2349-6762</td>
																		<td>Vol. 8, Issue. 1</td>
																		<td>Peer Reviewed Journal</td>

																	</tr>
																	<tr>
																		<td>2</td>
																		<td>Prof. Arvind Burande</td>
																		<td>Snehal Augud</td>
																		<td>Jan-21</td>
																		<td> To Study The Current And Future Potential In Indian Online Education Market</td>
																		<td>National Research Journal of Human Resource Management</td>
																		<td>2349-2015</td>
																		<td>Vol. 8, Issue. 2</td>
																		<td>UGC</td>

																	</tr>
																	<tr>
																		<td>3</td>
																		<td>Dr. Kalpana Deshmukh</td>
																		<td>Aishwarya Giradkar</td>
																		<td>Jun-21</td>
																		<td> An exploration into harrison assessment tools and their use in talent management and development</td>
																		<td>National Research Journal of Business Economics</td>
																		<td>2349-2015</td>
																		<td>Vol. 8, Issue. 1</td>
																		<td>UGC</td>

																	</tr>
																	<tr>
																		<td>4</td>
																		<td>Prof. Sakshi Parihar</td>
																		<td>Aniket Mahajan</td>
																		<td>Nov-20</td>
																		<td>To study inside sales and email marketing at IT </td>
																		<td>International Journal of Applied Research</td>
																		<td>2394-7500</td>
																		<td>Vol 6, Issue 11</td>
																		<td>Peer Reviewed Journal</td>

																	</tr>
																	<tr>
																		<td>5</td>
																		<td>Prof. Purva Agarwal</td>
																		<td>Sumit Bulbule</td>
																		<td>Oct-20</td>
																		<td>Measure the Effectiveness of Infomercial vs. Commercial Influence Consumer Buying</td>
																		<td>Journal of Emerging Technologies and Innovative Research </td>
																		<td>2349-5162</td>
																		<td>Vol 7, Issue 10</td>
																		<td>UGC</td>

																	</tr>
																	<tr>
																		<td>6</td>
																		<td>Dr. Abhijit Bobde</td>
																		<td>Riddhi Kharadkar</td>
																		<td>Nov-20</td>
																		<td>A Study on Consumer Behavior, Awareness and Perception in working Professionals towards term Life insurance</td>
																		<td>Journal of Emerging Technologies and Innovative Research </td>
																		<td>2349-5162</td>
																		<td>Vol 7, Issue 11</td>
																		<td>UGC</td>

																	</tr>
																	<tr>
																		<td>7</td>
																		<td>Dr. Mrityunjay Kumar</td>
																		<td>Shubham Naik</td>
																		<td>Jun-21</td>
																		<td> Best practices of digital marketing: a study at thedigitalfellow.com</td>
																		<td>National Research Journal of Sales &amp; Marketing Management</td>
																		<td>2349-2015</td>
																		<td>Vol. 8, Issue. 1</td>
																		<td>UGC</td>

																	</tr>
																</tbody>
															</Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="3">
														<Accordion.Header>Research Publication 2021-22</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr>

																		<td><b>S. No.</b></td>
																		<td><b>Name of Faculty</b></td>
																		<td><b>Name of Student</b></td>
																		<td><b>Month</b></td>
																		<td><b>Title of Paper</b></td>
																		<td><b>Journal</b></td>
																		<td><b>ISSN/ISBN</b></td>
																		<td><b>Details</b></td>
																		<td><b>Indexing</b></td>
																	</tr>
																	<tr>

																		<td>1</td>
																		<td>Dr. Komal Singh</td>
																		<td>Virendra Sarda</td>
																		<td>Mar-22</td>
																		<td>Technical And Fundamental Analysis Of Selected Pharmaceutical Companies</td>
																		<td>Journal Of Education: Rabindra Bharati University</td>
																		<td>0972-7175</td>
																		<td>Vol.: XXV, No. :3(III), 2022</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>2</td>
																		<td>Dr. Kalpana Deshmukh</td>
																		<td>Shiwani Holakar</td>
																		<td>Mar-22</td>
																		<td>A Study Of Campus Recruitment Process At Acura Solutions, Mulund</td>
																		<td>Anvesak</td>
																		<td>0378 – 4568</td>
																		<td>Vol. 52, No. 3 (III) January – June 2022</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>3</td>
																		<td>Prof. Amrita Karnawat</td>
																		<td>Riddhi Karnawat</td>
																		<td>Mar-22</td>
																		<td>A Financial Performance Analysis Of Itc Ltd By Using Ratios And Altman Z Score Model</td>
																		<td>Wesleyan Journal Of Research</td>
																		<td>0975-1386</td>
																		<td>Vol.15 No.3 </td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>4</td>
																		<td>Dr. Arvind Burande</td>
																		<td>Shubham Kinge</td>
																		<td>Mar-22</td>
																		<td>Study Of Buying Behaviour Of The Investors Towards Mutual Funds</td>
																		<td>Anvesak</td>
																		<td>0378 – 4568</td>
																		<td>Vol. 52, No. 3 (III)</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>5</td>
																		<td>Dr. Mrityunjay Kumar</td>
																		<td>Shubham Naik</td>
																		<td>Mar-22</td>
																		<td>A Study On Best Practices Of Digital Marketing In Pune Region</td>
																		<td>Journal Of Education: Rabindra Bharati University</td>
																		<td>0972-7175</td>
																		<td>Vol.: XXV, No. :3(III), 2022</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>6</td>
																		<td>Prof. Purva Agrawal</td>
																		<td>Sneha Chayla</td>
																		<td>Mar-22</td>
																		<td>To study the factors affecting B2B buying decision of Salesforce CRM</td>
																		<td>Wesleyan Journal of Research</td>
																		<td>0975-1386</td>
																		<td>Vol. 15, No. 3 (March 2022)</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>7</td>
																		<td>Prof. Umesh Gramopadhye</td>
																		<td>Niyati Seth</td>
																		<td>Mar-22</td>
																		<td>A Study Of Key Trends And Challenges Faced By It Companies In Recruitment And Selection Process</td>
																		<td>Wesleyan Journal of Research</td>
																		<td>0975-1386</td>
																		<td>Vol.15 No.3 </td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>8</td>
																		<td>Prof.Trupti Joshi</td>
																		<td>Saket Anil More</td>
																		<td>Mar-22</td>
																		<td>“Study Of Risk Management In Various Mutual Funds Schemes”</td>
																		<td>Journal Of Education: Rabindra Bharati University</td>
																		<td>0972-7175</td>
																		<td>Vol. XXV , No. :3 (III), 2022</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>9</td>
																		<td>Prof. Anuja Limbad </td>
																		<td>Yashodeep Patil</td>
																		<td>Mar-22</td>
																		<td>The Study Of Consumer Perception Towards Insurance Products &amp; Sector In Nashik</td>
																		<td>Anvesak</td>
																		<td>0378 – 4568</td>
																		<td>Vol. 52, No. 3 (III) January – June 2022</td>
																		<td>UGC</td>
																	</tr>
																	<tr>

																		<td>10</td>
																		<td>Prof. Mangesh Sanap</td>
																		<td>Shubham Kandalkar</td>
																		<td>Mar-22</td>
																		<td>A CONCEPTUAL STUDY OF INDUSTRY 4.0</td>
																		<td>Wesleyan Journal of Research, </td>
																		<td>0975-1386</td>
																		<td>Vol.15 No.3 </td>
																		<td>UGC</td>
																	</tr>
																</tbody>
															</Table>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="4">
														<Accordion.Header>Research Publication 2022-23</Accordion.Header>
														<Accordion.Body>
															<Table className="table table-striped table-bordered" responsive>
																<tbody>
																	<tr>

																		<td><b>S. No.</b></td>
																		<td><b>Name of Faculty</b></td>
																		<td><b>Name of Student</b></td>
																		<td><b>Month</b></td>
																		<td><b>Title of Paper</b></td>
																		<td><b>Journal</b></td>
																		<td><b>ISSN/ISBN</b></td>
																		<td><b>Details</b></td>
																		<td><b>Indexing</b></td>
																	</tr>
																	<tr>
																		<td >
																			1
																		</td>
																		<td >
																			Dr. Renu Bhargava&nbsp;
																		</td>
																		<td >
																			Dhrupa Bhatia (Ph.d Student)
																		</td>
																		<td >
																			December
																		</td>
																		<td >
																			A Study of Talent Management of Millennials and Zen Z in India through Work from Home (WFH) Initiative Post Covid 19
																		</td>
																		<td >
																			Annals of Forest Research
																		</td>
																		<td >
																			1844-8135
																		</td>
																		<td >
																			65 (1)
																		</td>
																		<td >
																			UGC
																		</td>
																	</tr>
																	<tr>
																		<td >
																			2
																		</td>
																		<td >
																			Dr. Komal Singh
																		</td>
																		<td >
																			Dhrupa Bhatia (Ph.d Student)
																		</td>
																		<td >
																			December
																		</td>
																		<td >
																			A Study of Talent Management of Millennials and Zen Z in India through Work from Home (WFH) Initiative Post Covid 19
																		</td>
																		<td >
																			Annals of Forest Research
																		</td>
																		<td >
																			1844-8135
																		</td>
																		<td >
																			65 (1)
																		</td>
																		<td >
																			UGC
																		</td>
																	</tr>
																	<tr>
																		<td >
																			3
																		</td>
																		<td >
																			Prof. Amrita Karnawat
																		</td>
																		<td >
																			Dhiraj Patil
																		</td>
																		<td >
																			December
																		</td>
																		<td >
																			A comparative study on Financial Health of Listed stock broking companies in India
																		</td>
																		<td >
																			YMER
																		</td>
																		<td >
																			0044-0477
																		</td>
																		<td >
																			volume 21, issue 12
																		</td>
																		<td >
																			SCOPUS
																		</td>
																	</tr>
																	<tr>
																		<td >
																			4
																		</td>
																		<td >
																			Prof. Amrita Karnawat
																		</td>
																		<td >
																			Anurag Mohan Goud Gargula
																		</td>
																		<td >
																			June&nbsp;
																		</td>
																		<td >
																			To study the impact of Internet Banking in the Banking system&nbsp;
																		</td>
																		<td >
																			BioGecko
																		</td>
																		<td >
																			2230-5807
																		</td>
																		<td >
																			Volum 10, Issue 1(IX)
																		</td>
																		<td >
																			Peer Reviewed
																		</td>
																	</tr>
																	<tr>
																		<td >
																			5
																		</td>
																		<td >
																			Dr. Mrityunjay Kumar
																		</td>
																		<td >
																			Mangesh Shirole&nbsp;
																		</td>
																		<td >
																			January
																		</td>
																		<td >
																			A Case Study on Pre-Owned Car Market in India.
																		</td>
																		<td >
																			International Journal of Advance and Innovative Research
																		</td>
																		<td >
																			2394-7780
																		</td>
																		<td >
																			Volum 10, Issue 1(IX)
																		</td>
																		<td >
																			Peer Reviewed
																		</td>
																	</tr>
																	<tr>
																		<td >
																			6
																		</td>
																		<td >
																			Dr. Mrityunjay Kumar
																		</td>
																		<td >
																			Vikarant Pardesi
																		</td>
																		<td >
																			January
																		</td>
																		<td >
																			Customer behavior towards Start up online advertising with respect to Instagram.&nbsp;
																		</td>
																		<td >
																			International Journal of Advance and Innovative Research
																		</td>
																		<td >
																			2394-7780
																		</td>
																		<td >
																			Volum 10, Issue 1(IX)
																		</td>
																		<td >
																			Peer Reviewed
																		</td>
																	</tr>
																	<tr>
																		<td >
																			7
																		</td>
																		<td >
																			Dr. Mrityunjay Kumar
																		</td>
																		<td >
																			UtkarshLokande
																		</td>
																		<td >
																			January
																		</td>
																		<td >
																			UPI, a paradigm-shift in Digital Payment Transactions
																		</td>
																		<td >
																			International Journal of Advance and Innovative Research
																		</td>
																		<td >
																			2394-7780
																		</td>
																		<td >
																			Volum 10, Issue 1(IX)
																		</td>
																		<td >
																			Peer Reviewed
																		</td>
																	</tr>
																	<tr>
																		<td >
																			8
																		</td>
																		<td >
																			Dr. Manisha Maddel
																		</td>
																		<td >
																			Omkar Batwe
																		</td>
																		<td >
																			April
																		</td>
																		<td >
																			To study the impact of Business Analytics on Supply Chain Performance in the Manufacturing Sector
																		</td>
																		<td >
																			BioGecko
																		</td>
																		<td >
																			2230-5807
																		</td>
																		<td >
																			Vol 12 Issue 02 2023
																		</td>
																		<td >
																			Peer Reviewed
																		</td>
																	</tr>
																	<tr>
																		<td >
																			9
																		</td>
																		<td >
																			Dr. Manisha Maddel
																		</td>
																		<td >
																			Yogeshwar Nighojkar
																		</td>
																		<td >
																			April
																		</td>
																		<td >
																			Design and Development of an Interactive Dashboard for Real-Time Data Visualisation and Analysis
																		</td>
																		<td >
																			BioGecko
																		</td>
																		<td >
																			2230-5807
																		</td>
																		<td >
																			Vol 12 Issue 02 2023
																		</td>
																		<td >
																			Peer Reviewed
																		</td>
																	</tr>
																</tbody>
															</Table>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="FDPConducted" title="FDP Conducted" className="whiteBackground paddingBottom3em">
									<div className="container paddingclass">
										<div className="row">
											<div className="col-md-12">
												<h3 className="blockTitle">FDP Conducted</h3>
											</div>
											<Table className="table table-striped table-bordered" responsive>
												<tbody>
													<tr>
														<td ><b>
															Particulars:
															</b>
														</td>
														<td ><b>
															Date
															</b>
														</td>
														<td ><b>
															Resource Person:
															</b>
														</td>
													</tr>
													<tr>
														<td >
															Stress Management and Group Cohesion for Teachers
														</td>
														<td >
															26.08.2022
														</td>
														<td >
															Ms. Mugdha Shivapurkar, The secret Ingredient
														</td>
													</tr>
													<tr>
														<td >
															OMT-Team Building
														</td>
														<td >
															15-9-2022 and 16-9-2022
														</td>
														<td >
															Dr. Abhijit B and Dr. Sumit Roy At Malhar Machi
														</td>
													</tr>
													<tr>
														<td >
															Case Writing and Case Methods
														</td>
														<td >
															07.10.2022
														</td>
														<td >
															Mr. Ketan Gandhi
														</td>
													</tr>
												</tbody>
											</Table>
											<br/><br/>
											<div className="col-md-6">
												<p><b>FDP on Structured Experiential Learning</b></p>
												<p>A two day FDP on Structured Experiential Learning was conducted for the faculty of ISBS on 21 and 22nd Dec 2019 by Pegasus Institute of Excellence at their campus in Kamshet. The FDP was an outbound learning experience for the faculty. Structured Experiential Learning (SEL), part of which is Outbound Learning (OBL), is based on the popular axiom “experience is the best teacher”. It integrates variety of fun-filled activities that simulate real (work)-life situations and is conducted in a controlled, risk- free and non-threatening environment.</p>
												<p>SEL is also a method that simulates experiences and creates opportunity for learners to discover, comprehend/understand and alter/develop attitudes and behaviours. Most competences (attitude and behaviour) are unconscious and this learning methodology helps in sensitising learners to their competences or the lack of them. Pegasus institute conducted a number of experiential learning activities on self-learning, leadership, team work and team bonding through games. In all, it was a great learning experience for the faculty of ISBS.</p>
											</div>
											<div className="col-md-6">
												<img src={FDP1} className="imgRes" alt="" />
											</div>
											<div className="col-md-6 mobHide">
												<img src={FDP2} className="imgRes" alt="" />
											</div>
											<div className="col-md-6">
												<p><b>FDP on Statistical Tools/Techniques</b></p>
												<p>A two day FDP on Statistical Tools/Techniques was
													conducted by Dr Abhilas Pradhan for the faculty of
													ISBS on 24th June and 1st July 2019 at ISBS
													campus. The FDP covered statistical tools such as TTest, Chi Square, Anova, Correlation and Regression.
													Trainer explained this Statistical tools with practical
													examples on SPPS software in computer lab.
													The Trainer also covered business problems from
													Marketing, Finance and HR areas. The business
													problems were solved by applying this Statistical
													tools. The FDP was well received by the ISBS
													faculty</p>
											</div>
											<div className="col-md-6 mobShow">
												<img src={FDP2} className="imgRes" alt="" />
											</div>
											<div className="col-md-6">
												<p><b>Workshop on Self Needs
													Assessment</b></p>
												<p>A two day Workshop on Self Needs Assessment was
													conducted by Prof. Hema Anand for the faculty of ISBS on
													27th and 28th June 2019 at ISBS campus. The facilitation
													workshop covered Harvard’s Leaders of Learning Program.
													Trainer explained Doughnut Strategy and four Quadrants
													with practical examples/videos and how effectively we can
													engage students in classroom.</p>
												<p>A two day Workshop on Self Needs Assessment was
													conducted by Prof. Hema Anand for the faculty of ISBS on
													27th and 28th June 2019 at ISBS campus. The facilitation
													workshop covered Harvard’s Leaders of Learning Program.
													Trainer explained Doughnut Strategy and four Quadrants
													with practical examples/videos and how effectively we can
													engage students in classroom.</p>
											</div>
											<div className="col-md-6">
												<img src={FDP3} className="imgRes" alt="" />
											</div>
											<div className="col-md-12">
												<p><b>Workshop On SPPU MBA New Syllabus Orientation</b></p>
												<p>MBA Department of Indira School of Business Studies has arranged a workshop on SPPU MBA New Syllabus Orientation for the
													faculty of ISBS, IGBS and ICEM at ISBS Campus on 22nd April 2019. The new syllabus will be implemented from the academic year
													2019-20. The workshop was conducted by Dr. Shailesh Kasande, a man who had been part of new syllabus development. He
													explained newly introduced Outcome Based Education system. He explained the basic structure of the new syllabus. In the new
													syllabus, 5 major specialisation and 3 minor specialisations have been developed as per requirement of the industry. The speaker
													discussed enrichment courses, fundamental courses and Alternative Study credit courses. Comprehensive Concurrent Evaluation
													was also explained to the faculty. New features like Rubrics system, Start Up: Launching and Sustaining Program were explained to
													the faculty. In all, it was a great orientation to all the faculty members of IGI.</p>
											</div>
											<div className="col-md-12">
												<p><b>FDP on Design Thinking</b></p>
												<p>Indira School of Business Studies Research Cell had organised faculty development program (FDP) on Design Thinking for the
													faculty of Indira School of Business Studies for two days on 30th and 31st march 2019 at Hotel Orritel, Talegaon, Pune. The
													workshop was conducted by Dr. Kaustubh Dhargalkar, a renowned expert on the subject. Design Thinking is a design methodology
													that provides a solution-based approach to solving problems. He explained the concept of design thinking with the help of real life
													case studies.
												</p>
												<p>The workshop covered topics such as last mile user connect, empathy and business strategy, design brief, developing analogies
													from unrelated sector, shift lane etc. The whole process of design thinking from learning from people, design brief, creating
													analogies, ideation, and implementation was explained with real life case studies. He also explained how research can be made
													interesting through gamification. The design thinking process was then applied to most important issues like research, discipline
													among students. In all, it was a great learning experience for the faculty of Indira School of Business Studies, Pune.</p>
											</div>

										</div>
									</div>
								</Tab>
								{/* <Tab eventKey="PGRC" title="PGRC" className="whiteBackground paddingBottom3em">
                                    <div className="container paddingclass">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="blockTitle">PGRC</h3>
                                            </div>
                                            <div className="col-md-12">
                                                <p>ISBS PGRC Center was established in the AY 2021. PGRC has been affiliated and approved by Savitribai Phule Pune University. For more information, please click here</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab> */}
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</Fragment >
	)
}

export default ResearchFacultyDevelopment
import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/about/recognition.png"
import trophyImage from "../../assets/images/about/trophy.png"
import a1Image from "../../assets/images/about/a1.png"
import a2Image from "../../assets/images/about/a2.png"
import a3Image from "../../assets/images/about/a3.png"
import a4Image from "../../assets/images/about/a4.png"
import a5Image from "../../assets/images/about/a5.png"
import a6Image from "../../assets/images/about/a6.png"
import a7Image from "../../assets/images/about/a7.png"
import a8Image from "../../assets/images/about/a8.png"
import a9Image from "../../assets/images/about/a9.png"
import a10Image from "../../assets/images/about/a10.png"
import a11Image from "../../assets/images/about/a11.png"
import a12Image from "../../assets/images/about/a12.png"
import a13Image from "../../assets/images/about/a13.png"
const Recognition = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout rightAlignBannerTxt whiteText">RECOGNITION</h2>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="blockTitle">About Us &#62; <span>RECOGNITION</span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container">
                    <div className="row paddingTop3em paddingBottom3em">
                        <div className="col-md-12">
                            <h3 className="blockTitle">Accolades to Indira</h3>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground">
                                    <p><b>12th World Education Congress Global Awards (Institutional category)2023 </b></p>
                                    <ul className="mobUlNone">
                                        <br/>
                                        <li>Best Business School Award </li>
                                        <br/>
                                     
                                    </ul>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground">
                                    <p><b>Asia Pacific HRM Congress Awards (Times Ascent) 2022 </b></p>
                                    <ul className="mobUlNone">
                                        <br/>
                                        <li>Education Leadership Award 2022</li>
                                        <br/>
                                     
                                    </ul>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground">
                                    <p><b>Times of India Best B School Awards 2022  </b></p>
                                    <ul className="mobUlNone">
                                    <br/>
                                        <li>Included in the top 150 Best B schools  </li>
                                        <br/>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground">
                                    <p><b>World HRD Congress conducted the 2022  </b></p>
                                    <ul className="mobUlNone">
                                        <li>Award for Best Academic and Industry Interface - Institutional category at World HRD Congress - IGI, Pune </li>
                                        <li>Global Education Awards - Quality and Value-Based education in the country – IGI, Pune </li>
                                        <li>Best Finance Faculty Award (Under the Individual Category) 2022- Dr. Komal Singh</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground">
                                    <p><b>Business Leader of the Year 2022  </b></p>
                                    <ul className="mobUlNone">
                                        <li>Women leader of the year award 2022 – Dr. Tarita Shankar </li>
                                        <li> Achiever of the year award 2022 – Prof. Chetan Wakalkar </li>
                                        <br/>
                                    </ul>
                                </div>
                            </div>

                            <div className="row padding15px">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground">
                                    <p><b>CMO Asia Education Awards 2021</b></p>
                                    <ul className="mobUlNone">
                                        <li>Mr. Upendra Katageri-Best Accounts Officer-Indira Group of Institutes, Pune</li>
                                        <li>Mr. Vivek Ganmukhi-Best Automation and Digitization -Indira Group of Institutes, Pune</li>
                                        <li>Mr. Sachin Kamble-IT Systems Award -Indira Group of Institutes, Pune</li>
                                        <li>Mr. Shivaji Patil-IT Infrastructure Award-Indira Group of Institutes, Pune</li>
                                        <li>Mr. Eazaz R. Bagwan-Best Project Manager-Indira Group of Institutes, Pune</li>
                                        <li>Mr. Nandlal R. Prasad- Best Administrative Officer- Indira Group of Institutes, Pune</li>
                                        <li>Mr. Kishor Gaikwad - Graphic Designer & Motion Artist Award-Indira Group of Institutes, Pune</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row padding15px paddingBottom3em">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground">
                                    <p><b>National Education Awards 2021 Organizational Awards</b></p>
                                    <ul className="mobUlNone">
                                        <li>Indira Group of Institutes, Pune-Best in Class Infrastructure & Facilities</li>
                                        <li>Indira School of Business Studies, Pune - Best B-School using Technology in Education. Individual Awards</li>
                                        <li>Indira Group of Institutes, Pune-Best in Class Infrastructure & Facilities</li>
                                        <li>Mr. Shivaji Patil-IT Infrastructure Award-Indira Group of Institutes, PuneIndira School of Business Studies, Pune - Best B-School using Technology in Education. Individual Awards</li>
                                        <li>Indira Group of Institutes, Pune-Best Educational Group using Technology in Education</li>
                                        <li>lndira School of Business Studies, Pune-Top Private B-School (Western Region)</li>
                                        <li>Dr Santosh Mahajan - Leader of the Year (Marketing and Admissions)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row paddingBottom3em">
                                <div className="col-md-6 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a1Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-6 text-center borderRightLine">
                                            <p>CBO of the Year Award
                                                2020 - Mr. Raji Thomas
                                                (Chief Branding Officer,
                                                Indira Group of Institutes,
                                                Pune)</p>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <p>Edutech Leader Award
                                                2020- Mr. Santosh
                                                Kumar (Director- IT,
                                                Indira Group of
                                                Institutes, Pune)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a2Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Indira School of
                                                Business Studies, Pune
                                                is was ranked at No. 30
                                                amongst top Private BSchools.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a3Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>HR Professional Of The Year
                                                - Mr. Sandeep Gaekwad,
                                                Director-Administration &
                                                HR - Indira Group of
                                                Institutes, Pune
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="paddingBottom3em">
                                <div className="row">
                                    <div className="col-md-3 boxShadowNew noBoxShM">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <img src={a4Image} alt="" className="maxImgAwardIcon" />
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <p>Award for Best Social
                                                    Media Campaign -
                                                    Indira School of
                                                    Business Studies, Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-6 boxShadowNew noBoxShM">
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <img src={a5Image} alt="" className="maxImgAwardIcon" />
                                                    </div>
                                                    <div className="col-md-6 text-center borderRightLine">
                                                        <p>Young Achiever of
                                                            the Year - Mr. Girish
                                                            Parekh, Director,
                                                            Accounts</p>
                                                    </div>
                                                    <div className="col-md-6 text-center">
                                                        <p>Most Admired Human
                                                            Resource Provider -
                                                            Indira Group of
                                                            Institutes, Pune</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 boxShadowNew  noBoxShM">
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <img src={a6Image} alt="" className="maxImgAwardIcon" />
                                                    </div>
                                                    <div className="col-md-6 text-center borderRightLine">
                                                        <p>Education
                                                            Leadership Award
                                                            2019 - Indira Group
                                                            of Institutes, Pune</p>
                                                    </div>
                                                    <div className="col-md-6 text-center">
                                                        <p>Outstanding Leader
                                                            Award 2019 - Prof.
                                                            Chetan Wakalkar
                                                            (Group Director - IGI)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row paddingBottom3em">
                                <div className="col-md-3 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a7Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Best Institute Overall -
                                                Indira School of
                                                Business Studies, Pune</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a8Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Prof Raji Thomas - IGI -
                                                Best Branding &
                                                Placement Officer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a9Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Indira School of
                                                Business Studies, Pune
                                                - Best Institute Overall</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a10Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Dr. Tarita Shankar -
                                                Women In Education
                                                Leadership Award
                                                (Excellence in Education)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row padding15px paddingBottom3em">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground noBoxShM">
                                    <p><b>26th Business School Affaire & Dewang Mehta National Education Awards 2018</b></p>
                                    <ul className="mobUlNone">
                                        <li>Education Leadership Award 2019 - Indira Group of Institutes, Pune</li>
                                        <li>Best Corporate Relations Team - Indira Group of Institutes, Pune</li>
                                        <li>Best Director in Information Technology - Mr. Santosh Kumar, Director-IT, Indira Group of Institutes, Pune</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row padding15px paddingBottom3em">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground noBoxShM">
                                    <p><b>Organizational Category</b></p>
                                    <ul className="mobUlNone">
                                        <li>Educational Institute that encourages leadership as a part of the curriculum - Indira Group of Institutes, Pun</li>
                                        <li>Best Educational Group (Infrastructure & Facilities) - Indira Group of Institutes, Pune</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row paddingBottom3em">
                                <div className="col-md-4 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a13Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Education Entrepreneurship
                                                Award - Dr. Tarita Shankar
                                                National Education Awards
                                                2017</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a12Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Indira Group of Institutes,
                                                Pune -Best Educational
                                                Group (Infrastructure &
                                                Facilities)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 boxShadowNew noBoxShM">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <img src={a11Image} alt="" className="maxImgAwardIcon" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <p>Best Business School -
                                                Indira School of Business
                                                Studies, Pune.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row padding15px paddingBottom3em">
                                <div className="col-md-2 positionAbsolute mobHide">
                                    <img src={trophyImage} alt="" className="circleAboutIcon" />
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-11 whiteBgWithShadow paddingCommonbox whiteBackground noBoxShM">
                                    <p><b>DNA Innovative Education Awards 2016 Social media awards</b></p>
                                    <ul className="mobUlNone">
                                        <li>Best use of Social Media in Education - Indira Group of Institutes, Pune</li>
                                        <li>World Education Congress - Global Awards for Excellence in Education, Leadership and Teaching 2015</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default Recognition

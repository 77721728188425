import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import HomePageMenu from "../../components/HomePageMenu";
import Header from "../../components/Header";
import MbaBanner from "../../assets/images/mba-banner.jpg"
import Gharwapsibanner1 from "../../assets/images/Ghar-Wapsi.jpg"
import Banner1Image from "../../assets/images/Newisbsbanner.png"
import Banner1MobImage from "../../assets/images/isbsNewMob2.png"
import Banner2Image from "../../assets/images/home-banner2.jpg"
import Banner2MobImage from "../../assets/images/home-banner2-mob.jpg"
import Banner3Image from "../../assets/images/isbsbanner1.png"
import Banner3MobImage from "../../assets/images/isbsNewMob.png"
import Banner4Image from "../../assets/images/home-banner4.jpg"
import Banner4MobImage from "../../assets/images/banner4-mob.jpg"
import Banner5Image from "../../assets/images/home-banner5.jpg"
import Banner5MobImage from "../../assets/images/banner5-mob.jpg"
import Banner6Image from "../../assets/images/home-banner6.jpg"
import Banner6MobImage from "../../assets/images/banner6-mob.jpg"
import Banner7Image from "../../assets/images/home-banner7.jpg"
import Banner7MobImage from "../../assets/images/banner7-mob.jpg"
import Banner8Image from "../../assets/images/MDP.jpg"
import Banner8MobImage from "../../assets/images/banner8-mob.jpg"
import Banner9Image from "../../assets/images/home-banner9.jpg"
import Banner10Image from "../../assets/images/home-banner10.jpg"
import TestimonialImage from "../../assets/images/testimonial.jpg"
import Icon1Image from "../../assets/images/icon1.png"
import Icon2Image from "../../assets/images/icon2.png"
import Icon3Image from "../../assets/images/icon3.png"
import Icon4Image from "../../assets/images/icon4.png"
import Icon11Image from "../../assets/images/home-ico/Excellence.png"
import Icon22Image from "../../assets/images/home-ico/Recruiters.png"
import Icon33Image from "../../assets/images/home-ico/Package.png"
import Icon44Image from "../../assets/images/home-ico/Accreditation.png"
import Icon55Image from "../../assets/images/home-ico/Leadership.png"
import Icon66Image from "../../assets/images/home-ico/Ranking.png"
import Icon77Image from "../../assets/images/home-ico/Placement.png"
import Icon88Image from "../../assets/images/home-ico/Infrastructure.png"
import SaurabhMahajan from "../../assets/images/home-ico/SaurabhMahajan.jpg"
import ShubhamJain from "../../assets/images/home-ico/ShubhamJain.png"
import SumeetNarke from "../../assets/images/home-ico/SumeetNarke.jpg"
import ACSPApplications from "../../assets/pdf/Advt-for-IL-ACAP-applications.pdf"
import "./Home.css"
const Home = () => {
    const [divHeight, setDivHeight] = useState(500);
    const [menuHeight, setmenuHeight] = useState(500);
    const [isImg2, setIsImg2] = useState(false);
    const [isImg3, setIsImg3] = useState(false);
    const [isImg4, setIsImg4] = useState(false);
    const [isImg5, setIsImg5] = useState(false);
    const [isImg6, setIsImg6] = useState(false);
    const [isImg7, setIsImg7] = useState(false);
    const [isImg8, setIsImg8] = useState(false);


    window.addEventListener("scroll", () => {
        const title = document.querySelector('.sticky-title');
        const titleTop = title.offsetTop;
        setDivHeight(titleTop);

    });
    function isInViewPort(element) {
        // Get the bounding client rectangle position in the viewport
        var bounding = element.getBoundingClientRect();

        // Checking part. Here the code checks if it's *fully* visible
        // Edit this part if you just want a partial visibility
        //console.log(menuHeight);
        if (
            bounding.top >= -150 && bounding.top <= 0
        ) {
            //console.log('In the viewport! :)');
            return true;
        } else {
            //console.log('Not in the viewport. :(');
            return false;
        }
    }
    window.addEventListener('scroll', function (event) {
        const theElementToWatch = document.querySelector('.img2');
        isInViewPort(theElementToWatch)
        isInViewPort(theElementToWatch) ? setIsImg2(true) : setIsImg2(false);
    }, false);

    window.addEventListener('scroll', function (event) {
        const theElementToWatch = document.querySelector('.img3');
        isInViewPort(theElementToWatch)
        isInViewPort(theElementToWatch) ? setIsImg3(true) : setIsImg3(false);
    }, false);

    window.addEventListener('scroll', function (event) {
        const theElementToWatch = document.querySelector('.img4');
        isInViewPort(theElementToWatch)
        isInViewPort(theElementToWatch) ? setIsImg4(true) : setIsImg4(false);
    }, false);

    window.addEventListener('scroll', function (event) {
        const theElementToWatch = document.querySelector('.img5');
        isInViewPort(theElementToWatch)
        isInViewPort(theElementToWatch) ? setIsImg5(true) : setIsImg5(false);
    }, false);

    window.addEventListener('scroll', function (event) {
        const theElementToWatch = document.querySelector('.img6');
        isInViewPort(theElementToWatch)
        isInViewPort(theElementToWatch) ? setIsImg6(true) : setIsImg6(false);
    }, false);
    window.addEventListener('scroll', function (event) {
        const theElementToWatch = document.querySelector('.img7');
        isInViewPort(theElementToWatch)
        isInViewPort(theElementToWatch) ? setIsImg7(true) : setIsImg7(false);
    }, false);
    window.addEventListener('scroll', function (event) {
        const theElementToWatch = document.querySelector('.img8');
        isInViewPort(theElementToWatch)
        isInViewPort(theElementToWatch) ? setIsImg8(true) : setIsImg8(false);
    }, false);

    return (
        <Fragment>
            <span className="mobHide">
                <HomePageMenu divHeight={divHeight} menuHeight={setmenuHeight}></HomePageMenu>
            </span>
            <span className="mobShow">
                <Header></Header>
            </span>
            <div>
            <div>
            <marquee className="marqueeText mobHide">
               <p><b>MBA Admission Alert : DTE Code for Indira School of Business Studies is <span class="blinkanimation">*0646010110*</span> | </b>An Autonomous Institute | Approved by AICTE | Accredited by NBA  | 
               Permanently Affiliated to Savitribai Phule Pune University</p>
            </marquee> 
            </div>
            <Carousel className="marginBottom2em studentC">
            
                               <Carousel.Item>
               
                    <div className="col-md-12">                   
                  <img src={MbaBanner} className="imgRes" alt="" />
                    
                    </div>
                   
                
                </Carousel.Item>
                {/* <Carousel.Item>
                <div className="col-md-12">
               
                <img src={Gharwapsibanner1} className="imgRes" alt="" />
                    </div>
                    
                
                </Carousel.Item>  */}
                <Carousel.Item>
                
                <div className="positionRelative bannerHeightLabel mobHide">
                
                    <img src={Banner1Image} className="imgRes" alt="" />
                   
                    <div className="bannerText">
                    
                        <h2>A BETTER LEARNING FUTURE STARTS HERE</h2>
                        <p>“Education is the key to success in life, and teachers make an enduring impact within the lives of their students.”</p>
                    </div>
                </div>
                <div className="positionRelative mobileBanner">
                    <img src={Banner1MobImage} className="imgRes" alt="" />
                    <div className="bannerTextMob">
                        <h2>A BETTER LEARNING FUTURE STARTS HERE</h2>
                        <p>“Education is the key to success in life, and teachers make an enduring impact within the lives of their students.”</p>
                    </div>
                </div>
                </Carousel.Item>
                </Carousel>
                
                <div className="">
                    <div className="patchTwoIconContainer">
                        <div className="container">
                            <div className="row blackText">
                                <div className="col-md-3 col-6 text-center">
                                    <a href="tel:912066759407">
                                        <img src={Icon1Image} className="homeIcon" alt="" />
                                        <p>Call</p>
                                    </a>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <NavLink to="Mba"><img src={Icon2Image} className="homeIcon" alt="" />
                                        <p>MBA</p></NavLink>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <a href="https://indiraisbs.ac.in/ISBS-360-degree-virtual-tour/" target="_blank" rel="noreferrer">
                                        <img src={Icon3Image} className="homeIcon" alt="" />
                                        <p>Virtual Tour</p>
                                    </a>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <NavLink to="contact">
                                        <img src={Icon4Image} className="homeIcon" alt="" />
                                        <p>Contact Us</p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobHide">
                    <div className="positionRelative img2">
                        <img src={Banner2Image} className="imgRes" alt="" />
                    </div>
                    <div className={isImg2 ? "SlideContentBox img2Content" : "SlideContentBox"} style={{ height: menuHeight }}>
                        <h3>About Us</h3>
                        <p>Indira School of Business Studies (ISBS) was
                            established as a professional school to meet the
                            growing needs of the business sector with an
                            idea to bridge the gap between the ‘Campus and
                            Corporates’. In a short span of time, the programs
                            of the Institute were approved by AICTE and ISBS
                            established its presence in Pune as a premier
                            business educational institute. The curriculum
                            design, methodology utilized, coupled with
                            technology for teaching keeps the students
                            abreast of the trends in the field of business and
                            assures greater articulation of the same.</p>
                    </div>
                </div>
                <div className="mobShow positionRelative">
                    <div className="positionRelative img2">
                        <img src={Banner2MobImage} className="imgRes" alt="" />
                    </div>
                    <div className="bannerTextMob">
                        <h3>About Us</h3>
                        <p>Indira School of Business Studies (ISBS) was
                            established as a professional school to meet the
                            growing needs of the business sector with an
                            idea to bridge the gap between the ‘Campus and
                            Corporates’. In a short span of time, the programs
                            of the Institute were approved by AICTE and ISBS
                            established its presence in Pune as a premier
                            business educational institute. The curriculum
                            design, methodology utilized, coupled with
                            technology for teaching keeps the students
                            abreast of the trends in the field of business and
                            assures greater articulation of the same.</p>
                    </div>
                </div>
                <div className="">
                    <div className="patchTwoIconContainer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon11Image} className="homeIcon" alt="" />
                                    <p>28+ Years Of<br />Excellence</p>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon22Image} className="homeIcon" alt="" />
                                    <p>350+ Prominent<br />Recruiters</p>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon33Image} className="homeIcon" alt="" />
                                    <p> 14 Lakh Highest<br />Package Offered</p>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon44Image} className="homeIcon" alt="" />
                                    <p>Accredited by<br />NBA</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon55Image} className="homeIcon" alt="" />
                                    <p>Career and<br />Leadership</p>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon66Image} className="homeIcon" alt="" />
                                    <p>Business India<br />Ranking</p>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon77Image} className="homeIcon" alt="" />
                                    <p>International<br />Placement</p>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <img src={Icon88Image} className="homeIcon" alt="" />
                                    <p>State Of The Art<br />Infrastructure</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobHide">
                    <div className="positionRelative img3">
                        <img src={Banner3Image} className="imgRes" alt="" />
                    </div>
                    <div className={isImg3 ? "SlideContentBox img2Content" : "SlideContentBox"} style={{ height: menuHeight }}>
                        <h3>Academics</h3>
                        <p><b>Academics</b></p>
                        <p>ISBS students undergoing MBA program will be equipped with requisite
                            knowledge, skills and competence to face the rigors of corporate world.
                            The students will develop into competent management professional with
                            strong ethical values and making them capable of assuming a pivotal role
                            in global economy. The MBA program will facilitate learning in theory and
                            practices of different functional areas of management with an integrated
                            approach to various functions of management.</p>
                        <p><b>Ph.D</b></p>
                        <p>To engage with and encourage research scholars to take up quality
                            research in various areas through building a research-oriented culture and
                            by providing them with Training inputs Forums for collaboration on
                            research, conducting research workshops and sharing of research
                            insights.
                        </p>
                    </div>
                </div>
                <div className="mobShow positionRelative">
                    <div className="positionRelative img2">
                        <img src={Banner3MobImage} className="imgRes" alt="" />
                    </div>
                    <div className="bannerTextMob">
                        <h3>Academics</h3>
                        <p><b>Academics</b></p>
                        <p>ISBS students undergoing MBA program will be equipped with requisite
                            knowledge, skills and competence to face the rigors of corporate world.
                        </p>
                        <p><b>Ph.D</b></p>
                        <p>To engage with and encourage research scholars to take up quality
                            research in various areas through building a research-oriented culture and
                            by providing them with Training inputs Forums for collaboration on
                            research, conducting research workshops and sharing of research
                            insights.
                        </p>
                    </div>
                </div>
                {/* <div className="lightGreyContainer">
                    <div className="patchTwoIconContainer">
                        <div className="container">
                            <div className="row">
                                <h4 className="titleHome text-center">ISBS Highlights</h4>
                                <div className="col-md-4 text-center">
                                    <img src={Icon1Image} className="homeIcon" alt="" />
                                    <p>Accredited By National Board Of Accreditation.</p>
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src={Icon2Image} className="homeIcon" alt="" />
                                    <p>Career And Leadership</p>
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src={Icon3Image} className="homeIcon" alt="" />
                                    <p> International Placement</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="orangeContainer paddingSpace"></div>
                <div className="positionRelative img4">
                    <div className="mobHide">
                        <img src={Banner4Image} className="imgRes" alt="" />
                    </div>
                    <div className={isImg4 ? "SlideContentBox img2Content" : "SlideContentBox"} style={{ height: menuHeight }}>
                        <h3>Placement</h3>
                        <p>Do not let what you cannot do interfere with
                            what you can do - the great words by John
                            Wooden motivates us at Indira School of
                            Business Studies to improve our placement
                            records year after year.</p>
                        <p>Indira Group of Institutes has a central Corporate
                            Relations cell headed by an Executive Director -
                            Corporate Relations. The cell is a hub which aims
                            to provide suitable career and training guidance
                            to students according to their interests and
                            capabilities.
                        </p>
                    </div>
                </div>
                <div className="mobShow positionRelative">
                    <div className="positionRelative img2">
                        <img src={Banner4MobImage} className="imgRes" alt="" />
                    </div>
                    <div className="bannerTextMob">
                        <h3>Placement</h3>
                        <p>Do not let what you cannot do interfere with
                            what you can do - the great words by John
                            Wooden motivates us at Indira School of
                            Business Studies to improve our placement
                            records year after year.</p>
                        <p>Indira Group of Institutes has a central Corporate
                            Relations cell headed by an Executive Director -
                            Corporate Relations. The cell is a hub which aims
                            to provide suitable career and training guidance
                            to students according to their interests and
                            capabilities.
                        </p>
                    </div>
                </div>
                <div className="orangeContainer paddingSpace"></div>
                <div className="mobHide">
                    <div className="positionRelative img5">
                        <img src={Banner5Image} className="imgRes" alt="" />
                    </div>
                    <div className={isImg5 ? "SlideContentBox img2Content" : "SlideContentBox"} style={{ height: menuHeight }}>
                        <h3>Student Life</h3>
                        <p>Indira School of Business Studies  believes
                            in the holistic development of students and
                            though the academic calendar forms the basis of
                            the agenda, abundant cynosure is given to cocurricular activities. In order to mold productive
                            and result oriented managers of the future, we
                            conduct the Career Development Program, which
                            is taught concurrently to the main program.
                            Unique and innovative co-curriculum activities
                            are initiated at ISBS  which focus on the
                            extra edge that students need in their personality
                            to succeed in a competitive environment.</p>
                    </div>
                </div>
                <div className="mobShow positionRelative">
                    <div className="positionRelative img2">
                        <img src={Banner5MobImage} className="imgRes" alt="" />
                    </div>
                    <div className="bannerTextMob">
                        <h3>Student Life</h3>
                        <p>Indira School of Business Studies  believes
                            in the holistic development of students and
                            though the academic calendar forms the basis of
                            the agenda, abundant cynosure is given to cocurricular activities. In order to mold productive
                            and result oriented managers of the future, we
                            conduct the Career Development Program, which
                            is taught concurrently to the main program.
                            Unique and innovative co-curriculum activities
                            are initiated at ISBS  which focus on the
                            extra edge that students need in their personality
                            to succeed in a competitive environment.</p>
                    </div>
                </div>
                <div className="orangeContainer paddingSpace"></div>
                <div className="mobHide">
                    <div className="positionRelative img6">
                        <img src={Banner6Image} className="imgRes" alt="" />
                    </div>
                    <div className={isImg6 ? "SlideContentBox img2Content" : "SlideContentBox"} style={{ height: menuHeight }}>
                        <h3>Alumni</h3>
                        <p>* A respectful, congenial, and equitable program
                            that is welcoming and engaging for alumni of all
                            ages.
                        </p>
                        <p>* A dynamic community of active alumni who
                            contribute to the goals of the program, feel
                            ownership over its direction, and act as
                            ambassadors for ISBS</p>
                        <p>* A program with strong ties to industry and
                            spin-out companies, and the ability of promote
                            mentoring, internship and career opportunities for
                            students and alumni in management and other
                            fields.
                        </p>
                        <p>* A commitment to transparency of goals and
                            actions</p>
                    </div>
                </div>
                <div className="mobShow positionRelative">
                    <div className="positionRelative img2">
                        <img src={Banner6MobImage} className="imgRes" alt="" />
                    </div>
                    <div className="bannerTextMob">
                        <h3>Alumni</h3>
                        <p>* A respectful, congenial, and equitable program
                            that is welcoming and engaging for alumni of all
                            ages.
                        </p>
                        <p>* A dynamic community of active alumni who
                            contribute to the goals of the program, feel
                            ownership over its direction, and act as
                            ambassadors for ISBS</p>
                        <p>* A program with strong ties to industry and
                            spin-out companies, and the ability of promote
                            mentoring, internship and career opportunities for
                            students and alumni in management and other
                            fields.
                        </p>
                        <p>* A commitment to transparency of goals and
                            actions</p>
                    </div>
                </div>
                <div className="orangeContainer paddingSpace"></div>

                <div className="mobHide">
                    <div className="positionRelative img7">
                        <img src={Banner7Image} className="imgRes" alt="" />
                    </div>
                    <div className={isImg7 ? "SlideContentBox img2Content" : "SlideContentBox"} style={{ height: menuHeight }}>
                        <h3>Research</h3>
                        <p>Since its inception has been constantly
                            contributing to the corporate world with wellprepared scholars, researchers, academicians
                            and industry leaders who come together in
                            sharing their knowledge and solving the real
                            world corporate problems.</p>
                    </div>
                </div>
                <div className="mobShow positionRelative">
                    <div className="positionRelative img2">
                        <img src={Banner7MobImage} className="imgRes" alt="" />
                    </div>
                    <div className="bannerTextMob">
                        <h3>Research</h3>
                        <p>Since its inception has been constantly
                            contributing to the corporate world with wellprepared scholars, researchers, academicians
                            and industry leaders who come together in
                            sharing their knowledge and solving the real
                            world corporate problems.</p>
                    </div>
                </div>
                <div className="orangeContainer paddingSpace"></div>

                <div className="mobHide">
                    <div className="positionRelative img8">
                        <img src={Banner8Image} className="imgRes" alt="" />
                    </div>
                    <div className={isImg8 ? "SlideContentBox img2Content" : "SlideContentBox"} style={{ height: menuHeight }}>
                        <h3>MDP</h3>
                        <p>The objective of the MDPs is to help improve
                            management systems and practices in India by
                            providing relevant training to the executives of
                            different sectors of the economy operating at
                            different levels in the organizational hierarchy.
                            The participants will be equipped with the latest
                            tools, techniques and skills spanning different
                            streams of management such as General
                            Management, Human Resources, Soft Skills,
                            Marketing, Finance.</p>
                    </div>
                </div>
                <div className="mobShow positionRelative">
                    <div className="positionRelative">
                        <img src={Banner8MobImage} className="imgRes" alt="" />
                    </div>
                    <div className="bannerTextMob">
                        <h3>MDP</h3>
                        <p>The objective of the MDPs is to help improve
                            management systems and practices in India by
                            providing relevant training to the executives of
                            different sectors of the economy operating at
                            different levels in the organizational hierarchy.
                            The participants will be equipped with the latest
                            tools, techniques and skills spanning different
                            streams of management such as General
                            Management, Human Resources, Soft Skills,
                            Marketing, Finance.</p>
                    </div>
                    </div>
                    <div className="orangeContainer paddingSpace"></div>
                    {/* <img src={Banner9Image} className="imgRes" alt="" />
                <div className="lightGreyContainer paddingSpace"></div> */}
                    <img src={Banner10Image} className="imgRes sticky-title" alt="" />
                    <div className="lightGreyContainer paddingSpace">
                        <div className="container">
                            <h3 className="titleHome text-center">Testimonials</h3>
                            <Carousel>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10">
                                            <div className="row whiteBackground">
                                                <div className="col-md-4 paddingZero mobTextCenter">
                                                    <img src={SumeetNarke} className="imgRes testimonialImg" alt="" />
                                                </div>
                                                <div className="col-md-8 padding30px">
                                                    <p>The academic experience with a world-class infrastructure and excellent faculty at Indira School of Business Studies has endured me with a lifelong career excellence. The exceptional programmes and teaching methodologies backed by practical skills and industry interface have given me the confidence to pursue my career ahead. The all-time support and motivation of the faculty members of ISBS has enlightened me throughout the beautiful journey.</p>
                                                    <p className="marginBottomZero"><b>Sumeet Narke</b><br />
                                                        <b>FICO Consultant – Tieto</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10">
                                            <div className="row whiteBackground">
                                                <div className="col-md-4 paddingZero mobTextCenter">
                                                    <img src={SaurabhMahajan} className="imgRes testimonialImg" alt="" />
                                                </div>
                                                <div className="col-md-8 padding30px">
                                                    <p>The academic experience with excellent faculty at Indira School of Business Studies has endured me with a lifelong career excellence. Throughout my two years of MBA at ISBS, the department has made me gain knowledge and solid experience on business, ethics, and personality and professionalism. These attributes were the key reasons because of which I was successfully placed from campus. Big thanks to all my faculty and friends for the amazing journey at ISBS.</p>
                                                    <p className="marginBottomZero"><b>Saurabh Mahajan</b><br />
                                                        <b>Assistant Manager – Federal Bank</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10">
                                            <div className="row whiteBackground">
                                                <div className="col-md-4 paddingZero mobTextCenter">
                                                    <img src={ShubhamJain} className="imgRes testimonialImg" alt="" />
                                                </div>
                                                <div className="col-md-8 padding30px">
                                                    <p>I, Shubham Jain, would like to give my sincere gratitude to Indira School of Business Studies for helping me at each and every step of my professional career. The institute provided me with best possible platform and infrastructure to excel in my career interest. The expert faculty made me industry ready while the placement training helped me enhance my soft skills which made me stand out from the others. Being a member of various committees at the institute inculcated values and discipline which ultimately helped me to work abroad at Squareyards as a summer intern in UAE and later got the placement in the same company in Oman.</p>
                                                    <p className="marginBottomZero"><b>Shubham Jain</b><br />
                                                        <b>Square yards Dubai & Oman</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
        </Fragment>
    )
}
export default Home;
import React, { Fragment } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/alumni/bannernetwork.jpg"
import video1 from "../../assets/images/event/Brand-Slam-Trailer-2.mp4"
import video2 from "../../assets/images/event/Indira-Brand-Slam-inaugural-video.mp4";


const BrandSlam = () => {
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout" style={{ color: '#ffffff', textAlign: "left", paddingLeft: '6%' }}>Indira Brand Slam<br />
                Summit & Awards</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        
                        <h3 className="blockTitle">News & Events &#62; <span>Indira Brand Slam Summit and Awards, 2023</span></h3>
                    </div>
                </div>
            </div>
            <div className="marginBottom3em">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 whiteBgWithShadow">
                            <div className="row padding15px">
                                <div className="col-md-12">
                                    <h3 className="blockTitle">Indira Brand Slam Summit and Awards, 2023</h3>
                                    <p>Indira School Of Business Studies organized the 10th edition of the Indira Brand Slam Summit and Awards, 2023. The theme for this year was “Changing Paradigms for Building Brands”. Several corporate leaders and management students attended this spectacular event!</p>
                                    <p>The event brought to the fore examples of how changing paradigms for building brands are taking place and what businesses are doing to transform themselves and redesign themselves in the digitally transforming business world. It was an occasion for the industry stalwarts to hold up the beacon of their experience for students to shape the leaders of tomorrow. </p>
                                    <div className="row">
                                    <div className="col-md-6">
                                    <video src={video1} width="100%" height="350" controls="controls" autoplay="true" />
                                </div>
                                <div className="col-md-6">
                                    <video src={video2} width="100%" height="350" controls="controls" autoplay="true" />
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default BrandSlam
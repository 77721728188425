import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
  
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16673953245';
    script.async = true;
    document.head.appendChild(script);

    // Create the inline script to initialize gtag
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16673953245');
    `;
    document.head.appendChild(inlineScript);

    // Cleanup function to remove scripts if the component unmounts
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return null; 
};

export default GoogleTagManager;
